import { t } from "@lingui/macro";
import * as Sentry from "@sentry/browser";
import Debug from "debug";
import { observable, action, runInAction, computed } from "mobx";
import { v4 as uuidV4 } from "uuid";

import {
    API_CORE_APPLICATION_IDENTIFIER,
    API_CORE_TENANT_NAME,
    IDHUB_URL,
} from "src/config/environment";
import { isProperty } from "src/lib/environment";
import { isAdmin, isCM, isPS, isWLA } from "src/lib/group-role";
import { loads } from "src/lib/loading";
import {
    IMe,
    IWhiteLabelRead,
    IClientConfiguration,
    IUserRead,
    ICommunityRead,
} from "src/services";
import { IAuthToken } from "src/services/auth-token";
import { HttpStatus, IApiResponse } from "src/services/client";
import { ICurrentUser, IAdminRoles } from "src/services/deprecated";
import { BaseStore } from "src/store/base-store";

import { MessageSeverity } from "src/store/global/message-store";
import { readState } from "src/store/lib/read-state";
import { store } from "src/store/lib/store";

import { FeatureStore } from "./session-store/feature-store";

const stateStorageKey = "__tmpl-manager-auth-state";
const warn = Debug("tmpl:warn:store/session");

export enum SessionStatus {
    Error = -2,
    FailedAuthentication = -1,
    Unauthenticated = 0,
    IsAuthenticating = 1,
    Authenticated = 4,
}

interface IAuthState {
    uuid: string;
    redirectURL?: string;
}

function isSU(user: ICurrentUser) {
    return user.adminRoles.includes(IAdminRoles.SuperUser);
}

@store
export class SessionStore extends BaseStore {
    @observable
    private _data?: {
        user: ICurrentUser | null;
        me: IMe;
        communities: ICommunityRead[];
        whiteLabel: IWhiteLabelRead;
        clientConfiguration: IClientConfiguration;
    };

    @observable
    public status: SessionStatus = SessionStatus.Unauthenticated;

    public readonly features = new FeatureStore();

    private get dataOrThrow() {
        if (!this._data) {
            throw new Error("Session store not completly initialized");
        }
        return this._data;
    }

    /** @deprecated the brf building */
    public get building() {
        return this.dataOrThrow.user && this.dataOrThrow.user.building;
    }

    @computed
    public get me() {
        return this.dataOrThrow.me;
    }

    @computed
    public get communities() {
        return this.dataOrThrow.communities;
    }

    @computed
    public get whiteLabel() {
        return this.dataOrThrow.whiteLabel;
    }

    @computed
    public get clientConfiguration() {
        return this.dataOrThrow.clientConfiguration;
    }

    @computed
    public get currentUserIsWLA() {
        return isWLA(this.me);
    }

    @computed
    public get defaultTags() {
        if (isWLA(this.me)) {
            // WLA admins go into this case. Tag with the global client tag to work around backend problem
            // where the entry would be tagged with something inane like a building.
            return [this.whiteLabel.uuid];
        } else {
            // We go into this case if the user doesn't have global access, i.e. CM and PS admins.
            // If no tags are selected the entry should be visible to anyone in the managed communities
            // but the backend defaults to picking just one of the communities so we tag ut ourselves.
            return this.communities.map((community) => community.uuid);
        }
    }

    public forceInitForTest(
        token: IAuthToken,
        user: ICurrentUser,
        me: IMe,
        whiteLabel: IWhiteLabelRead,
        communities: ICommunityRead[],
        clientConfiguration: IClientConfiguration,
        baseUrl: string
    ) {
        this.services.setAuthToken(token);
        this._data = { user, me, whiteLabel, communities, clientConfiguration };
        this.status = SessionStatus.Authenticated;
        this.setBaseUrl(baseUrl);
    }

    /** @deprecated uses legacy user id */
    public isUser(user: { id?: number }) {
        return user.id != null && this.dataOrThrow.user && this.dataOrThrow.user.id === user.id;
    }

    public hasPermission(permission: string): boolean;
    public hasPermission(role: string, permission: string): boolean;
    public hasPermission(permissionOrRole: string, maybePermission?: string): boolean {
        const role = maybePermission != null ? permissionOrRole : null;
        const permission = maybePermission != null ? maybePermission : permissionOrRole;

        if (role == null) {
            return this.dataOrThrow.me.permissions.includes(permission);
        } else {
            return this.dataOrThrow.me.roles.some((r) =>
                r.permissions.some((p) => p.role === role && p.permission === permission)
            );
        }
    }

    public hasSessionError() {
        return this.status === SessionStatus.Error;
    }

    public canViewPublicRoutes() {
        return this.status === SessionStatus.FailedAuthentication;
    }

    public canViewPrivateRoutes() {
        return this.status === SessionStatus.Authenticated;
    }

    private get hasNoRoles() {
        const noRoles =
            this._data?.user?.adminRoles.length === 0 && this._data?.me.roles.length === 0;
        return this.canViewPrivateRoutes() && noRoles;
    }

    public get hasToken() {
        return this.services.getAuthToken().isSet();
    }

    public get hasBaseUrl() {
        return this.services.getBaseUrl() != null;
    }

    public get clientType() {
        if (this._forcedClientType) {
            return this._forcedClientType;
        }
        return this.whiteLabel.type;
    }

    public isWorkClientType() {
        return this.clientType === "work";
    }

    public isHomeClientType() {
        return this.clientType === "home";
    }

    @action.bound
    public debugForceClientType(clientType: undefined | "home" | "work") {
        this._forcedClientType = clientType;
    }

    @observable
    private _forcedClientType: undefined | "home" | "work" = undefined;

    @computed
    public get forcedClientType() {
        return this._forcedClientType;
    }

    @action.bound
    public debugForceSideMenuV1(force = true) {
        this._forceSideMenuV1 = force;
    }

    @observable
    private _forceSideMenuV1 = false;

    @computed
    public get forceSideMenuV1() {
        return this._forceSideMenuV1;
    }

    public get showV2SideMenu() {
        if (this._forceSideMenuV1) {
            return false;
        }
        return this.enableV2Manager;
    }

    private get enableV2Manager() {
        if (!this._data) {
            return false;
        }

        if (!this._data.user) {
            return false;
        }

        return isAdmin(this._data.me) || isSU(this._data.user);
    }

    @action.bound
    public destroy(redirect = true) {
        this.services.destroyAuthToken();
        this.status = SessionStatus.Unauthenticated;

        if (redirect) {
            this.redirectToIdhubLogin();
        }
    }

    /**
     * Load and set the tenant base url. This is useful when you need to set
     * the base url while the user in unauthenticated.
     */
    public async loadBaseUrl() {
        if (this.hasBaseUrl) {
            return;
        }

        await this.root.utility.$loading.around("session/load-base-url", async () => {
            const response = await this.services.whiteLabel.fetchCurrent();
            if (response.status === HttpStatus.Ok) {
                this.setBaseUrl(response.body.domainUrl);
            } else {
                this.reportResponseError(response);
            }
        });
    }

    @action.bound
    @loads("session/check-authentication")
    public async fetchCurrentUser(force = false) {
        if (!this.hasToken) {
            this.status = SessionStatus.FailedAuthentication;
            return;
        }

        if (!this.hasBaseUrl) {
            // The current session is invalid as there's no base url. No
            // requests can be made without a base url.
            this.status = SessionStatus.Error;
            return;
        }

        if (!force && this.status >= SessionStatus.IsAuthenticating) {
            // No need to authenticate, we are valid
            return;
        }
        this.status = SessionStatus.IsAuthenticating;
        this._data = undefined;
        const [userResponse, meResponse, whiteLabelResponse, clientConfigurationResponse] =
            await Promise.all([
                !isProperty() ? this.services.session.check() : null,
                this.services.me.getMe(),
                this.services.whiteLabel.fetchCurrent(),
                this.services.clientConfiguration.retrieve(),
            ]);

        if (userResponse) {
            switch (userResponse.status) {
                case HttpStatus.Ok:
                    break;
                case HttpStatus.Unauthorized:
                    runInAction(() => (this.status = SessionStatus.FailedAuthentication));
                    return;
                default:
                    this.handleAuthError(userResponse);
                    return;
            }
        }

        switch (meResponse.status) {
            case HttpStatus.Ok:
                break;
            case HttpStatus.Unauthorized:
                runInAction(() => (this.status = SessionStatus.FailedAuthentication));
                return;
            default:
                this.handleAuthError(meResponse);
                return;
        }

        if (whiteLabelResponse.status !== HttpStatus.Ok) {
            this.handleAuthError(whiteLabelResponse);
            return;
        }

        if (clientConfigurationResponse.status !== HttpStatus.Ok) {
            this.handleAuthError(clientConfigurationResponse);
            return;
        }

        let user = userResponse ? userResponse.body : null;
        const me = meResponse.body;
        const whiteLabel = whiteLabelResponse.body;
        const clientConfiguration = clientConfigurationResponse.body;

        let communities: ICommunityRead[] = [];

        if (isCM(me) || isPS(me)) {
            const adminProfileResponse = await this.services.admin.retrieve(me.uuid);

            if (adminProfileResponse.status === HttpStatus.Ok) {
                const adminProfile = adminProfileResponse.body;

                if (adminProfile.communities.length === 0) {
                    this.handleAuthError(
                        "No communities fetched for non-WLA, admin may be misconfigured"
                    );

                    return;
                }

                communities = adminProfile.communities;
            } else {
                this.handleAuthError(adminProfileResponse);
            }
        }
        if (user) {
            if (isCM(me)) {
                user.adminRoles.push(IAdminRoles.CommunityAdministrator);
            }

            if (user.uuid !== me.uuid) {
                Sentry.captureMessage(
                    `User uuid missmatch! user.uuid: ${user.uuid} and me.uuid: ${me.uuid}. Forcing me.uuid=user.uuid`
                );
                me.uuid = user.uuid;
            }
        } else if (isProperty() && !user) {
            user = {
                id: 0,
                uuid: me.uuid,
                email: me.email,
                firstName: me.firstName,
                lastName: me.lastName,
                adminRoles: [],
                phone: me.phone,
                onboardingCompleted: false,
                building: {
                    id: 0,
                    title: "",
                },
            };
            if (isPS(me) && user) {
                user.adminRoles.push(IAdminRoles.PropertyStaff);
            } else if (isWLA(me) && user) {
                user.adminRoles.push(IAdminRoles.WhitelabelAdministrator);
            }
        }

        runInAction(() => {
            this._data = { user, me, whiteLabel, communities, clientConfiguration };
            this.status = SessionStatus.Authenticated;
        });
        return true;
    }

    @action
    private handleAuthError(error: string | IApiResponse<any, any>) {
        this.status = SessionStatus.Error;
        let reference: string;

        if (typeof error === "string") {
            reference = this.reportError(error);
        } else {
            reference = this.reportResponseError(error);
        }

        this.root.global.$message.createMessage(
            MessageSeverity.FatalError,
            t`store.session.load-error`,
            reference
        );

        // Destroy any auth token so that the user doesn't
        // end up in the same state again after reload
        this.services.destroyAuthToken();
    }

    @action
    public async setToken(token: IAuthToken) {
        this.services.setAuthToken(token);
    }

    private setBaseUrl(baseUrl: string) {
        this.services.setBaseUrl(baseUrl);
    }

    @action
    public onProfileUpdated(me: IMe) {
        this._data = { ...this.dataOrThrow, me };
    }

    private redirectToIdhubLogin() {
        window.location.assign(this.getIdhubAuthUrl(this.createAuthState()));
    }

    @action.bound
    public async assertAuthenticated() {
        const { code, state } = this.root.utility.$param.params;

        let authState;

        if (code && state) {
            authState = this.verifyAuthState(state);

            if (authState) {
                const response = await this.services.auth.authenticate(code, this.getRedirectUrl());

                if (response.status === HttpStatus.Ok) {
                    await this.setToken(response.body);
                    this.setBaseUrl(response.body.baseUrl);
                } else {
                    this.reportResponseError(response);
                }
            }
        }

        await this.fetchCurrentUser();
        this.runRedirects(this.isOnPath("/login"), false, authState?.redirectURL);
    }

    @action.bound
    public async assertNotAuthenticated() {
        await this.fetchCurrentUser();
        this.runRedirects(true, true);
    }

    @action.bound
    public async login(token: IAuthToken) {
        await this.setToken(token);
        await this.fetchCurrentUser();
        this.runRedirects(true);
    }

    private get doOnboarding() {
        if (isProperty()) {
            return false;
        }

        if (!this._data) {
            return true;
        }
        if (this._data.user && this._data.user.onboardingCompleted) {
            return false;
        }
        if (this.features.disabled("forceOnboarding")) {
            return false;
        }
        if (this.enableV2Manager) {
            return false;
        }
        return true;
    }

    @action
    private runRedirects(runLoginRedirects: boolean, isOnPublicRoute = false, redirectUrl = "") {
        const { $router } = this.root.utility;

        if (this.hasSessionError()) {
            // TODO: is this required?
            this.redirectToIdhubLogin();
            return;
        }

        if (!this.canViewPrivateRoutes()) {
            if (!isOnPublicRoute) {
                this.redirectToIdhubLogin();
            }

            return;
        }

        if (this.hasNoRoles) {
            this.destroy(true);
        }

        const resolveRedirect = () => {
            let v2StartUrl = "/v2/dashboard";

            if (redirectUrl) {
                const { pathname } = new URL(redirectUrl);
                v2StartUrl = pathname;
            }

            if (runLoginRedirects) {
                if (this.doOnboarding) {
                    return "/onboarding";
                }
                if (this.showV2SideMenu) {
                    return v2StartUrl;
                } else {
                    return "/spaces";
                }
            }

            if (this.doOnboarding === this.isOnPath("/onboarding")) {
                return undefined;
            }

            if (this.doOnboarding) {
                if (readState(() => this.features.enabled("forceOnboarding"))) {
                    return "/onboarding";
                }
            } else {
                if (this.showV2SideMenu) {
                    return v2StartUrl;
                } else {
                    return "/spaces";
                }
            }

            return undefined;
        };

        const path = resolveRedirect();

        if (path) {
            $router.push(path);
        }

        return;
    }

    public getMeAsUser(): IUserRead {
        return {
            apartmentNumber: "",
            uuid: this.me.uuid,
            firstName: this.me.firstName,
            lastName: this.me.lastName,
            email: this.me.email,
            phone: this.me.phone,
            created: this.me.created,
            updated: this.me.updated,
            profile: {
                competences: this.me.competences,
                gender: this.me.gender,
                phoneSwish: this.me.phoneSwish,
                profileBio: this.me.profileBio,
                profileImage: this.me.profileImage,
                linkedinProfilelink: this.me.linkedinProfilelink,
                companyRole: this.me.companyRole,
                professionalInfo: this.me.professionalInfo,
                shareEmail: this.me.shareEmail,
                sharePhone: this.me.sharePhone,
                shareProfile: this.me.shareProfile,
                shareProfessionalProfile: this.me.shareProfessionalProfile,
            },
            leasePublicName: null,
            streetAddress: "",
            communities: [],
            revokedPermissions: [],
            roles: [],
            absent: false,
            userAbsenceSet: [],
        };
    }

    private getIdhubAuthUrl(stateUuid: string) {
        const url = new URL(`${IDHUB_URL}/login`);

        url.searchParams.set("state", stateUuid);
        url.searchParams.set("redirectUri", this.getRedirectUrl());
        url.searchParams.set("application", API_CORE_APPLICATION_IDENTIFIER);
        url.searchParams.set("wl", API_CORE_TENANT_NAME);

        return url.toString();
    }

    private createAuthState() {
        if (window.sessionStorage.getItem(stateStorageKey)) {
            warn("Auth state already exist in storage and will be overwritten.");
        }

        const authState: IAuthState = {
            uuid: uuidV4(),
            redirectURL: this.isOnPath("/login") ? undefined : window.location.href,
        };

        window.sessionStorage.setItem(stateStorageKey, JSON.stringify(authState));

        return authState.uuid;
    }

    private verifyAuthState(stateUuid: string) {
        const serializedAuthState = window.sessionStorage.getItem(stateStorageKey);

        if (!serializedAuthState) {
            warn("Auth state not found - unable to log in.");

            return;
        }

        try {
            const authState = JSON.parse(serializedAuthState) as IAuthState;

            if (stateUuid !== authState.uuid) {
                warn("Auth state was found but could not be verified - unable to log in.");

                return;
            }

            return authState;
        } catch (e) {
            const message = e instanceof Error ? e.message : null;

            this.handleAuthError(
                `Auth state was found but could not be parsed and the pitiful error thrown was ${
                    message || e
                }`
            );
        }

        return;
    }

    private isOnPath(path: string) {
        const { $router } = this.root.utility;

        return $router.location.pathname.startsWith(path);
    }

    private getRedirectUrl() {
        return `${new URL(window.location.href).origin}/login`;
    }
}
