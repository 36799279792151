export const localStorageToJSON = <Type>(key: string): Type | null => {
    const storedData = localStorage.getItem(key);
    if (!storedData) return null;
    return JSON.parse(storedData);
};

/**
 * Saves an object to localstorage based on URL. Merges
 * with already existing object
 * ```
 * {
 *  "/pathname" : {
 *     key: value
 *   }
 * }
 * ```
 */
export const updateObjectOnKeyInLocalStorage = <Type extends Record<string, any>>(
    storageKey: string,
    settingsKey: string,
    item: Type
) => {
    const storedData = localStorageToJSON<Type>(storageKey) ?? {};
    const storedDataAtURL = storedData[settingsKey] ?? {};
    const updatedData = Object.assign(storedDataAtURL, item);
    storedData[settingsKey] = updatedData;
    localStorage.setItem(storageKey, JSON.stringify(storedData));
};
