import { autorun } from "mobx";

import { APPLICATION } from "src/config/environment";
import { TargetApplication } from "src/lib/target-application";
import { Store } from "src/store";

const tokenStorageKey = "__tmpl-session-token";
const tenantFilterIdStorageKey = "__tmpl-tenant-filter-id";
const tenantFilterTagsStorageKey = "__tmpl-tenant-filter-tags";
const baseUrlStorageKey = "__tmpl-base-url";

/**
 * Load already persisted state from local storage. If no token and/or
 * filter/tags is found, clean up any persisted filters.
 */
function loadPersistedState(store: Store, localStorage: Storage) {
    const existingSessionToken = localStorage.getItem(tokenStorageKey);
    if (existingSessionToken) {
        store.services.setAuthToken(JSON.parse(existingSessionToken));
    } else {
        removeFilters(localStorage);
    }

    const existingWilFilterId = localStorage.getItem(tenantFilterIdStorageKey);
    const existingTenantFilterTags = localStorage.getItem(tenantFilterTagsStorageKey);
    if (existingWilFilterId && existingTenantFilterTags) {
        store.global.$tenant.setFilter(existingWilFilterId, JSON.parse(existingTenantFilterTags));
    } else {
        removeFilters(localStorage);
    }

    if (APPLICATION !== TargetApplication.IdHub) {
        const existingBaseUrl = localStorage.getItem(baseUrlStorageKey);
        if (existingBaseUrl) {
            store.services.setBaseUrl(existingBaseUrl);
        }
    }
}

/**
 * Perform actions whenever token changes in the store. If auth token is not set
 * in store, persisted token and filter are removed.
 */
function persistTokenOnChange(store: Store, localStorage: Storage) {
    autorun(() => {
        if (store.services.getAuthToken().isSet()) {
            localStorage.setItem(tokenStorageKey, JSON.stringify(store.services.getAuthToken()));
        } else {
            localStorage.removeItem(tokenStorageKey);
            removeFilters(localStorage);
        }
    });
}

/**
 * Perform actions whenever filter changes in the store. If the filter is
 * falsy, any persisted filter is removed.
 */
function persistFilterOnChange(store: Store, localStorage: Storage) {
    autorun(() => {
        if (store.global.$tenant.filterId) {
            localStorage.setItem(tenantFilterIdStorageKey, store.global.$tenant.filterId);
            localStorage.setItem(
                tenantFilterTagsStorageKey,
                JSON.stringify(store.global.$tenant.filterTags)
            );
        } else {
            removeFilters(localStorage);
        }
    });
}

/**
 * Perform actions whenever the base url changes in the store.
 */
function persistBaseUrlOnChange(store: Store, localStorage: Storage) {
    autorun(() => {
        /**
         * Don't store the base url in local storage if the application is
         * idhub. Idhub doesn't require a stored base url since it will fetch
         * it on load everytime.
         */
        if (APPLICATION !== TargetApplication.IdHub) {
            const baseUrl = store.services.getBaseUrl();
            if (baseUrl != null) {
                localStorage.setItem(baseUrlStorageKey, baseUrl);
            } else {
                localStorage.removeItem(baseUrlStorageKey);
            }
        }
    });
}

function removeFilters(localStorage: Storage) {
    localStorage.removeItem(tenantFilterIdStorageKey);
    localStorage.removeItem(tenantFilterTagsStorageKey);
}

export function autoSaveStore(store: Store, localStorage: Storage) {
    loadPersistedState(store, localStorage);
    persistTokenOnChange(store, localStorage);
    persistFilterOnChange(store, localStorage);
    persistBaseUrlOnChange(store, localStorage);
}
