import { objectToFormData } from "src/lib/object-to-formdata";
import { FormValuefy } from "src/models/form-value/formvaluefy-type";

import {
    IAgentV1Read,
    IGalleryFileRead,
    IResourceFilterFieldRead,
    IResourceFilterValueRead,
    IUserRead,
} from "src/services";
import { HttpStatus, IGenericApiError, IApiResponseMap } from "src/services/client";
import { Service } from "src/services/service";
import { ITag } from "src/services/services/types/tag";
import { IPaginatedResponseV1 } from "src/types/paginated-response";
import { PublishStatusEnum } from "src/types/published-status";

export interface IResourceInterval {
    startTime: string;
    endTime: string;
    weekday: number;
}

export interface IResourceIntervalRead extends IResourceInterval {
    uuid: UUID;
}

export type IResourceIntervalCreate = IResourceInterval;
export type IResourceIntervalUpsert = IResourceIntervalCreate | IResourceIntervalRead;

export enum ResourceTimeUnit {
    Daily = "daily",
    Dynamic = "dynamic",
    /** @deprecated */
    Weekly = "weekly",
}

export enum ResourceType {
    External = "external",
    Standard = "standard",
    SsoIntegration = "sso_integration",
}

export interface IResourceCategoryBase {
    uuid: string;
    identifier: string;
    label: string;
    filterFields: string[] | IResourceFilterFieldRead[];
}

export type IResourceCategoryRead = Expand<IResourceCategoryBase, "filterFields">;

export interface IResourceBase {
    uuid: UUID;
    created: string;
    updated: string;
    description: string;
    name: string;
    price: string;
    category: string | IResourceCategoryRead | null;
    image: string | null;
    files: IGalleryFileRead[];
    filterValues: string[] | IResourceFilterValueRead[];
    timeUnit: ResourceTimeUnit | "";
    owner: string | null | IAgentV1Read | IUserRead;
    intervals: IResourceIntervalRead[];
    wholeDayBookingStartTime: string;
    wholeDayBookingEndTime: string;
    phoneSwish: string;
    resourceType: ResourceType;
    url: string | null;
    urlTitle: string;
    paymentTimeUnit: string;
    minimumInterval: number;
    keyWords: string[];
    tags: string[] | ITag[];
    maxActiveBookings: number | null;
    ecommerceStockrecordId: number | null;
    ecommercePartnerId: number | null;
    ecommerceProductId: number | null;

    // No backend support yet
    isDraft?: boolean;
    status?: PublishStatusEnum;
}

export type IResourceRead = ExpandCollapse<IResourceBase, "tags", "owner" | "category">;
export type IResourceDetailsRead = ExpandCollapse<
    IResourceBase,
    "tags",
    "owner" | "category" | "filterValues" | "filterFields" | "tags"
>;

// Note: very few fields are expandable in a patch / post response
// Se we need to use a separate interface for it.
export type IResourceUpsertResponseRead = Collapse<
    IResourceBase,
    "owner" | "category" | "filterValues" | "filterFields" | "tags"
>;

export interface IResourceCreate {
    description: string;
    name: string;
    url: string;
    urlTitle: string;
    files?: UUID[];
    owner?: string;
    price: number | null;
    image?: string | File;
    intervals: IResourceIntervalUpsert[];
    maxActiveBookings: number | null;
    minimumInterval?: number | null;
    tags: string[];
}

export type IResourceUpdate = Partial<IResourceCreate>;
export type IResourceUpsert = IResourceUpdate | IResourceCreate;

export const isSameInterval = (
    interval1: Omit<IResourceIntervalRead, "uuid" | "resource">,
    interval2: Omit<IResourceIntervalRead, "uuid" | "resource">
) => {
    return (
        interval1.endTime === interval2.endTime &&
        interval1.startTime === interval2.startTime &&
        interval1.weekday === interval2.weekday
    );
};

export const isEcommerceResource = (resource: IResourceDetailsRead) => {
    return resource.ecommerceStockrecordId != null;
};

interface IResourceRetrieveQueryParams extends IQueryParams {
    start_datetime?: string;
    end_datetime?: string;
}

export class ResourceService extends Service {
    public list(params: IQueryParams) {
        params = { ...params, expand: ["owner", "category", "files"] };
        const url = this.client.url(["api", "v1", "resources"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IResourceRead>;
            }>
        >(url);
    }

    public allCategories = this.createListTraverser((params) => this.listCategories(params));

    public listCategories(params: IQueryParams) {
        params = { ...params, expand: "filter_fields" };
        const url = this.client.url(["api", "v1", "resource-categories"], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IResourceCategoryRead>;
            }>
        >(url);
    }

    public retrieve(uuid: string, params?: IResourceRetrieveQueryParams) {
        params = {
            ...params,
            expand: ["owner", "category", "filter_values", "filter_fields", "files", "tags"],
        };
        const url = this.client.url(["api", "v1", "resources", uuid], params);

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IResourceDetailsRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public create(data: FormValuefy<IResourceCreate>, params?: IResourceRetrieveQueryParams) {
        params = {
            ...params,
            expand: ["files"],
        };

        const url = this.client.url(["api", "v1", "resources"], params);

        // We need to submit as json to include the intervals, hence the image can't be submitted here.
        // @see {@link updateImage}
        const { image: _image, ...body } = data;

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IResourceUpsertResponseRead;
                [HttpStatus.BadRequest]: ValidationError<IResourceCreate>;
            }>
        >(url, body);
    }

    public update(
        uuid: string,
        data: FormValuefy<IResourceUpdate>,
        params?: IResourceRetrieveQueryParams
    ) {
        params = {
            ...params,
            expand: ["files"],
        };

        const url = this.client.url(["api", "v1", "resources", uuid], params);

        // We need to submit as json to include the intervals, hence the image can't be submitted here.
        // @see {@link updateImage}
        const { image: _image, ...body } = data;

        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IResourceUpsertResponseRead;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.BadRequest]: ValidationError<IResourceUpdate>;
            }>
        >(url, body);
    }

    public updateImage(
        uuid: string,
        data: Pick<IResourceUpdate, "image">,
        params?: IResourceRetrieveQueryParams
    ) {
        params = {
            ...params,
            expand: ["files"],
        };

        const url = this.client.url(["api", "v1", "resources", uuid], params);

        const body = objectToFormData(data);

        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IResourceUpsertResponseRead;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.BadRequest]: ValidationError<IResourceUpdate>;
            }>
        >(url, body);
    }

    public delete(uuid: string) {
        const url = this.client.url(["api", "v1", "resources", uuid]);

        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }
}
