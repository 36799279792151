import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import {
    ClientConfigurationModuleType,
    IClientConfigurationModule,
} from "src/services/services/client-configuration-service";

export class ClientModulesService extends Service {
    public retrieve<T extends ClientConfigurationModuleType>(module: T, params?: IQueryParams) {
        const url = this.client.url(["api", "v1", "client", "modules", module], params);

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IClientConfigurationModule<T>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public update<T extends ClientConfigurationModuleType>(
        module: T,
        body: Partial<IClientConfigurationModule<T>>
    ) {
        const url = this.client.url(["api", "v1", "client", "modules", module]);

        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IClientConfigurationModule<T>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, body);
    }
}
