import deepEqual from "fast-deep-equal";
import { action, computed } from "mobx";

import { pick, filter as filterObject } from "src/lib/object";
import { FormValue } from "src/models/form-value";

import { AbstractForm } from "./base-form/abstract-form";
import { observed } from "./lib/observed";

type FilterData<U> = {
    [key: string]: Partial<U>;
};

@observed
export class ListFilterStore<U> extends AbstractForm<FilterData<U>, any> {
    public data: FilterData<U> = {};

    constructor(private initialValues: Partial<U> = {}) {
        super();
    }

    @computed
    public get activeFilterCount() {
        let appliedFilters = 0;

        for (const optionId in this.data) {
            const initialValues = pick(
                this.initialValues,
                Object.keys(this.data[optionId] ?? {}) as Array<keyof U>
            );

            appliedFilters += Number(
                this.filterOptionHasChanged(initialValues, this.data[optionId])
            );
        }

        return appliedFilters;
    }

    @action
    public resetFilterAndNotifyIfHasChanged() {
        const options = Object.assign({}, ...Object.values(this.data));
        this.data = {};
        return this.filterOptionHasChanged(options, this.initialValues);
    }

    @action
    public setFilterAndNotifyIfHasChanged(optionId: string, value: Partial<U>, apply = true) {
        const oldOption = this.data[optionId] ?? {};

        this.data = { ...this.data, [optionId]: value };

        return apply && this.filterOptionHasChanged(oldOption, value);
    }

    public get appliedFilters() {
        return this.getMergedFilters();
    }

    private getMergedFilters() {
        const options = Object.values(this.data);
        return Object.assign({}, this.initialValues, ...options);
    }

    public get params() {
        const filters = this.getMergedFilters();

        return Object.fromEntries(
            Object.entries<IQueryParamValue>(filters)
                .filter(([_, value]) => value !== null && value !== undefined)
                .map(([param, value]) => [
                    param,
                    value instanceof Date
                        ? value.toISOString()
                        : value instanceof FormValue
                        ? value.value
                        : value,
                ])
        );
    }

    private filterOptionHasChanged(first: Partial<U>, second: Partial<U>) {
        const nonActiveValues = [null, undefined];

        return !deepEqual(
            filterObject(first, nonActiveValues),
            filterObject(second, nonActiveValues)
        );
    }
}
