import { createObjectSlicer } from "src/lib/slicing";

import { HttpStatus, IApiResponseMap } from "src/services/client";
import { Service } from "src/services/service";

import { ISurveyQuestionOptionRead } from "./survey-question-option-service";

export enum QuestionType {
    SingleChoice = "single",
    MultiChoice = "multiple",
    Text = "text",
}

export interface ISurveyQuestionRead {
    uuid: string;
    survey: string;
    title: string;
    description: string;
    placeholderText: string;
    type: QuestionType;
    allowComment: boolean;
    mandatory: boolean;
    position: number;
    video: string | null;
    options: ISurveyQuestionOptionRead[];
}

export type ISurveyQuestionCreate = Omit<ISurveyQuestionRead, "uuid" | "options">;
export type ISurveyQuestionUpdate = Partial<ISurveyQuestionCreate>;

const sliceUpdateable = createObjectSlicer<ISurveyQuestionUpdate>()(
    "survey",
    "title",
    "description",
    "placeholderText",
    "type",
    "allowComment",
    "mandatory",
    "position",
    "video"
);

export class SurveyQuestionService extends Service {
    public async create(data: ISurveyQuestionCreate) {
        const url = this.client.url(["api", "v1", "survey-questions"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: ISurveyQuestionRead;
                [HttpStatus.BadRequest]: ValidationError<ISurveyQuestionCreate>;
            }>
        >(url, sliceUpdateable(data));
    }

    public async update(uuid: string, data: ISurveyQuestionUpdate) {
        const url = this.client.url(["api", "v1", "survey-questions", uuid]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ISurveyQuestionRead;
                [HttpStatus.BadRequest]: ValidationError<ISurveyQuestionCreate>;
                [HttpStatus.NotFound]: void;
            }>
        >(url, sliceUpdateable(data));
    }

    public async delete(uuid: string) {
        const url = this.client.url(["api", "v1", "survey-questions", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
                [HttpStatus.NotFound]: void;
            }>
        >(url);
    }
}
