import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { AuthMethodIdentifier } from "src/services/services/client-auth-method-service";

export enum AuthenticationStatus {
    New = "new",
    Started = "started",
    Complete = "complete",
    Failed = "failed",
}

export enum AuthenticationIntent {
    Login = "login",
    SignUp = "signup",
}

export enum AuthenticationError {
    InvalidCredentials = "invalid_credentials",
    NoCommunity = "no_community",
    AuthMethodNotAllowed = "auth_method_not_allowed",
    GenericError = "generic_error",
}

enum IAuthenticationSeBankIdProviderInfoProcessStatus {
    Pending = "pending",
    Failed = "failed",
    Complete = "complete",
}

export enum IAuthenticationSeBankIdProviderInfoProcessStatusInfo {
    OutstandingTranscation = "outstandingTransaction",
    NoClient = "noClient",
    Started = "started",
    UserSign = "userSign",
    PendingOther = "pendingOther",
    ExpiredTransaction = "expiredTransaction",
    CertificateError = "certificateErr",
    UserCancel = "userCancel",
    Cancelled = "cancelled",
    StartFailed = "startFailed",
    FailedOther = "failedOther",
}

interface IAuthenticationProviderInfo {
    [AuthMethodIdentifier.Password]: null;
    [AuthMethodIdentifier.SeBankId]: {
        providerInfo: {
            seBankID: {
                processStatus: IAuthenticationSeBankIdProviderInfoProcessStatus;
                processStatusInfo: IAuthenticationSeBankIdProviderInfoProcessStatusInfo;
                autoStartToken: string;
            };
        };
    };
    [AuthMethodIdentifier.Token]: null;
    [AuthMethodIdentifier.MicrosoftAd]: null;
}

interface IAuthProviderParameters {
    [AuthMethodIdentifier.Password]: { email: string; password: string };
    [AuthMethodIdentifier.SeBankId]: {
        personalnumber: string;
        email?: string;
        redirectUrl: string;
    };
    [AuthMethodIdentifier.Token]: { email: string; password: string; token: string };
    [AuthMethodIdentifier.MicrosoftAd]: { idToken: string };
}

export enum AuthenticationErrorDetailsType {
    Auth = "authError",
    User = "userError",
    Email = "emailError",
    Password = "passwordError",
    Login = "loginError",
    Invitation = "invitationError",
    MsadConfig = "msadConfigError",
    SebankidTransaction = "sebankidTransactionError",
    ScriveConfig = "scriveConfigError",
}

/**
 * Error details can contain a lot of differently formatted values. It can be:
 *
 * An error message like: "Wrong email or password"
 * An error code like: "userCancel"
 * An object containing an error message like: { signupError: "The method does not support signup" }
 */
export type IAuthenticationErrorDetails =
    | string
    | string[]
    | IAuthenticationSeBankIdProviderInfoProcessStatusInfo
    | { [K in AuthenticationErrorDetailsType]: string };

export interface IAuthenticationCreate<T extends AuthMethodIdentifier> {
    wl: string;
    application: string;
    method: T;
    redirectUri: string;
    state: string;
    intent: AuthenticationIntent;
    providerParameters: IAuthProviderParameters[T];
}

export interface IAuthenticationRead<T extends AuthMethodIdentifier = AuthMethodIdentifier> {
    uuid: string;
    status: Exclude<AuthenticationStatus, AuthenticationStatus.Failed>;
    method: T;
    redirectUri: string;
    state: string;
    authorizationCode: string;
    providerInfo: IAuthenticationProviderInfo[T];
}

export type IAuthenticationErrorRead<T extends AuthMethodIdentifier = AuthMethodIdentifier> = Omit<
    IAuthenticationRead<T>,
    "status" | "authorizationCode"
> & {
    status: AuthenticationStatus.Failed;
    errorCode: AuthenticationError;
    errorDetails: IAuthenticationErrorDetails;
};

export enum AuthenticationErrorMessages {
    CouldNotFindUserOrInvitation = "Could not find a SSOUser, OnboardingUser nor invitation who were allowed to authenticate with the application",
    EmailDoesNotMatchInvitation = "Email does not match invitation.",
    InvalidEmailAddress = "Ange en giltig mejladress.",
    PasswordTooCommon = "Detta lösenord är alldeles för vanligt.",
    PasswordTooShort = "Detta lösenord är för kort. Det måste innehålla minst 8 tecken.",
    UserAlreadyExists = "User with email already exists.",
    // The trailing space is intentional
    InvalidCredentials = "Ogiltiga inloggningsuppgifter. ",
    UserNotAllowedToLogin = "The authenticated SSOUser was not allowed to login to the application",
}

export class AuthenticationService extends Service {
    public async retrieve<T extends AuthMethodIdentifier>(id: string) {
        const url = this.client.url(["api", "v1", "auth", "authentications", id]);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IAuthenticationRead<T> | IAuthenticationErrorRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public create<T extends AuthMethodIdentifier>(data: IAuthenticationCreate<T>) {
        const url = this.client.url(["api", "v1", "auth", "authentications"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IAuthenticationRead<T> | IAuthenticationErrorRead;
                [HttpStatus.BadRequest]: IGenericApiError;
            }>
        >(url, data);
    }
}
