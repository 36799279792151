import { objectToFormData } from "src/lib/object-to-formdata";
import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";
import { IUserRole } from "src/types/user";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

export interface IFileRootCategory {
    id: number;
    identifier: string;
    name: string;
}

export interface IFileCategory {
    id: number;
    name: string;
    rootCategory: number;
}

export interface ILibraryFile {
    id: number;
    title: string;
    description: string;
    file: string;
    iconUrl?: string;
    created: string;
    category: IFileCategory;
    spaceDocuments?: number[];
}

export interface IFileWritable {
    title: string;
    description: string;
    file: File | string;
    iconUrl: string;
    created: string;
    category: number;
    spaceDocuments: number[];
    resourceDocuments: number[];
    buildingDocuments: number[];
}

export type IFileError = ValidationError<IFileWritable>;

export type IFileCategoryError = ValidationError<IFileCategory> & {
    nonFieldErrors: string[];
    name: string[];
};

export interface IFileCategoryDeleteError {
    category: string[];
}

export type IFileCategoryCreateError = ValidationError<IFileCategory>;
export type IFileCreateError = ValidationError<IFileWritable>;
export type IFileUpdateError = ValidationError<IFileWritable>;

export class LibraryService extends Service {
    public getPaginatedFiles(url?: string, params?: IPaginationStoreParams) {
        if (!url) {
            let queryParams;
            if (params && params.page) {
                queryParams = { page: params.page };
            }
            url = this.client.url(["manager", "files"], queryParams, {
                applyWlFilter: true,
            });
        }
        return this.client.get<IApiResponse<IPaginatedBody<ILibraryFile>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async getFile(id: number) {
        const url = this.client.url(["manager", "files", id.toString()]);
        return this.client.get<IApiResponse<Required<ILibraryFile>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async deleteFile(id: number) {
        const url = this.client.url(["manager", "files", id.toString()]);
        return this.client.delete(url, {
            throwOnNon2xx: true,
        });
    }

    public async patchFile(id: number, file: Partial<IFileWritable>) {
        const url = this.client.url(["manager", "files", id.toString()]);
        let spaceDocuments: string | undefined;
        let buildingDocuments: string | undefined;
        let resourceDocuments: string | undefined;
        if (file.spaceDocuments) {
            spaceDocuments = file.spaceDocuments.toString();
        }
        if (file.buildingDocuments) {
            buildingDocuments = file.buildingDocuments.toString();
        }
        if (file.resourceDocuments) {
            resourceDocuments = file.resourceDocuments.toString();
        }
        const nextFile = { ...file, spaceDocuments, buildingDocuments, resourceDocuments };
        const body = objectToFormData(nextFile);

        if (typeof body.get("file") === "string") {
            body.delete("file");
        }

        return this.client.patch<IApiResponse<Required<IFileWritable>, number>>(url, body, {
            throwOnNon2xx: true,
        });
    }

    public async postFile(file: Partial<IFileWritable>) {
        const url = this.client.url(["manager", "files"]);
        let spaceDocuments: string | undefined;
        let buildingDocuments: string | undefined;
        let resourceDocuments: string | undefined;
        if (file.spaceDocuments) {
            spaceDocuments = file.spaceDocuments.toString();
        }
        if (file.buildingDocuments) {
            buildingDocuments = file.buildingDocuments.toString();
        }
        if (file.resourceDocuments) {
            resourceDocuments = file.resourceDocuments.toString();
        }
        const nextFile = { ...file, spaceDocuments, buildingDocuments, resourceDocuments };
        const body = objectToFormData(nextFile);

        if (typeof body.get("file") === "string") {
            body.delete("file");
        }

        return this.client.post<IApiResponse<Required<IFileWritable>, number>>(url, body, {
            throwOnNon2xx: true,
        });
    }

    public async getCategories(rootCategory: number) {
        const url = this.client.url(["manager", "file-categories"], {
            root_category: rootCategory,
        });
        return this.client.get<IApiResponse<IFileCategory[], number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async postCategory(category: Partial<IFileCategory>) {
        const url = this.client.url(["manager", "file-categories"]);
        const data = Object.assign({}, category, {
            role_relation: Object.values(IUserRole),
        });
        return this.client.post<IApiResponse<IFileCategory, number>>(url, data, {
            throwOnNon2xx: true,
        });
    }

    public async deleteCategory(category: IFileCategory) {
        const url = this.client.url(["manager", "file-categories", category.id]);
        return this.client.delete<IApiResponse<undefined, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async patchCategory(id: number, category: Partial<IFileCategory>) {
        const url = this.client.url(["manager", "file-categories", id]);
        return this.client.patch<IApiResponse<IFileCategory, number>>(url, category, {
            throwOnNon2xx: true,
        });
    }

    public async getRootCategories() {
        const url = this.client.url(["manager", "root-categories"]);
        return this.client.get<IApiResponse<IFileRootCategory[], number>>(url, {
            throwOnNon2xx: true,
        });
    }
}
