import { IApiResponse, IApiResponseMap, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { ITag, ITagFixedSubType } from "src/services/services/types/tag";
import { IPaginationStoreParams } from "src/store/_generic/pagination-store";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

import { ITagSubTypeRead } from "./tag-sub-type-service";

interface ITenantFilterOptionsSuccessResponse {
    count: number;
    next: null | string;
    previous: null | string;
    results: ITag[];
}

export class TagService extends Service {
    public async listForCategoryAndQuery(
        type: ITagFixedSubType,
        contains: string,
        params?: IQueryParams
    ): Promise<IApiResponse<IPaginatedResponseV1<ITag>, HttpStatus.Ok>>;
    public async listForCategoryAndQuery(
        type: ITagSubTypeRead,
        contains: string,
        params?: IQueryParams
    ): Promise<IApiResponse<IPaginatedResponseV1<ITag>, HttpStatus.Ok>>;
    public async listForCategoryAndQuery(
        type: ITagFixedSubType | ITagSubTypeRead,
        contains: string,
        params?: IQueryParams
    ): Promise<IApiResponse<IPaginatedResponseV1<ITag>, HttpStatus.Ok>> {
        params = {
            ...params,
            sub_type__name__in: typeof type === "string" ? type : type.name,
            page_size: 5,
            name__icontains: contains,
        };
        const url = this.client.url(["api", "v1", "tags"], params);
        return this.client.get<IApiResponse<IPaginatedResponseV1<ITag>, HttpStatus.Ok>>(url);
    }

    public async retrieveCurrentWhitelabelFilter(): Promise<ITag> {
        const url = this.client.url(["api", "v1", "tags"], {
            sub_type__name__in: ITagFixedSubType.Client,
        });
        const response = await this.client.get<
            IApiResponse<ITenantFilterOptionsSuccessResponse, number>
        >(url, {
            throwOnNon2xx: true,
        });
        const whitelabelFilter = response.body.results[0];
        if (!whitelabelFilter) {
            throw new Error("Expected whitelabel filter to be found.");
        }
        return whitelabelFilter;
    }

    public listByIds(ids: string[], params?: IPaginationStoreParams) {
        const url = this.client.url(["api", "v1", "tags"], {
            ...params,
            id__in: ids.join(","),
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ITag>;
            }>
        >(url);
    }

    public allById(ids: string[]) {
        return this.createListTraverser((params) => this.listByIds(ids, params))();
    }
}
