import { IMembershipLease, IEcomUser } from "src/services";
import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export type IArenaSubscriptions = ISubscriptions & IArenaSpecificFields;

export type IRecurringSubscriptions = ISubscriptions;

export interface IDiscount {
    id: number;
    duration: number;
    type: string;
    value: string;
}

export interface ISubscriptionOrderLine {
    id: number;
    order: {
        id: number;
        number: string;
        datePlaced: string;
    };
    productId: number;
    quantity: number;
}

export interface ISubscriptions {
    associatedSubscription: {
        id: number;
        startDate: string;
        endDate: string;
    } | null;
    bindingPeriod: number;
    canceled: string;
    discounts: IDiscount[];
    endDate: string;
    id: number;
    isDiscountsActiveDuringTrial: boolean;
    isFreeTrialPeriod: boolean;
    linkedSubscriptions: number[];
    noticePeriod: number;
    orderLine: ISubscriptionOrderLine;
    product: {
        title: string;
        description: string;
        productClass: string;
        stockrecords: {
            basePriceInclTax: number;
            basePriceExclTax: number;
            basePriceInclTaxStr: string;
            basePriceExclTaxStr: string;
            currency: string;
            discount: number;
            discountType: string;
            discountValue: number;
            hasInfiniteStock: boolean;
            id?: number;
            numAllocated: number;
            numInStock: number;
            requiredPurchase: number;
            totalPriceExclTax: number;
            totalPriceInclTax: number;
            totalPriceExclTaxStr: string;
            totalPriceInclTaxStr: string;
        }[];
    };
    references: Array<string>;
    startDate: string;
    subscriptionPeriod: string;
    totalDiscountExclTax: string;
    totalDiscountInclTax: string;
    trialPeriod: number;
    user?: IEcomUser;
}

interface IArenaSpecificFields {
    linkedSubscription: ILinkedSubscription;
    membershipLeases: IMembershipLease[];
}

export interface INationalProduct {
    id?: number;
    title: string;
    stockrecords: { id?: number; totalPriceInclTax: string }[];
}

export interface ILinkedSubscription {
    id: number;
    startDate: string;
    endDate: string;
    order: { datePlaced: string };
    bindingPeriod: number;
    noticePeriod: number;
}

export interface IProductOrder {
    organization: {
        uuid: string;
    };
    lines: IProductLine[];
}

export interface IProductLine {
    stockrecord: number;
    quantity: number;
    start_date: string;
    options: IProductLineOptions[];
}

export interface IProductLineOptions {
    code: string;
    value: number;
}

export enum SubscriptionTypes {
    ArenaSubscription = "Arenaabonnemang",
    ArenaDayPass = "Dagspass Arena",
    ArenaOffice = "Hyra Arenakontor",
    LocalServiceSubscription = "Lokaltjänster abonnemang",
    OfficeServiceSubscription = "Kontorsservice abonnemang",
}

export class SubscriptionsService extends Service {
    public async list(params?: IQueryParams, subscriptionsType?: SubscriptionTypes) {
        if (subscriptionsType) {
            params = { ...params, product_cost_type_name__in: subscriptionsType };
        }
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "subscriptions"], params);
        return this.client.get<
            | IApiResponse<IPaginatedBody<IArenaSubscriptions>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public allForSubscriptions = (params?: IQueryParams, subscriptionsType?: SubscriptionTypes) =>
        this.createCursorListTraverser((p) => this.list(p, subscriptionsType))(params);

    public cancel(subscriptionsID: number, resource?: { end_date: string }) {
        const url = this.client.url([
            "api",
            "v1",
            "ecommerce",
            "v2",
            "subscriptions",
            subscriptionsID,
            "cancel",
        ]);

        return this.client.post<
            | IApiResponse<IArenaSubscriptions, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url, resource ?? {});
    }

    public async listForNationalProducts(productID: number) {
        const params = { associated_product: productID };
        const url = this.client.url(
            ["api", "v1", "ecommerce", "v2", "proxy", "core", "products", "national-access"],
            params
        );

        return this.client.get<
            | IApiResponse<IPaginatedBody<INationalProduct>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public async addProductToSubscription(resource: IProductOrder) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2/proxy/core/orders/community"]);

        return this.client.post<
            | IApiResponse<INationalProduct, HttpStatus.Created>
            | IApiResponse<unknown, HttpStatus.BadRequest>
        >(url, resource);
    }

    public async retrieve(id: number) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "subscriptions", id]);

        return this.client.get<
            IApiResponse<ISubscriptions, HttpStatus.Ok> | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public async patchDiscount(id: number, discountId: number, resource: Partial<IDiscount>) {
        const url = this.client.url([
            "api",
            "v1",
            "ecommerce",
            "v2",
            "subscriptions",
            id,
            "discounts",
            discountId,
        ]);

        return this.client.patch<
            | IApiResponse<IDiscount, HttpStatus.Ok>
            | IApiResponse<IDiscount, HttpStatus.BadRequest>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url, resource);
    }

    public async deleteDiscount(id: number, discountId: number) {
        const url = this.client.url([
            "api",
            "v1",
            "ecommerce",
            "v2",
            "subscriptions",
            id,
            "discounts",
            discountId,
        ]);

        return this.client.delete<
            | IApiResponse<IDiscount, HttpStatus.NoContent>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public async update(id: number, resource: Partial<ISubscriptions>) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "subscriptions", id]);

        return this.client.patch<
            | IApiResponse<ISubscriptions, HttpStatus.Ok>
            | IApiResponse<ISubscriptions, HttpStatus.BadRequest>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url, resource);
    }
}
