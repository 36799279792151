// tslint:disable
// TODO: get it working
import { autorun, runInAction } from "mobx";

import { Store } from "src/store";

let hasRun = false;
const localStorage = window.localStorage;
const storageKey = "__tmpl-dev_frozen-state";

declare let global: any;

// tslint:disable: no-any
function syncToStore(obj: any, store: any) {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            if (typeof obj[prop] === "object") {
                store[prop] = store[prop] || {};
                syncToStore(obj[prop], store[prop]);
            } else {
                store[prop] = obj[prop];
            }
        }
    }
}
export const exposeFreezeStore = (store: Store) => {
    autorun(() => {
        if (!hasRun) {
            const frozenState = localStorage.getItem(storageKey);
            if (frozenState) {
                const state = JSON.parse(frozenState);
                runInAction(() => {
                    syncToStore(state, store);
                });
            }
            hasRun = true;
        }
    });

    if (global.window) {
        global.window.$freeze = () => {
            localStorage.setItem(storageKey, JSON.stringify(store));
        };

        global.window.$release = () => {
            localStorage.removeItem(storageKey);
        };
    }
};
