import { IOrderLine } from "src/services";
import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginationStoreCursorParams } from "src/store/_generic/pagination-store-cursor";
import { IPaginatedBody } from "src/types/paginated-response";

export interface IUpdatedOrderLine
    extends Pick<IOrderLine, Exclude<keyof IOrderLine, "stockrecord">> {
    stockrecord: { title: string; description: string; currency: string };
}

export interface IFolioLines {
    id: number;
    user: number;
    created: string;
    modified: string;
    folio: number;
    orderLine: IUpdatedOrderLine;
    invoiceLine: {
        invoice: number;
    } | null;
}

export class FolioLinesService extends Service {
    public async listForLeasee(params: IQueryParams) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "folio-lines"], params);

        const hydrate = (body: IPaginatedBody<IFolioLines>) => ({
            ...body,
            results: body.results.map((folioLines) => ({
                ...folioLines,
                orderLine: {
                    ...folioLines.orderLine,
                    linePriceInclTax: Number(folioLines.orderLine.linePriceInclTax),
                    linePriceExclTax: Number(folioLines.orderLine.linePriceExclTax),
                    unitPriceInclTax: Number(folioLines.orderLine.unitPriceInclTax),
                    unitPriceExclTax: Number(folioLines.orderLine.unitPriceExclTax),
                },
            })),
        });

        const response = await this.client.get<
            | IApiResponse<IPaginatedBody<IFolioLines>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);

        if (response.status === HttpStatus.Ok) {
            return this.client.hydrateBody(response, hydrate);
        } else {
            throw new Error(`Cannot find folioLine with params ${params}`);
        }
    }

    public listAllFolioLines = (params: IPaginationStoreCursorParams) =>
        this.createCursorListTraverser((p) => this.listForLeasee(p))(params);

    public async retrieve(folioLineId: number) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "folio-lines", folioLineId]);
        const hydrate = (folioLine: IFolioLines) => ({
            ...folioLine,
            orderLine: {
                ...folioLine.orderLine,
                linePriceInclTax: Number(folioLine.orderLine.linePriceInclTax),
                linePriceExclTax: Number(folioLine.orderLine.linePriceExclTax),
                unitPriceInclTax: Number(folioLine.orderLine.unitPriceInclTax),
                unitPriceExclTax: Number(folioLine.orderLine.unitPriceExclTax),
            },
        });
        const response = await this.client.get<
            IApiResponse<IFolioLines, HttpStatus.Ok> | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);

        if (response.status === HttpStatus.Ok) {
            return this.client.hydrateBody(response, hydrate);
        } else {
            throw new Error(`Cannot find folioLine with id ${folioLineId}`);
        }
    }
}
