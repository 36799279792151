import { createObjectSlicer } from "src/lib/slicing";

import { HttpStatus, IApiResponseMap } from "src/services/client";
import { Service } from "src/services/service";

export interface ISurveyQuestionOptionRead {
    uuid: string;
    question: string;
    text: string;
    position: number;
    displayTextfield: boolean;
}

export interface ISurveyQuestionOptionCreate {
    question: string;
    /*(required; max length=1000) */
    text: string;
    position: number;
    displayTextfield: boolean;
}
export type ISurveyQuestionOptionUpdate = Partial<ISurveyQuestionOptionCreate>;

const sliceUpdateable = createObjectSlicer<ISurveyQuestionOptionUpdate>()(
    "question",
    "text",
    "position",
    "displayTextfield"
);

export class SurveyQuestionOptionService extends Service {
    public async create(data: ISurveyQuestionOptionCreate) {
        const url = this.client.url(["api", "v1", "survey-question-options"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: ISurveyQuestionOptionRead;
                [HttpStatus.BadRequest]: ValidationError<ISurveyQuestionOptionCreate>;
            }>
        >(url, sliceUpdateable(data));
    }

    public async update(uuid: string, data: ISurveyQuestionOptionUpdate) {
        const url = this.client.url(["api", "v1", "survey-question-options", uuid]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ISurveyQuestionOptionRead;
                [HttpStatus.BadRequest]: ValidationError<ISurveyQuestionOptionCreate>;
                [HttpStatus.NotFound]: void;
            }>
        >(url, sliceUpdateable(data));
    }

    public async delete(uuid: string) {
        const url = this.client.url(["api", "v1", "survey-question-options", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
                [HttpStatus.NotFound]: void;
            }>
        >(url);
    }
}
