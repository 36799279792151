import Queue from "p-queue";

/**
 * If a client sends more than five requests per second the api responds with
 * status 414 and no cors headers. Since the client cannot handle responses
 * with incorrect cors headers a generic error message will be shown to the
 * user.
 *
 * If the client tries to perform too many requests too quickly RequestQueue will
 * limit the number of requests per one second window to five.
 */
export class RequestQueue {
    private static DEFAULT_INTERVAL_MS = 1_000;
    private static INTERVAL_MS = this.DEFAULT_INTERVAL_MS;

    private queue: Queue;

    constructor(readonly requestsPerSecond: number) {
        this.queue = new Queue({
            concurrency: requestsPerSecond,
            interval: RequestQueue.INTERVAL_MS,
            intervalCap: requestsPerSecond,
        });
    }

    public static getInterval() {
        return this.INTERVAL_MS;
    }

    public static disable() {
        RequestQueue.INTERVAL_MS = 0;
    }

    public static enable() {
        RequestQueue.INTERVAL_MS = this.DEFAULT_INTERVAL_MS;
    }

    public get paused() {
        return this.queue.isPaused;
    }

    public get pending() {
        return this.queue.size;
    }

    public get requestsInFlight() {
        return this.queue.pending;
    }

    public pause() {
        this.queue.pause();
    }

    public resume() {
        this.queue.start();
    }

    public async enqueueRequest(request: () => Promise<void>) {
        return this.queue.add(() => {
            return request();
        });
    }
}
