import {
    GroupRoleAdmin,
    GroupRole,
    IGroupRoleRead,
    ILeaseRead,
    PremiseType,
    GroupRoleUser,
} from "src/services";

interface IUserWithRoles {
    roles: IGroupRoleRead[];
}

export function hasRole(user: IUserWithRoles, role: GroupRole) {
    return user.roles.some((groupRole) => groupRole.role === role);
}

export function isWLA(user: IUserWithRoles) {
    return hasRole(user, GroupRoleAdmin.WhitelabelAdministrator);
}

export function isCM(user: IUserWithRoles) {
    return hasRole(user, GroupRoleAdmin.CommunityAdministrator);
}

export function isPS(user: IUserWithRoles) {
    return hasRole(user, GroupRoleAdmin.PropertyStaff);
}

export function isAdmin(user: IUserWithRoles) {
    return isWLA(user) || isCM(user) || isPS(user);
}

export function getGroupRoleForLease(lease: ILeaseRead) {
    const type = lease.premises.type;

    if (type === PremiseType.Residential) {
        return [GroupRoleUser.Tenant, GroupRoleUser.Resident];
    } else if (type === PremiseType.Office) {
        return [GroupRoleUser.Coworker, GroupRoleUser.ContactPerson];
    }

    return [];
}
