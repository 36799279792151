import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export interface IEcomUser {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    organization: IEcomOrganization;
    reference: string;
    baseUrl: string;
}

export interface IEcomOrganization {
    agreementNumber: string;
    baseUrl: string;
    companyName: string;
    extra: { dayPassLimitSet: number; dayPassLimitAuto: number };
    id: number;
    name: string;
    orgNr: string;
    reference: string;
}

export class EcomUsersService extends Service {
    public retrieve(leaseID: string) {
        const queryParams = { organization_reference: leaseID };
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "users"], queryParams);
        return this.client.get<
            | IApiResponse<IPaginatedBody<IEcomUser>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }
}
