import { HttpStatus, IApiResponseMap } from "src/services/client";
import { Service } from "src/services/service";
import { ITag } from "src/services/services/types/tag";

interface ICreateTenantFilterSuccessResponse {
    tags: { id: string }[];
    uuid: string;
}

interface IGetTenantFilterSuccessResponse {
    tags: ITag[];
    uuid: string;
}

export class TagFilterService extends Service {
    public retrieve(id: string) {
        const url = this.client.url(["api", "v1", "tag-filters", id]);
        return this.client.get<
            IApiResponseMap<{ [HttpStatus.Ok]: IGetTenantFilterSuccessResponse }>
        >(url, {
            throwOnNon2xx: true,
        });
    }

    public create(tags: ITag[] | string[]) {
        const url = this.client.url(["api", "v1", "tag-filters"]);
        const ids = tags.map((tag: ITag | string) => (typeof tag === "string" ? tag : tag.id));
        return this.client.post<
            IApiResponseMap<{ [HttpStatus.Created]: ICreateTenantFilterSuccessResponse }>
        >(
            url,
            {
                tags: ids,
            },
            {
                throwOnNon2xx: true,
            }
        );
    }
}
