import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IUserDetailsRead } from "src/services/services/user-service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export class PremiseLeaseUserService extends Service {
    public delete(leaseId: string, userId: string) {
        const url = this.client.url(["api", "v1", "leases", leaseId, "users", userId]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: never;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public list(leaseId: string, params?: IQueryParams) {
        params = { ...params, expand: "roles" };

        const url = this.client.url(["api", "v1", "leases", leaseId, "users"], params);

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IUserDetailsRead>; // TODO: This is actually an Agent
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }
}
