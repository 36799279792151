import { ITagSubTypeRead } from "src/services";
import { ITag, ITagFixedSubType } from "src/services/services/types/tag";

export const tagsToLocation = (tags: ITag[]) => {
    const communityTags = tags.filter((tag) => tag.subType === ITagFixedSubType.Community);
    if (communityTags.length === 1) {
        return communityTags[0].name;
    }

    const buildingTags = tags.filter((tag) => tag.subType === ITagFixedSubType.Building);
    if (buildingTags.length === 1) {
        return buildingTags[0].name;
    }

    return null;
};

export const dropClientTags = (tags: ITag[]) => {
    return tags.filter((tag) => tag.subType !== ITagFixedSubType.Client);
};

export const dropUserTags = (tags: ITag[]) => {
    return tags.filter((tag) => tag.subType !== ITagFixedSubType.User);
};

/**
 * Sort order is set from largest to smallest. If a TagSubType is below another
 * TagSubType it can be seen as a child of that TagSubType in the tag tree.
 * This isn't 100% how it actually works in the backend, but it's a reasonably
 * logical model for the users.
 *
 * Some TagSubTypes don't fit into this model and are placed at the bottom.
 */
const hierarchicalTagSubTypeOrder: { [key in ITagFixedSubType]: number } = {
    [ITagFixedSubType.Client]: 1,
    [ITagFixedSubType.City]: 2,
    [ITagFixedSubType.ZipCode]: 3,
    [ITagFixedSubType.District]: 4,
    [ITagFixedSubType.Area1]: 5,
    [ITagFixedSubType.Area2]: 6,
    [ITagFixedSubType.Community]: 7,
    [ITagFixedSubType.RealEstate]: 8,
    [ITagFixedSubType.Building]: 9,
    [ITagFixedSubType.Address]: 10,
    [ITagFixedSubType.Space]: 11,

    // The tags below are not part of the hierarchy in the same way us the others.
    [ITagFixedSubType.CommunityLease]: 12,
    [ITagFixedSubType.Membership]: 13,
    [ITagFixedSubType.User]: 14,
};

export const sortTagSubTypes = (tagSubTypes: ITagSubTypeRead[]) => {
    tagSubTypes.sort((a, b) => {
        // Unspecified TagSubTypes gets the position Infinity to place them last
        // in the sorted array.
        const aPosition = hierarchicalTagSubTypeOrder[a.name] ?? Infinity;
        const bPosition = hierarchicalTagSubTypeOrder[b.name] ?? Infinity;
        return aPosition - bPosition;
    });
};
