import { objectToFormData } from "src/lib/object-to-formdata";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";

import { IPaginatedResponseV1 } from "src/types/paginated-response";

import { PublishStatusEnum } from "src/types/published-status";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

import { ISurveyQuestionRead, ISurveyQuestionCreate } from "./survey-question-service";
import { ITag } from "./types/tag";

export interface ISurveyRead {
    uuid: string;
    title: string;
    internalTitle: string;
    description: string;
    picture: string | undefined;
    startDate: string | null;
    endDate: string | null;
    completionTitle: string;
    completionText: string;
    pinned: string | null;
    questions: string[] | ISurveyQuestionRead[];
    completedCount: number;
    answerCount: number;
    tags: string[] | ITag[];
    isDraft: boolean;
    status: PublishStatusEnum;
}

export interface ISurveyCreate {
    title: string;
    internalTitle: string;
    description: string;
    picture: undefined | File;
    startDate: string;
    endDate: string;
    completionTitle: string;
    completionText: string;
    pinned: string | null;
    tags: string[];
    questions: ISurveyQuestionCreate[];
    isDraft: boolean;
}

export interface ISurveyUpdate extends Partial<Omit<ISurveyCreate, "picture">> {
    picture: File | undefined | null;
}

type Expandables = "questions" | "tags";

export class SurveyService extends Service {
    public async list(params?: IPaginationStoreParams) {
        const url = this.client.url(["api", "v1", "surveys"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ISurveyRead>;
            }>
        >(url);
    }

    public async retreive<Expand extends Expandables = never>(
        uuid: string,
        { expand }: { expand?: Expand[] } = {}
    ) {
        const url = this.client.url(["api", "v1", "surveys", uuid], {
            expand,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: ExpandCollapse<ISurveyRead, Expandables, Expand>;
                [HttpStatus.NotFound]: void;
            }>
        >(url);
    }

    public async create(data: ISurveyCreate) {
        const url = this.client.url(["api", "v1", "surveys"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: ISurveyRead;
                [HttpStatus.BadRequest]: ValidationError<ISurveyCreate>;
            }>
        >(url, this.prepareData(data));
    }

    public async update(uuid: string, data: ISurveyUpdate) {
        const url = this.client.url(["api", "v1", "surveys", uuid]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ISurveyRead;
                [HttpStatus.NotFound]: void;
                [HttpStatus.BadRequest]: ValidationError<ISurveyUpdate>;
            }>
        >(url, this.prepareData(data));
    }

    public async delete(uuid: string) {
        const url = this.client.url(["api", "v1", "surveys", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
                [HttpStatus.NotFound]: void;
            }>
        >(url);
    }

    public async export(uuid: string) {
        const url = this.client.url(
            ["api", "v1", "survey-answers", uuid, "export"],
            {},
            {
                applyTagFilter: true,
            }
        );
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: void;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, {});
    }

    public prepareData(data: ISurveyCreate | ISurveyUpdate) {
        const formData = objectToFormData(data);

        if (typeof formData.get("picture") === "string") {
            formData.delete("picture");
        }

        if (formData.get("pinned") === "null") {
            formData.delete("pinned");
        }

        return formData;
    }
}
