import { FormValue } from "src/models/form-value";
import { IAuthToken } from "src/services/auth-token";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";

import { ICommunityRead } from "src/services/services/community-service";
import { IPaginatedBody } from "src/types/paginated-response";

import { IGroupRoleAdminRead } from "./admin-service";

export enum AdminInvitationStatus {
    Pending = "pending",
    Accepted = "accepted",
}

export interface IAdministratorInvitationBase {
    uuid: string;
    created: string;
    updated: string;
    sent?: string;
    email: string;
    status: AdminInvitationStatus;
    host: string;
    role: string | IGroupRoleAdminRead;
    contexts: string[];
}

export type IAdministratorInvitationRead = Expand<IAdministratorInvitationBase, "role">;
export type IAdministratorInvitationDetailsRead = IAdministratorInvitationRead;

export interface IAdminInvitationCreate {
    email: string;
    role: string;
    groups: (FormValue<ICommunityRead> | string)[];
}

export type IAdminInvitationCreateError = ValidationError<IAdminInvitationCreate> & {
    user: string[];
};
export type IAdminInvitationAcceptError = ValidationError<IAdminInvitationAcceptCreate>;

export interface IAdminInvitationAcceptCreate {
    password: string;
    firstName: string;
    lastName: string;
}

export interface IAdminInvitationAcceptRead {
    token: IAuthToken;
}

export class AdminInvitationService extends Service {
    public list(params?: IQueryParams) {
        params = { ...params, expand: "role" };
        const url = this.client.url(["api", "v1", "admin-invitations"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<IAdministratorInvitationRead>;
            }>
        >(url);
    }

    public create(adminInvitation: IAdminInvitationCreate) {
        const url = this.client.url(["api", "v1", "admin-invitations"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: Collapse<IAdministratorInvitationBase, "role">;
                [HttpStatus.BadRequest]: IAdminInvitationCreateError;
            }>
        >(url, adminInvitation);
    }

    public delete(uuid: string) {
        const url = this.client.url(["api", "v1", "admin-invitations", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: "";
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public resend(uuid: string) {
        const url = this.client.url(["api", "v1", "admin-invitations", uuid, "resend"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: Collapse<IAdministratorInvitationBase, "role">;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, {});
    }

    public retrieve(id: string) {
        const url = this.client.url(["api", "v1", "admin-invitations", id], {
            expand: "role",
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IAdministratorInvitationDetailsRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public accept(id: string, data: IAdminInvitationAcceptCreate) {
        const url = this.client.url(["api", "v1", "admin-invitations", id, "accept"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: IAdminInvitationAcceptRead;
                [HttpStatus.BadRequest]: IAdminInvitationAcceptError;
            }>
        >(url, data, {
            expectedStatus: [HttpStatus.Ok, HttpStatus.BadRequest, HttpStatus.Unauthorized],
        });
    }
}
