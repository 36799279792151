import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IBuildingDetailsRead } from "src/services/services/building-service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface IMainEntranceRead {
    uuid: string;
    created: string;
    updated: string;
    city: string;
    streetAddress: string;
    zipCode: string;
    longitude: number;
    latitude: number;
    building: IBuildingDetailsRead;
}

export type IMainEntranceCreate = {
    city: string;
    streetAddress: string;
    zipCode: string;
    longitude: number;
    latitude: number;
    building: string;
};

export interface IMainEntranceUpdate extends IMainEntranceCreate {
    uuid: string;
}

export type IMainEntranceCreateError = ValidationError<IMainEntranceCreate>;
export type IMainEntranceUpdateError = ValidationError<IMainEntranceUpdate>;

export interface IMainEntranceDeleteError {
    mainEntrance: string[];
}

export type IMainEntranceUpsert = IMainEntranceUpdate | IMainEntranceCreate;

export class MainEntranceService extends Service {
    public list = (params?: IQueryParams) => {
        params = { ...params, expand: "building" };
        const url = this.client.url(["api", "v1", "main-entrances"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            | IApiResponse<IPaginatedResponseV1<IMainEntranceRead>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    };

    public listForBuilding = (buildingId: string, params?: IQueryParams) => {
        params = { ...params, expand: "building" };
        const url = this.client.url(
            ["api", "v1", "buildings", buildingId, "main-entrances"],
            params
        );
        return this.client.get<
            | IApiResponse<IPaginatedResponseV1<IMainEntranceRead>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    };

    public allForBuilding = (buildingId: string, params?: IQueryParams) =>
        this.createListTraverser((p) => this.listForBuilding(buildingId, p))(params);

    public create(data: IMainEntranceCreate) {
        const url = this.client.url(["api", "v1", "main-entrances"], {
            expand: "building",
        });
        return this.client.post<
            | IApiResponse<IMainEntranceRead, HttpStatus.Created>
            | IApiResponse<IMainEntranceCreateError, HttpStatus.BadRequest>
        >(url, data);
    }

    public update(data: IMainEntranceUpdate) {
        const url = this.client.url(["api", "v1", "main-entrances", data.uuid], {
            expand: "building",
        });
        return this.client.patch<
            | IApiResponse<IMainEntranceRead, HttpStatus.Ok>
            | IApiResponse<IMainEntranceCreateError, HttpStatus.BadRequest>
        >(url, data);
    }

    public delete(id: string) {
        const url = this.client.url(["api", "v1", "main-entrances", id]);
        return this.client.delete<
            | IApiResponse<unknown, HttpStatus.Ok>
            | IApiResponse<IMainEntranceDeleteError, HttpStatus.BadRequest>
        >(url);
    }

    public upsert(data: IMainEntranceUpsert) {
        if ("uuid" in data) {
            return this.update(data);
        } else {
            return this.create(data);
        }
    }
}
