import { DeepPartial } from "utility-types";

import { objectToFormData } from "src/lib/object-to-formdata";
import { createObjectSlicer } from "src/lib/slicing";
import { snakeCase } from "src/lib/transform-case";
import { IApiResponseMap, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginationStoreParams } from "src/store/_generic";
import { IPaginatedResponseV1, IPaginatedObject } from "src/types/paginated-response";

import {
    ClientConfigurationModuleType,
    IClientConfigurationModule,
    ClientConfigurationModuleSettings,
} from "./client-configuration-service";

import { IUserRead } from "./user-service";

export enum CommunityGroupRoleName {
    CommunityAdministrator = "community_administrator",
}

export enum CommunityType {
    Coworking = "coworking",
    Residential = "residential",
    Office = "office",
}

export interface ICommunityRead {
    uuid: string;
    title: string;
    isDraft: boolean;
    type: CommunityType;
    roles: never[];
    streetAddress?: string;
    zipCode?: string | null;
    city?: string;
    groupRoles: { roleUuid: string; roleName: CommunityGroupRoleName }[];
    created: string;
    updated: string;
    publishDate: string | null;
    phone: string;
    email: string;
    defaultIssueHandler?: undefined | string | IUserRead;
    icon: string | null;
    background: string | null;
    logo: string | null;
    premises: string[];
    publicName: string;
    buildings: string[];
    mainEntrances: string[];
    realEstates: string[];
}
type CommunityExpandables = "defaultIssueHandler";

export interface ICommunityAssetsSave {
    icon: File | "";
    background: File | "";
    logo: File | "";
}

export type CommunityModuleType = ClientConfigurationModuleType;
export enum CommunityModuleSoureLevel {
    Default = "default",
    Community = "community",
}

export interface ICommunityModuleRead<T extends CommunityModuleType>
    extends IClientConfigurationModule<T> {
    moduleType: T;
    sourceLevel: CommunityModuleSoureLevel;
}

export interface ICommunityModuleCreate<T extends CommunityModuleType> {
    isActive: boolean;
    settings: ClientConfigurationModuleSettings[T];
}

export interface ICommunityModuleUpdate<T extends CommunityModuleType> {
    isActive?: boolean;
    settings?: DeepPartial<ClientConfigurationModuleSettings[T]>;
}

export type ICommunityReadError = ValidationError<ICommunityRead>;

function keysOf<T>() {
    return <TKey extends keyof T>(...keys: TKey[]) => keys;
}

const updateProps = keysOf<ICommunityRead>()(
    "title",
    "streetAddress",
    "zipCode",
    "city",
    "phone",
    "email",
    "premises",
    "buildings",
    "mainEntrances",
    "realEstates",
    "defaultIssueHandler"
);

type UpdateProps = typeof updateProps[0];
type CreateProps = UpdateProps | "isDraft" | "type";

export type ICommunityUpdate = Partial<
    Collapse<Pick<ICommunityRead, UpdateProps>, CommunityExpandables>
>;
export type ICommunityCreate = Collapse<Pick<ICommunityRead, CreateProps>, CommunityExpandables>;

const defensiveCopyForUpdate = createObjectSlicer<ICommunityUpdate>()(...updateProps);
const defensiveCopyForCreate = createObjectSlicer<ICommunityCreate>()(
    "isDraft",
    "type",
    ...updateProps
);

export class CommunityService extends Service {
    private static baseUrl = ["api", "v1", "communities"];

    public allByUuids = (uuids: string[], extraParams?: IQueryParams) =>
        this.createListTraverser((params) =>
            this.listByUuids(uuids, { ...extraParams, ...params })
        )();

    public list = (params: IQueryParams) => {
        const url = this.client.url(CommunityService.baseUrl, params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ICommunityRead>;
            }>
        >(url);
    };

    public all = this.createListTraverser(this.list);

    public listByUuids(uuids: string[], params: IQueryParams) {
        const url = this.client.url(CommunityService.baseUrl, { ...params, uuid__in: uuids });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ICommunityRead>;
            }>
        >(url);
    }

    public getUsers(uuid: string, params?: IQueryParams) {
        const url = this.client.url(["api", "v1", "communities", uuid, "users"], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IUserRead>;
            }>
        >(url);
    }

    public retrieve<T extends CommunityExpandables | never = never>(
        uuid: string,
        { expand }: { expand?: T[] } = {}
    ) {
        const url = this.client.url([...CommunityService.baseUrl, uuid], {
            expand: expand ? expand.map(snakeCase) : undefined,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: ExpandCollapse<ICommunityRead, CommunityExpandables, T>;
            }>
        >(url);
    }

    public delete(uuid: string) {
        const url = this.client.url([...CommunityService.baseUrl, uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
            }>
        >(url);
    }

    public create(data: ICommunityCreate) {
        const url = this.client.url(CommunityService.baseUrl);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: ICommunityRead;
                [HttpStatus.BadRequest]: ICommunityReadError;
            }>
        >(url, defensiveCopyForCreate(data));
    }

    public update(uuid: string, data: ICommunityUpdate) {
        const url = this.client.url([...CommunityService.baseUrl, uuid]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ICommunityRead;
                [HttpStatus.BadRequest]: ICommunityReadError;
            }>
        >(url, defensiveCopyForUpdate(data));
    }

    public updateAssets(uuid: string, data: ICommunityAssetsSave) {
        const url = this.client.url([...CommunityService.baseUrl, uuid]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ICommunityRead;
                [HttpStatus.BadRequest]: ICommunityReadError;
            }>
        >(url, objectToFormData(data));
    }

    public retrieveModules(communityId: string) {
        return this.traversePaginatedObject((paginationParams: IPaginationStoreParams) => {
            const url = this.client.url(
                [...CommunityService.baseUrl, communityId, "modules"],
                paginationParams
            );
            return this.client.get<
                IApiResponseMap<{
                    [HttpStatus.Ok]: IPaginatedObject<{
                        [type in CommunityModuleType]: ICommunityModuleRead<type>;
                    }>;
                }>
            >(url);
        });
    }

    public retrieveModule<T extends CommunityModuleType>(communityId: string, moduleType: T) {
        const url = this.client.url([
            ...CommunityService.baseUrl,
            communityId,
            "modules",
            moduleType,
        ]);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: ICommunityModuleRead<T>;
                [HttpStatus.NotFound]: void;
            }>
        >(url);
    }

    public async updateModule<T extends CommunityModuleType>(
        communityId: string,
        moduleType: T,
        module: ICommunityModuleUpdate<T>
    ) {
        const url = this.client.url([
            ...CommunityService.baseUrl,
            communityId,
            "modules",
            moduleType,
        ]);
        const result = await this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ICommunityModuleRead<T>;
                [HttpStatus.BadRequest]: any;
            }>
        >(url, module);
        if (result.status === HttpStatus.Ok) {
            // PATCH does not return sourceLevel and moduleType so we fill them
            // in since we know what they must be.
            result.body.sourceLevel = CommunityModuleSoureLevel.Community;
            result.body.moduleType = moduleType;
        }
        return result;
    }

    public resetModule<T extends CommunityModuleType>(communityId: string, moduleType: T) {
        const url = this.client.url([
            ...CommunityService.baseUrl,
            communityId,
            "modules",
            moduleType,
        ]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.Ok]: void;
            }>
        >(url);
    }

    public publish(uuid: string) {
        const url = this.client.url([...CommunityService.baseUrl, uuid, "publish"], {});
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: ICommunityRead;
            }>
        >(url, {});
    }
}
