import { HttpStatus, IGenericApiError, IApiResponseMap } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface IFileCategory {
    uuid: string;
    name: string;
    parent: string | null;
    children: string[];
    roleRelation: string[];
    boardMembersCanReadAndWrite: true;
    allowUserUpload: true;
    icon: string;
    description: string;
    position: number;
    tags: string[];
}

export type IFileRootCategory = IFileCategory & {
    parent: null;
};

export type IFileCategoryUpsertError = ValidationError<IFileCategory> & {
    nonFieldErrors: string[];
    name: string[];
};

export class FileCategoryService extends Service {
    public async list(params?: IQueryParams) {
        const url = this.client.url(["api", "v1", "file-categories"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IFileCategory>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async create(category: Partial<IFileCategory>) {
        const url = this.client.url(["api", "v1", "file-categories"]);
        const data = { ...category, children: [], role_relation: [] };
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IFileCategory;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.BadRequest]: IFileCategoryUpsertError;
            }>
        >(url, data);
    }

    public async update(uuid: string, category: Partial<IFileCategory>) {
        const url = this.client.url(["api", "v1", "file-categories", uuid]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IFileCategory;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.BadRequest]: IFileCategoryUpsertError;
            }>
        >(url, category);
    }

    public async delete(uuid: string) {
        const url = this.client.url(["api", "v1", "file-categories", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.BadRequest]: IFileCategoryUpsertError;
            }>
        >(url);
    }
}
