import { toIsoDateString } from "src/lib/date-formatters";
import { objectToFormData } from "src/lib/object-to-formdata";
import { IApiResponseMap, HttpStatus, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { ITag } from "src/services/services/types/tag";
import { IUserRead } from "src/services/services/user-service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export enum SignableDocumentStatus {
    Pending = "pending",
    Active = "active",
    PartlySigned = "partly_signed",
    FullySigned = "fully_signed",
    Completed = "completed",
    Failed = "failed",
    Expired = "expired",
}

export interface ISignableDocumentSignatoryRead {
    user: IUserRead;
    signedAt: string | null;
}

export interface ISignableDocumentRead {
    uuid: string;
    status: SignableDocumentStatus;
    title: string;
    isActive: boolean;
    file: string | null;
    daysToSign: number;
    signDueDate: string;
    signatories: ISignableDocumentSignatoryRead[];
    signedFile: string | null;
    failureDetails: string | null;
    tags: ITag[];
    creator: string;
    created: string;
    updated: string;
}

export interface ISignableDocumentCreate {
    title: string;
    file: File | string | null;
    signDueDate: Date | null;
    tags: string[];
}

export interface ISignableDocumentUpdate extends ISignableDocumentCreate {
    uuid: string;
}

export type ISignableDocumentUpsert = ISignableDocumentCreate | ISignableDocumentUpdate;

export class SignableDocumentService extends Service {
    public async list(params?: IQueryParams): Promise<
        IApiResponseMap<{
            [HttpStatus.Ok]: IPaginatedResponseV1<ISignableDocumentRead>;
        }>
    > {
        const url = this.client.url(
            ["api", "v1", "signable-documents"],
            {
                ...params,
                expand: ["tags", "signatories", "signatories.user"],
            },
            { applyTagFilter: true }
        );
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ISignableDocumentRead>;
            }>
        >(url);
    }

    public async retrieve(id: string) {
        const url = this.client.url(["api", "v1", "signable-documents", id], {
            expand: ["tags", "signatories", "signatories.user"],
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: ISignableDocumentRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async create(data: ISignableDocumentCreate) {
        const url = this.client.url(["api", "v1", "signable-documents"], {
            expand: ["tags", "signatories", "signatories.user"],
        });
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ISignableDocumentRead>;
                [HttpStatus.BadRequest]: ValidationError<ISignableDocumentUpdate>;
            }>
        >(url, this.prepareData(data));
    }

    public async update(data: ISignableDocumentUpdate) {
        const url = this.client.url(["api", "v1", "signable-documents", data.uuid], {
            expand: ["tags", "signatories", "signatories.user"],
        });
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ISignableDocumentRead>;
                [HttpStatus.BadRequest]: ValidationError<ISignableDocumentUpdate>;
            }>
        >(url, this.prepareData(data));
    }

    public async upsert(data: ISignableDocumentUpsert) {
        if ("uuid" in data && typeof data.uuid !== "undefined") {
            return this.update(data);
        } else {
            return this.create(data);
        }
    }

    private prepareData(data: ISignableDocumentUpsert) {
        const formData = objectToFormData({
            ...data,
            signDueDate: data.signDueDate ? toIsoDateString(data.signDueDate) : null,
        });

        if (typeof formData.get("file") === "string") {
            formData.delete("file");
        }

        return formData;
    }
}
