import { makeObservable } from "mobx";

/**
 * Store decorator
 *
 * Annotates a class as being a store
 *
 * Makes instances of the class observed. Used together with the `@observable`
 * decorator on class properties.
 */
export function store<T extends { new (...args: any[]): any }>(constructor: T) {
    return class extends constructor {
        constructor(...args: any[]) {
            super(...args);

            makeObservable(this);
        }
    };
}
