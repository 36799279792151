import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface IWordMonitoringConfigRead {
    uuid: string;
    words: string[];
    created: string;
    updated: string;
}

export interface IWordMonitoringConfigUpsert {
    words: string[];
}

export type IWordMonitoringConfigUpsertError = ValidationError<IWordMonitoringConfigUpsert>;

export class WordMonitoringConfigsService extends Service {
    public async list(params: IQueryParams) {
        const url = this.client.url(["api", "v1", "word-monitoring-configs"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IWordMonitoringConfigRead>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async retrieve(uuid: string, params?: IQueryParams) {
        const url = this.client.url(["api", "v1", "word-monitoring-configs", uuid], params);

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IWordMonitoringConfigRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async create(body: IWordMonitoringConfigUpsert) {
        const url = this.client.url(["api", "v1", "word-monitoring-configs"]);

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IWordMonitoringConfigRead;
                [HttpStatus.BadRequest]: IWordMonitoringConfigUpsertError;
            }>
        >(url, body);
    }

    public async update(uuid: string, body: IWordMonitoringConfigUpsert) {
        const url = this.client.url(["api", "v1", "word-monitoring-configs", uuid]);

        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IWordMonitoringConfigRead;
                [HttpStatus.BadRequest]: IWordMonitoringConfigUpsertError;
            }>
        >(url, body);
    }

    public async delete(uuid: string) {
        const url = this.client.url(["api", "v1", "word-monitoring-configs", uuid]);

        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: undefined;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }
}
