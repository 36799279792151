import * as Sentry from "@sentry/browser";

import Debug from "debug";
import { makeObservable } from "mobx";

import { Class } from "utility-types";

import { DeployEnvironment, DEPLOY_ENVIRONMENT } from "src/config/environment";
import { LOADER, IHasLoader } from "src/lib/loading";
import { storeItemToJSON } from "src/lib/store-item-to-json";
import { Services } from "src/services";
import { IApiResponse, IGenericApiError } from "src/services/client";
import { Store } from "src/store";
import {
    IPaginationServiceEndpoint,
    IPaginationStoreParams,
    PaginationSortOrder,
} from "src/store/_generic";

import { PageStore } from "src/store/_generic/page-store";
import { ILoadingStateKeys } from "src/store/global";

import {
    IPaginationServiceEndpointCursor,
    PaginationStoreCursor,
} from "./_generic/pagination-store-cursor";
import { BaseForm } from "./base-form";

const warn = Debug("tmpl:warn:store/base");

export abstract class BaseStore implements IHasLoader {
    constructor(protected readonly root: Store) {}

    get [LOADER]() {
        return this.root.utility.$loading;
    }

    get services(): Services {
        return this.root.services;
    }

    public toJSON() {
        return storeItemToJSON(this);
    }

    protected cursorPaginated<T>(
        loadingKey: ILoadingStateKeys,
        serviceEndpoint: IPaginationServiceEndpointCursor<T>,
        useLoadMoreStore?: boolean
    ) {
        return new PaginationStoreCursor(
            (params) =>
                this.root.utility.$loading.around(loadingKey, () => serviceEndpoint(params)),
            useLoadMoreStore
        );
    }

    protected sortPaginated<T, SortingName extends string>(
        loadingKey: ILoadingStateKeys,
        serviceEndpoint: IPaginationServiceEndpoint<T>,
        sortMapping: readonly SortingName[],
        defaultSort?: { sortingParam: SortingName; sortOrder: PaginationSortOrder },
        settingsKey?: string,
        defaultParams?: IPaginationStoreParams
    ) {
        return new PageStore<T>(
            (params) =>
                this.root.utility.$loading.around(loadingKey, () => serviceEndpoint(params)),
            sortMapping,
            defaultSort,
            settingsKey,
            defaultParams
        );
    }

    protected reportError(message: string, extra = {}) {
        if ([DeployEnvironment.Local, DeployEnvironment.Development].includes(DEPLOY_ENVIRONMENT)) {
            warn(message, extra);
            // No error message is captured in the dev environment. For similarity with production we
            // return a fake reference code instead.
            return "0000";
        } else {
            return Sentry.captureMessage(message, {
                level: Sentry.Severity.Error,
                extra,
            });
        }
    }

    protected reportResponseError(errorResponse: IApiResponse<any, any>) {
        const detail = (errorResponse.body as IGenericApiError)?.detail ?? "";

        return this.reportError(
            `Unexpected API response status: ${errorResponse.status} ${
                detail ? `detail: "${detail}"` : ""
            }`.trim(),
            { request: errorResponse }
        );
    }

    /**
     * Helper method to inflate a form by injecting the constructor arguments.
     *
     * This can be extended in the future with a dependency injection kernel.
     */
    protected form<T, E, U extends BaseForm<T, E>, V = any>(ctor: Class<U>, ...extraArgs: V[]): U {
        const { $loading } = this.root.utility;
        const { $session } = this.root.global;

        return makeObservable(
            new ctor(
                this.services,
                { $loading, $session },
                this.reportResponseError.bind(this),
                ...extraArgs
            )
        );
    }
}
