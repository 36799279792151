import { HttpStatus, IApiResponseMap } from "src/services/client";

import { Service } from "src/services/service";

import { IUserRead } from "src/services/services/user-service";
import { IPaginationStoreParams } from "src/store/_generic";

import { IPaginatedResponseV1 } from "src/types/paginated-response";

import { IIssueRead } from "./issue-service";

interface IIssueChangePayload {
    changedFields: {
        [field: string]: {
            oldValue: string;
            newValue: string;
        };
    };
    issue: IIssueRead;
}

interface IIssueMessagePayload {
    author: IUserRead;
    message: string;
    created: string;
}

export interface IIssueHistoryChangeEventRead {
    uuid: string;
    created: string;
    updated: string;
    payload: IIssueChangePayload | IIssueRead;
    type: "change";
}

export interface IIssueHistoryMessageEventRead {
    uuid: string;
    created: string;
    updated: string;
    payload: IIssueMessagePayload;
    type: "message";
}

export type IIssueHistoryEventRead = IIssueHistoryChangeEventRead | IIssueHistoryMessageEventRead;

export class IssueHistoryService extends Service {
    public async listForIssue(issueId: string, params?: IQueryParams) {
        const url = this.client.url(["api", "v2", "issues", issueId, "history"], { ...params });

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IIssueHistoryEventRead>;
                [HttpStatus.NotFound]: unknown;
            }>
        >(url);
    }

    public allForIssue = (issueId: string, extraParams?: IPaginationStoreParams) =>
        this.createListTraverser((params) => this.listForIssue(issueId, params))(extraParams);
}
