import { toIsoDateString } from "src/lib/date-formatters";
import { FormValue } from "src/models/form-value";
import { IPremiseRead, IAgentV1Read, IPremiseBase } from "src/services";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody, IPaginatedResponseV1 } from "src/types/paginated-response";

export interface ILeaseRead {
    uuid: string;
    publicName: string;
    publicNameWithDefault: string;
    expires: string | null;
    activates: string;
    number: string;
    premises: ExpandCollapse<IPremiseBase, "communities" | "building", "mainEntrance">;
    leasor: IAgentV1Read;
    leasees: IAgentV1Read[];
    created: string;
}

interface ILeaseCreate {
    uuid: undefined;
    premises: FormValue<IPremiseRead> | null;
    publicName: string;
    activates: Date | null;
    expires: Date | null;
}

type ILeaseUpdate = Omit<ILeaseCreate, "uuid"> & {
    uuid: string;
};

export type ILeaseUpsert = ILeaseCreate | ILeaseUpdate;

export interface IListParams extends IQueryParams {
    user?: string;
}

export class PremiseLeaseService extends Service {
    public allForPremise = (premiseId: string, extraParams?: IQueryParams) =>
        this.createListTraverser((params) =>
            this.listForPremise(premiseId, { ...extraParams, ...params })
        )();

    public allByUuids = (uuids: string[], extraParams?: IQueryParams) =>
        this.createListTraverser((params) =>
            this.listByUuids(uuids, { ...extraParams, ...params })
        )();

    public list(params?: IListParams) {
        params = { ...params, expand: ["premises", "premises.main_entrance", "leasor", "leasees"] };
        const url = this.client.url(["api", "v1", "leases"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<ILeaseRead>;
                [HttpStatus.NotFound]: unknown;
            }>
        >(url);
    }

    public listForUser(userUuid: string, params?: IListParams) {
        params = {
            ...params,
            user: userUuid,
            expand: ["premises", "premises.main_entrance", "leasor", "leasees"],
        };
        const url = this.client.url(["api", "v1", "leases"], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ILeaseRead>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public listForPremise(premiseUuid: string, params?: IListParams) {
        params = {
            ...params,
            premises: premiseUuid,
            expand: ["premises", "premises.main_entrance", "leasor", "leasees"],
        };
        const url = this.client.url(["api", "v1", "leases"], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ILeaseRead>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public listByUuids(uuids: string[], params?: IListParams) {
        params = {
            ...params,
            expand: ["premises", "premises.main_entrance", "leasor", "leasees"],
            uuid__in: uuids.join(","),
        };
        const url = this.client.url(["api", "v1", "leases"], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ILeaseRead>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async retrieve(uuid: string) {
        const params = { expand: ["premises", "premises.main_entrance", "leasor", "leasees"] };
        const url = this.client.url(["api", "v1", "leases", uuid], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: ILeaseRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public create(data: ILeaseCreate) {
        const params = { expand: ["premises", "premises.main_entrance", "leasor", "leasees"] };
        const url = this.client.url(["api", "v1", "leases"], params);
        const body = {
            ...data,
            // We actually want to use `toIsoDateString` here instead of the UTC
            // variant. When the user selects a date in the ui, e.g. 2000-01-01,
            // the date will be stored in UTC internally, in that case it would
            // be 1999-12-31T22:00:00Z. Doing `toUtcIsoDateString` to get the
            // date would return 1999-12-31. That's wrong and would make the
            // lease expire a day early. If we instead do `toIsoDateString` the
            // returned date would be 2000-01-01.
            activates: data.activates ? toIsoDateString(data.activates) : undefined,
            expires: data.expires ? toIsoDateString(data.expires) : undefined,
        };
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: ILeaseRead;
                [HttpStatus.BadRequest]: ValidationError<ILeaseCreate>;
            }>
        >(url, body);
    }

    public update(data: ILeaseUpdate) {
        const params = { expand: ["premises", "premises.main_entrance", "leasor", "leasees"] };
        const url = this.client.url(["api", "v1", "leases", data.uuid], params);
        const body = {
            ...data,
            // We actually want to use `toIsoDateString` here instead of the UTC
            // variant. When the user selects a date in the ui, e.g. 2000-01-01,
            // the date will be stored in UTC internally, in that case it would
            // be 1999-12-31T22:00:00Z. Doing `toUtcIsoDateString` to get the
            // date would return 1999-12-31. That's wrong and would make the
            // lease expire a day early. If we instead do `toIsoDateString` the
            // returned date would be 2000-01-01.
            activates: data.activates ? toIsoDateString(data.activates) : null,
            expires: data.expires ? toIsoDateString(data.expires) : null,
        };
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ILeaseRead;
                [HttpStatus.BadRequest]: ValidationError<ILeaseUpdate>;
            }>
        >(url, body);
    }

    public delete(uuid: string) {
        const url = this.client.url(["api", "v1", "leases", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: never;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }
}
