import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";

import { ILibraryEntryBase } from "./types/library-entry-base";

export interface ILibraryHyperlinkRead extends ILibraryEntryBase {
    url: string;
}

export interface ILibraryHyperlinkCreate
    extends Pick<ILibraryHyperlinkRead, "title" | "category" | "url" | "isPublic"> {
    tags?: string[];
}
export type ILibraryHyperlinkUpdate = Partial<ILibraryHyperlinkCreate>;

export class LibraryHyperlinkService extends Service {
    public create(data: ILibraryHyperlinkCreate) {
        const url = this.client.url(["api", "v1", "library-hyperlinks"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: ILibraryHyperlinkRead;
                [HttpStatus.BadRequest]: ValidationError<ILibraryHyperlinkCreate>;
                [HttpStatus.Forbidden]: IGenericApiError;
            }>
        >(url, data);
    }

    public update(uuid: string, data: ILibraryHyperlinkUpdate) {
        const url = this.client.url(["api", "v1", "library-hyperlinks", uuid]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ILibraryHyperlinkRead;
                [HttpStatus.BadRequest]: ValidationError<ILibraryHyperlinkUpdate>;
                [HttpStatus.Forbidden]: IGenericApiError;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, data);
    }

    public delete(uuid: string) {
        const url = this.client.url(["api", "v1", "library-hyperlinks", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
                [HttpStatus.Forbidden]: IGenericApiError;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }
}
