export enum ITagFixedSubType {
    Building = "building",
    Address = "mainentrance",
    Space = "premises",
    City = "city",
    ZipCode = "zip_code",
    Community = "community",
    Client = "client",
    RealEstate = "realestate",
    CommunityLease = "communitylease",
    Membership = "membershiplease",
    Area1 = "area1",
    Area2 = "area2",
    District = "district",
    User = "User",
}

export enum TagCategoriesType {
    Main = "main-filter",
    Visibility = "visibility",
}

export type ITagSubtype = ITagFixedSubType | string;

export interface ITag {
    id: string;
    root: string;
    name: string;
    type: string;
    client: number;
    subType: ITagFixedSubType;
    hasChildren: boolean;
}
