import { DeepPartial } from "utility-types";

import { filterUndefinedObjectValues } from "src/lib/object";
import { ICommunityRead, IGroupRoleRead } from "src/services";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

export interface IUserProfileRead {
    competences: string[];
    gender: string;
    phoneSwish: string;
    profileBio: string;
    profileImage: string | null;
    linkedinProfilelink: string;
    companyRole: string;
    professionalInfo: string;
    shareEmail: boolean;
    sharePhone: boolean;
    shareProfile: boolean;
    shareProfessionalProfile: boolean;
}

export type RevokablePermission =
    | "feed.add_publiccard"
    | "feed.change_publiccard"
    | "feed.add_cardcomment"
    | "feed.change_cardcomment";

export interface IUserAbsenceWrite {
    startDate: string;
    endDate: string | null;
}

export interface IUserAbsenceRead extends IUserAbsenceWrite {
    uuid: string;
    user: string;
}

export interface IUserBase {
    apartmentNumber: string | null;
    uuid: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    created: string;
    updated: string;
    profile: IUserProfileRead | null;
    leasePublicName: string | null;
    streetAddress: string | null;
    communities: string[] | ICommunityRead[];
    revokedPermissions: RevokablePermission[];
    roles: IGroupRoleRead[] | string[];
    absent: boolean;
    userAbsenceSet: IUserAbsenceRead[];
    premisesInternalNumber?: string;
}

// Note that we _always_ expand profile
export type IUserRead = Collapse<IUserBase, "roles" | "communities">;
export type IUserDetailsRead = ExpandCollapse<IUserBase, "communities", "roles">;

type IUserPatchBadRequestInner = ValidationError<Omit<IUserRead, "profile"> & IUserProfileRead>;

export type IUserPatchBadRequest = ValidationError<DropUndefinedType<IUserRead, "profile">>;

/**
 * Since PATCH to /api/v1/users/{uuid} takes the data differently from
 * what get GET translate a get-like value to a PATCH like result.
 */
export function reformatUserReadToUserPatch({ profile, ...data }: DeepPartial<IUserRead>) {
    return filterUndefinedObjectValues({
        ...data,
        ...profile,

        competences: profile?.competences?.map((c) => c?.trim()).filter(Boolean),
    });
}
/**
 * PATCH to /api/v1/users/{uuid} returns bad request "nagging" in a different
 * structure than what GET/PATCH returns so we translate the structure to
 * the GET-like structure
 */
export function reformatUserPatchBadRequest({
    competences,
    gender,
    phoneSwish,
    profileBio,
    profileImage,
    linkedinProfilelink,
    companyRole,
    professionalInfo,
    shareEmail,
    sharePhone,
    shareProfile,
    shareProfessionalProfile,
    ...rest
}: IUserPatchBadRequestInner): IUserPatchBadRequest {
    const profile: any = filterUndefinedObjectValues({
        competences,
        gender,
        phoneSwish,
        profileBio,
        profileImage,
        linkedinProfilelink,
        companyRole,
        professionalInfo,
        shareEmail,
        sharePhone,
        shareProfile,
        shareProfessionalProfile,
    });
    const result: IUserPatchBadRequest = rest;
    if (Object.keys(profile).length) {
        result.profile = profile;
    }
    return result;
}

export class UserService extends Service {
    private assertValidUserId(uuid: string) {
        if (uuid) {
            return;
        }
        throw new Error(`Got invalid user id '${uuid}'`);
    }

    public list<T extends IUserBase = IUserRead>(params?: IQueryParams) {
        let expand: IQueryParamValue[] = ["profile"];

        if (params?.expand) {
            expand = [...expand, params.expand].flat();
        }

        params = { ...params, expand };
        const url = this.client.url(["api", "v1", "users"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<T>;
            }>
        >(url);
    }

    /* istanbul ignore next */
    public retrieve<T extends IUserBase = IUserRead>(userId: string, params?: IQueryParams) {
        this.assertValidUserId(userId);
        const url = this.client.url(["api", "v1", "users", userId], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: T;
            }>
        >(url);
    }

    /* istanbul ignore next */
    public setRevokedUserPermissions(userId: string, permissions: Set<RevokablePermission>) {
        const url = this.client.url(["api", "v1", "users", userId]);
        const data = {
            revokedPermissions: Array.from(permissions),
        };
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IUserRead;
            }>
        >(url, data);
    }

    /* istanbul ignore next */
    public deleteUser(uuid: string) {
        this.assertValidUserId(uuid);
        const url = this.client.url(["api", "v1", "users", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
            }>
        >(url);
    }

    public deleteUsersWithoutActiveLease() {
        const url = this.client.url(["api", "v1", "users", "delete-all-without-lease"]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
            }>
        >(url);
    }

    public listUsersWithoutActiveLease() {
        return this.list({
            has_active_lease: false,
        });
    }

    public async patchUser(uuid: string, data: DeepPartial<IUserRead>) {
        this.assertValidUserId(uuid);
        const url = this.client.url(["api", "v1", "users", uuid]);
        const result = await this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IUserRead;
                [HttpStatus.BadRequest]: IUserPatchBadRequestInner;
            }>
        >(url, reformatUserReadToUserPatch(data));
        if (result.status === HttpStatus.BadRequest) {
            return {
                ...result,
                body: reformatUserPatchBadRequest(result.body),
            };
        }
        /* istanbul ignore next */
        return result;
    }

    public export(params?: Omit<IPaginationStoreParams, "page_size" | "page">) {
        const url = this.client.url(["api", "v1", "users", "export"], params, {
            applyTagFilter: true,
        });
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Accepted]: void;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, {});
    }
}
