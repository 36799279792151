import { HttpStatus, IApiResponseMap } from "src/services/client";

import { Service } from "src/services/service";

import { IUserRead } from "./user-service";

export interface IIssueMessageBase {
    uuid: string;
    message: string;
    issue: string;
    author: string | IUserRead;
    created: string;
    updated: string;
}

export type IIssueMessageRead = Collapse<IIssueMessageBase, "author">;

export interface IIssueMessageCreate {
    message: string;
}

export class IssueMessagesService extends Service {
    public async create(issueId: string, message: IIssueMessageCreate) {
        const url = this.client.url(["api", "v2", "issues", issueId, "messages"]);

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IIssueMessageRead;
                [HttpStatus.NotFound]: unknown;
                [HttpStatus.BadRequest]: ValidationError<IIssueMessageCreate>;
            }>
        >(url, message);
    }
}
