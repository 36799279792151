import { Service } from "src/services/service";

// Expandable: community
export interface ISurveyAnswerRead {
    uuid: string;
    question: string;
    text: string;
    options: string[];
    comment: string;
    community: string;
}

export interface ISurveyAnswerCreate {
    question: string;
    text: string;
    options: string[];
    comment: string;
}

// Permissions & validations:
//     * options-field can only be used with and is required with questions of type "single" or "multiple"
//     * only 1 option can be used for questions of type "single"
//     * text-field can only be used with and is required with questions of type "text"
//     * only allow comments for questions where allow_comment is true
//     * return 400 for users that already is in the Survey.completed lis

export class SurveyAnswerService extends Service {
    // GET /api/v1/survey-answers/[{uuid}]/
    // POST /api/v1/surveys/{uuid}/bulk-answer/ (takes array of create)
}
