import { observable, action, reaction } from "mobx";

import { Store } from "src/store";
import { BaseStore } from "src/store/base-store";
import { store } from "src/store/lib/store";

interface IDrawer {
    opened: boolean;
}

interface IDrawers {
    [key: string]: IDrawer;
}

@store
export class NavigationStore extends BaseStore {
    @observable
    private drawers: IDrawers = {};

    @observable
    public sideMenuStatus = false;

    constructor(rootStore: Store) {
        super(rootStore);
        reaction(
            () => rootStore.utility.$router.location,
            () => this.open(rootStore.utility.$router.location.pathname)
        );
    }

    public opened(key: string) {
        if (!this.drawers[key]) {
            return false;
        }
        return this.drawers[key].opened;
    }

    @action.bound
    public open(key: string) {
        this.initDrawer(key);
        this.drawers[key].opened = true;
    }

    @action.bound
    public toggle(key: string) {
        this.initDrawer(key);
        this.drawers[key].opened = !this.drawers[key].opened;
    }

    private initDrawer(key: string) {
        if (!this.drawers[key]) {
            this.drawers[key] = { opened: false };
        }
    }

    @action.bound
    public toggleSideMenu() {
        this.sideMenuStatus = !this.sideMenuStatus;
    }
}
