import { _allowStateReadsStart, _allowStateReadsEnd } from "mobx";

/**
 * Allows you to read a part of the observable state from outside reactive
 * context, without triggering warnings in mobx
 *
 * @param cb Callback that reads the state
 * @returns Return value from given callback
 */
export function readState<Value>(cb: () => Value): Value {
    const prevAllowStateReads = _allowStateReadsStart(true);
    const value = cb();
    _allowStateReadsEnd(prevAllowStateReads);

    return value;
}
