import { objectToFormData } from "src/lib/object-to-formdata";
import { FormValuefy } from "src/models/form-value/formvaluefy-type";

import { IIssueMessageBase } from "src/services";
import { HttpStatus, IGenericApiError, IApiResponseMap } from "src/services/client";
import { Service } from "src/services/service";
import { IIssueCategory } from "src/services/services";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

import { IUserRead } from "./user-service";

export enum IssueStatus {
    Pending = "pending",
    Confirmed = "confirmed",
    Done = "done",
    Rejected = "rejected",
}

type IIssueLastEvent =
    | Omit<IIssueBase, "tagExpression">
    | {
          issue: Omit<IIssueBase, "tagExpression">;
          changedFields: {
              [field in keyof IIssueBase]?: {
                  oldValue: IIssueBase[field];
                  newValue: IIssueBase[field];
              };
          };
      }
    | Omit<IIssueMessageBase, "updated">;

export interface IIssueBase {
    uuid: string;
    title: string;
    reference: string;
    status: IssueStatus;
    category: IIssueCategory;
    description: string;
    file1: string;
    file2: string;
    file3: string;
    location: string;
    miscDescription: string;
    reporter: string | IUserRead;
    handler: string | IUserRead;
    created: string;
    updated: string;
    deletedAt: string | null;
    estimatedCompletionDate: string;
    customFieldValue: string;
    tagExpression: string[];
    lastEvent: IIssueLastEvent | null;
    unreadActivitiesIssueMessage: number;
    unreadActivitiesIssue: number;
}

export type IIssueRead = Expand<IIssueBase, "handler" | "reporter">;
export type IIssueListRead = Collapse<IIssueBase, "handler" | "reporter">;

export interface IIssueCreate {
    title: string;
    category?: string;
    description: string;
    community: string;
    file1?: File;
    file2?: File;
    file3?: File;
    location: string;
    miscDescription: string;
    customFieldValue?: string;
    status?: IssueStatus;
    handler?: string;
    reporter: string;
    estimatedCompletionDate?: string;
    lease?: string;
    membershipLease?: string;
}

export interface IIssueUpdate
    extends Omit<IIssueCreate, "file1" | "file2" | "file3" | "community" | "lease"> {
    uuid: string;
    file1?: string | File | null;
    file2?: string | File | null;
    file3?: string | File | null;
}

export type IIssueUpsert = IIssueCreate | IIssueUpdate;

export class IssueService extends Service {
    public async list(params?: IQueryParams) {
        params = { expand: ["handler", "reporter", "category"], ...params };
        const url = this.client.url(["api", "v2", "issues"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IIssueListRead>;
            }>
        >(url);
    }

    public delete(uuid: string) {
        const url = this.client.url(["api", "v2", "issues", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: undefined;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public post(issue: FormValuefy<IIssueCreate>) {
        const params = { expand: ["handler", "reporter", "category"] };
        const url = this.client.url(["api", "v2", "issues"], params);

        const nrFiles = 3;
        const nextIssue = { ...issue };
        const body = objectToFormData(nextIssue);

        for (let i = 1; i <= nrFiles; i++) {
            if (typeof body.get(`file_${i}`) === "string" && body.get(`file_${i}`) !== "null") {
                body.delete(`file_${i}`);
            }
        }

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IIssueRead;
                [HttpStatus.BadRequest]: ValidationError<IIssueCreate>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, body);
    }

    public async patch(uuid: string, issue: FormValuefy<IIssueUpdate>) {
        const params = { expand: ["handler", "reporter", "category"] };
        const url = this.client.url(["api", "v2", "issues", uuid], params);

        const nrFiles = 3;
        const nextIssue: any = { ...issue }; // any because files are sent with _1.
        const mainBody = { ...nextIssue };

        delete mainBody.file_1;
        delete mainBody.file_2;
        delete mainBody.file_3;

        const fileBody = objectToFormData({
            file_1: nextIssue.file_1,
            file_2: nextIssue.file_2,
            file_3: nextIssue.file_3,
        });
        for (let i = 1; i <= nrFiles; i++) {
            if (
                typeof fileBody.get(`file_${i}`) === "string" &&
                fileBody.get(`file_${i}`) !== "null"
            ) {
                fileBody.delete(`file_${i}`);
            }
        }

        const response = await this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IIssueRead;
                [HttpStatus.BadRequest]: ValidationError<IIssueUpdate>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, fileBody);

        if (response.status !== HttpStatus.Ok) {
            return response;
        }

        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IIssueRead;
                [HttpStatus.BadRequest]: ValidationError<IIssueUpdate>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, mainBody);
    }

    public async retrieve(uuid: string, params?: IQueryParams) {
        params = { expand: ["handler", "reporter", "category"], ...params };
        const url = this.client.url(["api", "v2", "issues", uuid], params);

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IIssueRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public export(params?: Omit<IPaginationStoreParams, "page_size" | "page">) {
        const url = this.client.url(["api", "v2", "issues", "export"], params, {
            applyTagFilter: true,
        });
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Accepted]: void;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, {});
    }

    public readActivities(uuid: UUID) {
        const url = this.client.url(["api", "v2", "issues", uuid, "read-activities"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: void;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, {});
    }
}
