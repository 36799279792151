import { range } from "src/lib/range";
import { Client, IApiResponse, IApiResponseMap, HttpStatus } from "src/services/client";
import { IPaginationStoreParams } from "src/store/_generic/pagination-store";
import { IPaginationStoreCursorParams } from "src/store/_generic/pagination-store-cursor";
import {
    IPaginatedResponseV1,
    IPaginatedCursorResponse,
    IPaginatedObject,
} from "src/types/paginated-response";

const isStatusOk = <T>(
    response: IApiResponseMap<{ [HttpStatus.Ok]: T }>
): response is IApiResponse<T, HttpStatus.Ok> => response.status === HttpStatus.Ok;

export class Service {
    constructor(protected readonly client: Client) {}

    protected async traversePaginatedObject<T extends { [key: string]: any }>(
        fn: (
            params: IPaginationStoreParams
        ) => Promise<IApiResponseMap<{ [HttpStatus.Ok]: IPaginatedObject<T> }>>
    ): Promise<Partial<T>> {
        const pageSize = 100;
        const fetchPage = (page: number) => fn({ page_size: pageSize, page });
        const first = await fetchPage(1);
        if (first.status !== HttpStatus.Ok) {
            return {};
        }

        const pagesLeft = Math.floor(first.body.count / pageSize);
        // Generate each page request instead of using the `next` param to be able
        // to concurrently fetch all consecutive pages at the same time.
        const consecutiveResponses = await Promise.all(range(pagesLeft, 2).map(fetchPage));
        return [first, ...consecutiveResponses]
            .filter(isStatusOk)
            .map((response) => response.body.results)
            .reduce<Partial<T>>((acc, response) => Object.assign({}, acc, response), {});
    }

    protected createListTraverser<T>(
        fn: (
            params: IPaginationStoreParams
        ) => Promise<IApiResponseMap<{ [HttpStatus.Ok]: IPaginatedResponseV1<T> }>>
    ): (params?: IQueryParams) => Promise<T[]>;
    protected createListTraverser<T, Expandables extends ExtractNestedKeys<T>>(
        fn: <Expanded extends Expandables | never>(
            params: { expand?: Expanded[] } & IPaginationStoreParams
        ) => Promise<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ExpandCollapse<T, Expandables, Expanded>>;
            }>
        >
    ): <Expanded extends Expandables = never>(
        params?: { expand?: Expanded[] } & IQueryParams
    ) => Promise<ExpandCollapse<T, Expandables, Expanded>[]>;
    protected createListTraverser<T>(
        fn: (
            params: IPaginationStoreParams
        ) => Promise<IApiResponseMap<{ [HttpStatus.Ok]: IPaginatedResponseV1<T> }>>
    ) {
        return async (params?: IQueryParams) => {
            const pageSize = 100;
            const fetchPage = (page: number) => fn({ ...params, page_size: pageSize, page });
            const first = await fetchPage(1);

            if (first.status !== HttpStatus.Ok) {
                return [];
            }

            const pagesLeft = Math.floor(first.body.count / pageSize);

            // Generate each page request instead of using the `next` param to be able
            // to concurrently fetch all consecutive pages at the same time.
            const consecutiveResponses = await Promise.all(range(pagesLeft, 2).map(fetchPage));
            return [first, ...consecutiveResponses].reduce((acc: T[], response) => {
                if (response.status !== HttpStatus.Ok) {
                    return acc;
                }
                return acc.concat(response.body.results);
            }, [] as T[]);
        };
    }

    protected createCursorListTraverser<T>(
        fn: (
            params: IPaginationStoreCursorParams
        ) => Promise<
            | IApiResponse<IPaginatedCursorResponse<T>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >
    ) {
        return async (params?: IQueryParams) => {
            let retVal: T[] = [];
            const first = await fn({ ...params });
            if (first.status !== HttpStatus.Ok) {
                return [];
            }
            retVal = retVal.concat(first.body.results);

            let hasNextPage = first.body.next;
            while (hasNextPage) {
                const resp = await fn({ cursor: hasNextPage });
                if (resp.status === HttpStatus.Ok) {
                    retVal = retVal.concat(resp.body.results);
                    hasNextPage = resp.body.next;
                } else {
                    throw new Error(`Error when fetching page with cursor ${hasNextPage}`);
                }
            }

            return retVal;
        };
    }
}
