import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";

export enum OnboardingStep {
    NotStarted = "not_started",
    StepOne = "step_one",
    StepTwo = "step_two",
    StepThree = "step_three",
    Completed = "completed",
}

export interface IApplicationAddressWritable {
    city: string;
    latitude: number;
    longitude: number;
    zipCode: string;
    streetAddress: string;
}

export interface IApplicationApartmentWritable {
    address: IApplicationAddressWritable;
    building: null;
    email?: string;
    number: string;
    rooms: number;
    selected?: boolean;
    size: number;
}

export interface IApplicationWritable {
    apartments?: IApplicationApartmentWritable[];
    email: string;
    housingCoopTitle: string;
    organizationNumber: string;
    phone: string;
    status: OnboardingStep;
    supportEmail: string;
}

interface IApplicationReadable extends IApplicationWritable {
    icon: string | null;
    logo: string | null;
}

export type IApplicationError = ValidationError<IApplicationWritable> & {
    administratorEmail: string[];
};

export class OnboardingService extends Service {
    public async get() {
        const url = this.client.url(["manager", "onboarding"]);
        const response = await this.client.get<IApiResponse<IApplicationReadable, number>>(url, {
            throwOnNon2xx: true,
        });
        return this.hydrateApplicationReadable(response);
    }

    public async patch(application: Partial<IApplicationWritable>) {
        const url = this.client.url(["manager", "onboarding"]);
        const response = await this.client.patch<IApiResponse<IApplicationReadable, number>>(
            url,
            application,
            {
                throwOnNon2xx: true,
            }
        );
        return this.hydrateApplicationReadable(response);
    }

    public async post() {
        const url = this.client.url(["manager", "onboarding"]);
        const response = await this.client.post<IApiResponse<IApplicationReadable, number>>(
            url,
            {},
            {
                throwOnNon2xx: true,
            }
        );
        return this.hydrateApplicationReadable(response);
    }

    private hydrateApplicationReadable(response: IApiResponse<IApplicationReadable, number>) {
        return this.client.hydrateBody(response, (body: IApplicationReadable) => {
            const nextBody: Partial<IApplicationReadable> = { ...body };
            // Clear logo and icon. Backend won't accept them as null and they
            // should not be able to be set in the onboarding
            delete nextBody.icon;
            delete nextBody.logo;

            if (nextBody.apartments) {
                const nextApartments = nextBody.apartments.slice(0);
                nextApartments.sort((a, b) => {
                    const aAddress = a.address.streetAddress;
                    const bAddress = b.address.streetAddress;
                    return aAddress.localeCompare(bAddress, "sv", { sensitivity: "accent" });
                });
                nextBody.apartments = nextApartments;
            }

            return nextBody;
        });
    }
}
