// this is a temporary file for translations of errors messages from core
// it should be removed in favor of a more robust system later.
import { t } from "@lingui/macro";

const translations = () => ({
    "A User with this Email address already exists.": t`global.validation.email-already-exists`,
    "Enter a valid email address.": t`global.validation.email-invalid`,
    "Invalid personal identity number": t`global.validation.personal-number-invalid`,
    "This password is too short. It must contain at least 8 characters.": t`global.validation.password-too-short`,
    "This password is too common.": t`global.validation.password-too-common`,
    "This password is entirely numeric.": t`global.validation.password-only-numeric`,
    "Det här fältet får inte vara null.": t`global.validation.obligatory-field`,
    "Det här fältet får inte vara blankt.": t`global.validation.obligatory-field`,
    "The fields address, number must make a unique set.": t`global.validation.premise-number-already-exists`,
    "Must be a valid zip code": t`global.validation.postal-code-invalid`,
});

export function translate(key: string) {
    const translation = translations()[key];

    return translation ?? key;
}
