import { observable, action, runInAction } from "mobx";

import { exhaustiveCheck } from "src/lib/exhaustive-check";
import { loads } from "src/lib/loading";
import { IOverviewSuccessResponse } from "src/services";
import { HttpStatus } from "src/services/client";
import { ITag, ITagFixedSubType, TagCategoriesType } from "src/services/services/types/tag";
import { BaseStore } from "src/store/base-store";

import { store } from "src/store/lib/store";

type ITenantOverview = IOverviewSuccessResponse;
export type ITenantOverviewKeys = keyof ITenantOverview;

@store
export class TenantStore extends BaseStore {
    @observable
    public filterId?: string;

    @observable
    public filterTags?: ITag[];

    @observable
    private tenantOverview?: ITenantOverview;

    @observable
    private tenantOverviewCacheKey?: string = "always-miss-first-try";

    public hasFilter() {
        return Boolean(this.filterId);
    }

    @action.bound
    public async setFilterFromTags(tags: ITag[]) {
        if (tags.length) {
            const response = await this.services.tagFilter.create(tags);
            if (response.status === HttpStatus.Created) {
                this.setFilter(response.body.uuid, tags);
            } else {
                this.reportResponseError(response);
            }
        } else {
            this.clearFilter();
        }
    }

    @action.bound
    public setFilter(filterId: string, tags: ITag[]) {
        this.filterId = filterId;
        this.filterTags = tags;
    }

    @action.bound
    public clearFilter() {
        this.filterId = undefined;
        this.filterTags = undefined;
    }

    @action.bound
    @loads("tenant-overview/get")
    public async reloadTenantOverview(cacheKey?: string) {
        if (this.tenantOverviewCacheKey === cacheKey) {
            return;
        }

        this.tenantOverview = undefined;
        this.tenantOverviewCacheKey = cacheKey;

        const { body } = await this.services.tenantOverview.retrieve();
        runInAction(() => {
            this.tenantOverview = body;
        });
    }

    public getTenantOverviewStat(key: ITenantOverviewKeys) {
        if (this.tenantOverview) {
            return this.tenantOverview[key];
        }
        return undefined;
    }

    public getFilterCategories(type: TagCategoriesType): string[] {
        switch (type) {
            case TagCategoriesType.Visibility:
                return this.getVisibilityCategories();
            case TagCategoriesType.Main:
                return this.getMainFilterCategories();
            default:
                exhaustiveCheck(type);
                throw new Error(`Unexpected TagCategoriesType ${type}`);
        }
    }

    public getMainFilterCategories() {
        const hasWorkAccess = this.root.global.$session.isWorkClientType();
        const categories = this.getVisibilityCategories();

        if (hasWorkAccess) {
            categories.push(ITagFixedSubType.Membership);
        }

        return categories;
    }

    public getVisibilityCategories() {
        return [
            ITagFixedSubType.Address,
            ITagFixedSubType.Building,
            ITagFixedSubType.City,
            ITagFixedSubType.Community,
            ITagFixedSubType.RealEstate,
            ITagFixedSubType.Space,
            ITagFixedSubType.Area1,
            ITagFixedSubType.Area2,
            ITagFixedSubType.District,
        ];
    }
}
