import { objectToFormData } from "src/lib/object-to-formdata";
import { IApiResponseMap, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginationStoreParams } from "src/store/_generic/pagination-store";
import { IPaginatedBody } from "src/types/paginated-response";

export interface IOrganizations {
    uuid: string;
    name: string;
    companyName: string;
    organizationNumber: string;
    address: string;
    zipCode: string;
    city: string;
    phone: string;
    email: string;
    profileImage?: string | null;
    isWhitelabelOrganization: boolean;
    created: string;
}

export class OrganizationsService extends Service {
    public list(params?: IPaginationStoreParams) {
        const url = this.client.url(["api/v1", "organizations"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<IOrganizations>;
            }>
        >(url);
    }

    public retrieve(organizationID: string) {
        const url = this.client.url(
            ["api/v1", "organizations", organizationID],
            {},
            {
                applyTagFilter: true,
            }
        );

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IOrganizations;
            }>
        >(url);
    }

    public update(resource: Partial<IOrganizations>, organizationID: string) {
        const url = this.client.url(["api/v1", "organizations", organizationID]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IOrganizations;
                [HttpStatus.BadRequest]: ValidationError<IOrganizations>;
            }>
        >(url, objectToFormData(resource));
    }
}
