import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";

export interface IClientAssetsConfiguration {
    icon: string | null;
    background: string | null;
    logo: string | null;
}

export type ClientConfigurationModuleType = "feed" | "tags" | "library" | "issues" | "leases";

export type ClientConfigurationModuleSettings = {
    feed: {
        allowUserPosts: boolean;
        allowUserComments: boolean;
    };
    tags: { subTypeLogicalRelationships?: string[][] };
    library: { version: 1 | 2 };
    issues: { deletionDaysDuration?: number; defaultIssueHandler?: string; version: "v2" };
    leases: { deleteUsersWithoutActiveLease: boolean; nrDaysBeforeLeaseExpiresToNotify?: number };
};

export interface IClientConfigurationModule<TType extends ClientConfigurationModuleType> {
    uuid: string;
    apiUrl: string | null;
    isActive: boolean;
    moduleType: TType;
    settings: ClientConfigurationModuleSettings[TType];
}

export interface IClientConfiguration {
    assets: IClientAssetsConfiguration;
    modules: { [type in ClientConfigurationModuleType]: IClientConfigurationModule<type> };
}

export class ClientConfigurationService extends Service {
    public async retrieve(params?: IQueryParams) {
        const url = this.client.url(["api", "v1", "client-configuration"], params);

        return this.client.get<IApiResponse<IClientConfiguration, HttpStatus.Ok>>(url);
    }
}
