import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

export interface IResetPasswordError {
    email: string[];
}

interface IUserReadable {
    id: number;
    boardPositions: number[];
    email: string;
    endDate: null | string;
    firstName: string;
    lastName: string;
    phone: string;
    profileImage: string;
    role: {
        id: number;
        name: string;
    };
    space: {
        id: number;
        address: {
            id: number;
            city: string;
            streetAddress: string;
            zipCode: string;
        };
        internalNumber: string;
        number: string;
    };
    startDate: null | string;
    buildingAdministrator: null | { id: number; buildingSet: number[] };
}

export interface IUserReadablePaginated {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    profileImage: string;
    role: string;
    spaceNumber: string;
}

interface IUserWritable {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    boardPositions: number[];
}

export class UserService extends Service {
    public getPaginated(url?: string, params?: IPaginationStoreParams) {
        if (url) {
            return this.client.get<IApiResponse<IPaginatedBody<IUserReadablePaginated>, number>>(
                url,
                {
                    throwOnNon2xx: true,
                }
            );
        }

        const nextUrl = this.client.url(
            ["manager", "user"],
            { page: (params && params.page) || 1 },
            { applyWlFilter: true }
        );
        return this.client.get<IApiResponse<IPaginatedBody<IUserReadablePaginated>, number>>(
            nextUrl,
            {
                throwOnNon2xx: true,
            }
        );
    }

    public find(id: number) {
        const url = this.client.url(["manager", "user", id]);
        return this.client.get<IApiResponse<IUserReadable, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public patch(id: number, body: Partial<IUserWritable>) {
        const url = this.client.url(["manager", "user", id]);
        return this.client.patch<IApiResponse<undefined, number>>(url, body, {
            throwOnNon2xx: true,
        });
    }

    public delete(id: number) {
        const url = this.client.url(["manager", "user", id]);
        return this.client.delete<IApiResponse<undefined, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public resetPassword(email: string) {
        const url = this.client.url(["reset-password"]);
        return this.client.post<IApiResponse<undefined, number>>(
            url,
            { email },
            {
                throwOnNon2xx: true,
            }
        );
    }
}
