import { FormValue } from "src/models/form-value";
import { IMainEntranceRead } from "src/services";
import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

import { ICommunityRead } from "./community-service";

export enum PremiseType {
    Office = "office",
    Residential = "residential",
}

export interface IPremiseBase {
    uuid: string;
    internalNumber: string;
    type: PremiseType;
    lfrApartmentNumber: string;
    rooms: number | null;
    size: number | null;
    floor?: number;
    hasStorage: boolean;
    hasParking: boolean;
    created: string;
    updated: string;
    mainEntrance: string | IMainEntranceRead;
    token: string;
    communities: string[] | ICommunityRead[];
    /** @deprecated */
    number: string;
}

export type IPremiseDetailsRead = Expand<IPremiseBase, "building" | "mainEntrance" | "communities">;

// Note: We don't expand communities in the list view due to performance issues
export type IPremiseRead = ExpandCollapse<IPremiseBase, "communities", "mainEntrance" | "building">;

export interface IPremiseCreate {
    internalNumber?: string;
    lfrApartmentNumber?: string;
    type?: PremiseType;
    rooms?: string;
    size?: string;
    floor?: string;
    hasStorage?: boolean;
    hasParking?: boolean;
    mainEntrance?: FormValue<IMainEntranceRead>;
}

interface IPremiseCreateValidationError extends ValidationError<IPremiseCreate> {
    nonFieldErrors?: string[];
}

export type IPremiseUpdate = IPremiseCreate;

interface IPremiseUpdateValidationError extends ValidationError<IPremiseUpdate> {
    nonFieldErrors?: string[];
}

export class PremiseService extends Service {
    public all = this.createListTraverser<IPremiseRead>((params) => this.list({ ...params }));

    public retrieve(id: string) {
        const url = this.client.url(["api", "v1", "premises", id], {
            expand: ["main_entrance", "communities", "main_entrance.building"],
        });
        return this.client.get<
            | IApiResponse<IPremiseDetailsRead, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public list(params?: IQueryParams) {
        params = { expand: ["main_entrance", "building"], ...params };
        const url = this.client.url(["api", "v1", "premises"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            | IApiResponse<IPaginatedResponseV1<IPremiseRead>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public create(data: IPremiseCreate) {
        const url = this.client.url(["api", "v1", "premises"], {
            expand: ["main_entrance", "communities", "building"],
        });
        return this.client.post<
            | IApiResponse<IPremiseDetailsRead, HttpStatus.Created>
            | IApiResponse<IPremiseCreateValidationError, HttpStatus.BadRequest>
        >(url, data);
    }

    public update(id: string, data: IPremiseUpdate) {
        const url = this.client.url(["api", "v1", "premises", id], {
            expand: ["main_entrance", "communities", "building"],
        });
        return this.client.patch<
            | IApiResponse<IPremiseDetailsRead, HttpStatus.Ok>
            | IApiResponse<IPremiseUpdateValidationError, HttpStatus.BadRequest>
        >(url, data);
    }

    public delete(id: string) {
        const url = this.client.url(["api", "v1", "premises", id]);
        return this.client.delete<
            IApiResponse<unknown, HttpStatus.NoContent> | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }
}
