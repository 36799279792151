import { spy } from "mobx";

import { Store } from "src/store";

// tslint:disable-next-line: no-any
declare let global: any;
export function exposePrintStore(store: Store) {
    global.s = () => {
        return JSON.parse(JSON.stringify(store));
    };
    global.s2 = () => {
        return store;
    };
    global.spy = () => {
        // tslint:disable-next-line: no-any
        function getActionName(event: any) {
            if (typeof event.object === "undefined") {
                return event.name;
            }
            return `${event.object.constructor.name}.${event.name}`;
        }
        let lastStateHash = "";
        spy((event) => {
            if (event.type === "action") {
                const jsonStore = global.s();
                const nextStateHash = JSON.stringify(jsonStore);
                if (lastStateHash !== nextStateHash) {
                    /* eslint-disable no-console */
                    console.groupCollapsed(getActionName(event));
                    Object.keys(jsonStore).forEach((key) => {
                        console.log(key, jsonStore[key]);
                    });
                    console.groupEnd();
                    /* eslint-enable no-console */
                    lastStateHash = nextStateHash;
                }
            }
        });
    };
}
