import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface IContactCategoryRead {
    uuid: string;
    position: number;
    title: string;
    parent: string;
    children: string[];
}

export class ContactCategoryService extends Service {
    public list = (params?: IQueryParams) => {
        const url = this.client.url(["api", "v1", "contact-categories"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IContactCategoryRead>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    };

    public all = this.createListTraverser(this.list);
}
