import { objectToFormData } from "src/lib/object-to-formdata";
import { IApiResponseMap, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";

import { IGroupRoleRead } from "./group-role-service";

export interface IMe {
    appAgreement: boolean;
    bank: string;
    bankContact: string;
    baseUrl: string;
    canComment: boolean;
    competences: string[];
    company: string;
    companyRole: string;
    contactAddress: string;
    contactCity: string;
    contactPostcode: string;
    created: string;
    email: string;
    firstName: string;
    gender: string;
    isBoardMember: boolean;
    language: string;
    lastName: string;
    linkedinProfilelink: string;
    permissions: string[];
    personalIdentityNumber: string;
    phone: string;
    phoneSwish: string;
    professionalInfo: string;
    profileImage: string | null;
    profileBio: string;
    roles: IGroupRoleRead[];
    shareEmail: boolean;
    sharePhone: boolean;
    shareProfessionalProfile: boolean;
    shareProfile: boolean;
    timezone: string;
    updated: string;
    uuid: string;
}

export type IMeRead = IMe;
export interface IMeUpdate
    extends Partial<Pick<IMeRead, "firstName" | "lastName" | "email" | "phone">> {
    profileImage?: File | null;
}

export class MeService extends Service {
    public async getMe() {
        const path = this.client.url(["api", "v1", "auth", "me"], {
            expand: ["roles"],
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IMeRead;
            }>
        >(path);
    }

    public async updateMe({ firstName, lastName, profileImage, email, phone }: IMeUpdate) {
        const url = this.client.url(["api", "v1", "auth", "me"], {
            expand: ["roles"],
        });
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IMeRead;
                [HttpStatus.BadRequest]: ValidationError<IMeUpdate>;
            }>
        >(
            url,
            objectToFormData({
                firstName,
                lastName,
                profileImage: profileImage === null ? "" : profileImage,
                email,
                phone,
            })
        );
    }

    public async updateMyPassword(newPassword: string, oldPassword: string) {
        const url = this.client.url(["api", "v1", "auth", "change-password"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: void;
                [HttpStatus.BadRequest]: any;
            }>
        >(url, { newPassword, oldPassword });
    }
}
