import Debug from "debug";
import { observable, action, computed } from "mobx";

import { storeItemToJSON } from "src/lib/store-item-to-json";
import { store } from "src/store/lib/store";

const debug = Debug("tmpl:store/loading");
const warn = Debug("tmpl:warn:store/loading");

export type ILoadingStateKeys =
    | symbol
    | "booking/post-booking"
    | "booking/get-bookings-for-resource"
    | "booking/get-calendar-days"
    | "booking/update-status"
    | "building/get-building"
    | "building/get-buildings"
    | "building/delete-building"
    | "building/post-building"
    | "contact/delete-contact"
    | "contact/delete-contact-person"
    | "contact/get-contact"
    | "contact/get-contact-people"
    | "contact/get-contacts"
    | "contact/patch-contact"
    | "contact/patch-contact-file"
    | "contact/post-contact"
    | "contact-category/get-contact-categories"
    | "feed/add-card"
    | "feed/get-agents"
    | "feed/delete-card"
    | "feed/delete-comment"
    | "feed/get-card"
    | "feed/get-comments"
    | "feed/get-card-comments"
    | "feed/get-card-types"
    | "feed/get-feed"
    | "feed/patch-card"
    | "feed/report-card"
    | "housing-coop/get-summary"
    | "main-entrance/post-main-entrance"
    | "main-entrance/delete-main-entrance"
    | "onboarding/find-housing-coop"
    | "onboarding/get-application"
    | "onboarding/get-invitation-pdf"
    | "onboarding/patch-application"
    | "onboarding/patch-application-force"
    | "onboarding/patch-application-with-status"
    | "onboarding/submit-application"
    | "registration/verify-confirmation"
    | "registration/started"
    | "report/get-issue-categories"
    | "report/get-issue-category"
    | "report/get-issue-statuses"
    | "report/get-issues"
    | "report/patch-issue"
    | "report/delete-issue"
    | "reset-password/send"
    | "resource/get-resource"
    | "resource/get-resource-for-booking"
    | "resource/get-resources"
    | "real-estate/get-real-estates"
    | "real-estate/get-real-estate"
    | "real-estate/get-buildings"
    | "real-estate/patch-real-estate"
    | "real-estate/post-real-estate"
    | "real-estate/delete-real-estate"
    | "tenant-filter/get"
    | "tenant-filter/search"
    | "tenant-filter/create"
    | "tenant-overview/get"
    | "session/authenticating"
    | "session/authenticating-with-token"
    | "session/check-authentication"
    | "session/load-base-url"
    | "sharing/delete-resource"
    | "sharing/get-answered-bookings"
    | "sharing/get-resource"
    | "sharing/get-resources"
    | "sharing/get-unanswered-bookings"
    | "sharing/patch-booking-status"
    | "sharing/patch-resource"
    | "sharing/post-resource"
    | "sharing/get-rental"
    | "sharing/get-rentals"
    | "sharing/patch-rental"
    | "sharing/get-subscription"
    | "space/download-pdf"
    | "space/get-addresses"
    | "space/get-buildings"
    | "space/get-space"
    | "space/get-spaces"
    | "space/get-addresses-for-building"
    | "space/save-space"
    | "space-token/generate"
    | "user/delete-user"
    | "user/download-pdf"
    | "user/get-buildings"
    | "user/get-spaces-for-building"
    | "user/get-spaces"
    | "user/get-user"
    | "user/get-users"
    | "user/invite-user"
    | "user/patch-user"
    | "user/patch-profile"
    | "library/get-library"
    | "library/get-file"
    | "library/get-file-categories"
    | "library/get-spaces"
    | "library/get-categories"
    | "library/get-root-categories"
    | "library/patch-file"
    | "library/delete-file"
    | "library/save-category-changes"
    | "library/post-category"
    | "library/post-file"
    | "customer/get-billing-address"
    | "customer/get-membership-leases"
    | "customer/get-memberships"
    | "customer/get-organization"
    | "customer/get-organizations"
    | "customer/get-subscriptions"
    | "customer/patch-organization"
    | "customer/get-community-leases"
    | "customer/get-user-addresses"
    | "customer/get-user-permissions"
    | "customer/update-user-permissions"
    | "customer/get-invoicing-method"
    | "customer/patch-billing-address"
    | "customer/patch-invoicing-method"
    | "leases/patch-community-lease"
    | "leases/cancel-subscription"
    | "leases/delete-membership-lease"
    | "leases/post-membership-invitation"
    | "leases/delete-membership-invitation"
    | "leases/get-folios"
    | "leases/get-folio-lines"
    | "leases/get-folio-line"
    | "leases/get-invoices"
    | "leases/get-invoicing-method"
    | "leases/get-user-addresses"
    | "leases/get-national-products"
    | "leases/post-add-national-product"
    | "leases/get-order-lines"
    | "leases/save-lease"
    | "oembed/loading-metadata";

interface ILoadingStateItem {
    generic: number;
    objects: (string | number)[];
}

type ILoadingStates = { [key in ILoadingStateKeys]?: ILoadingStateItem };

@store
export class LoadingStore {
    @observable
    private states: ILoadingStates = {};

    @computed
    public get activeLoaders() {
        const states = this.states;
        return Reflect.ownKeys(states).filter((key) => {
            const value = states[key];
            return value && (value.generic || (value.objects && value.objects.length));
        });
    }

    // If generic is true or objects contains data get will return true
    // If generic is false and objects is empty it will return false
    public get(key: keyof ILoadingStates, value?: string | number) {
        const state = this.states[key];

        if (typeof value !== "undefined") {
            if (state && state.objects) {
                return state.objects.indexOf(value) !== -1;
            }
            return false;
        }

        return Boolean(state && (state.generic > 0 || state.objects.length));
    }

    public any(keys: ILoadingStateKeys[]) {
        return keys.some((key) => this.get(key));
    }

    public all(keys: ILoadingStateKeys[]) {
        return keys.filter((key) => this.get(key)).length === keys.length;
    }

    public none(keys: ILoadingStateKeys[]) {
        return keys.filter((key) => this.get(key)).length === 0;
    }

    @action.bound
    public start(key: ILoadingStateKeys, value?: string | number) {
        const state: ILoadingStateItem = this.states[key] || this.defaultStateItem();

        if (typeof value === "undefined") {
            state.generic++;
        } else if (state.objects.indexOf(value) === -1) {
            state.objects.push(value);
        }

        this.states = { ...this.states, [key]: state };
        debug(
            `started loading ${String(key)}${
                typeof value !== "undefined" ? ` with value ${value}` : ""
            }`
        );
    }

    @action.bound
    public stop(key: ILoadingStateKeys, value?: string | number) {
        const state: ILoadingStateItem | undefined = this.states[key];

        if (!state) {
            this.logWarn(key, value);
            return;
        }

        if (typeof value === "undefined") {
            if (state.generic === 0) {
                this.logWarn(key);
            }

            state.generic--;
        } else {
            const index = state.objects.indexOf(value);

            if (index !== -1) {
                state.objects.splice(index, 1);
            } else {
                this.logWarn(key, value);
            }
        }

        this.states = { ...this.states, [key]: state };

        debug(
            `stopped loading ${String(key)}${
                typeof value !== "undefined" ? ` with value ${value}` : ""
            }`
        );
    }

    @action.bound
    public async around<T>(key: ILoadingStateKeys, fn: () => Promise<T>): Promise<T> {
        try {
            this.start(key);
            const result = await fn();
            return result;
        } finally {
            this.stop(key);
        }
    }

    @action.bound
    public stopAll() {
        this.states = {};
    }

    private defaultStateItem(): ILoadingStateItem {
        return { generic: 0, objects: [] };
    }

    private logWarn(key: ILoadingStateKeys, value?: string | number) {
        let message;

        if (value === undefined) {
            message = `Attempted to stop '${String(key)}' which is not currently loading.`;
        } else {
            message = `Attempted to stop '${String(
                key
            )}: ${value}' which is not currently loading.`;
        }

        warn(message);
    }

    public toJSON() {
        return storeItemToJSON(this);
    }
}
export default LoadingStore;
