import { AbstractFormValue } from "src/models/form-value/abstract-form-value";

function isObject(subject: any) {
    return typeof subject === "object" && !Array.isArray(subject) && subject !== null;
}

function convert(obj: ObjectOf<any> | ObjectOf<any>[], parentKey?: string, carry?: FormData) {
    const formData = carry || new FormData();

    Object.keys(obj).forEach((key) => {
        if (obj[key] !== undefined) {
            let propName = parentKey || key;

            if (parentKey && isObject(obj)) {
                propName = parentKey + "[" + key + "]";
            }

            if (obj[key] instanceof AbstractFormValue) {
                formData.append(propName, obj[key].value);
            } else if (obj[key] instanceof File) {
                formData.append(propName, obj[key]);
            } else if (Array.isArray(obj[key]) || isObject(obj[key])) {
                convert(obj[key], propName, formData);
            } else if (typeof obj[key] === "boolean") {
                formData.append(propName, obj[key] ? "1" : "0");
            } else {
                formData.append(propName, obj[key]);
            }
        }
    });

    return formData;
}

export const objectToFormData = (obj: ObjectOf<any> | ObjectOf<any>[]) => {
    return convert(obj);
};
