import { toIsoDateString } from "src/lib/date-formatters";
import { IAgentV1Read, ICommunityRead } from "src/services";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export interface ICommunityLeaseBase {
    uuid: string;
    community: string | ICommunityRead;
    membershipsExisting: number;
    membershipsActive: number | null;
    leasor: string | null;
    leasee: string | IAgentV1Read | null;
    leasees: string[] | IAgentV1Read[];
    publicName: string;
    publicNameWithDefault: string;
    activates: string;
    expires?: string;
    created: string;
    updated: string;
}

export interface ICommunityLeaseUpdate {
    publicName: string;
    activates: Date | null;
    expires: Date | null;
}

export type ICommunityLeaseRead = ExpandCollapse<
    ICommunityLeaseBase,
    "community" | "leasees",
    "leasee"
>;
export type ICommunityLeaseDetailsRead = ExpandCollapse<
    ICommunityLeaseBase,
    "leasees",
    "leasee" | "community"
>;

export class CommunityLeasesService extends Service {
    public list<T extends ICommunityLeaseBase = ICommunityLeaseRead>(params: IQueryParams) {
        params = { ...params, expand: "leasee" };
        const url = this.client.url(["api/v1", "community-leases"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<T>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public listForUser(userUuid: string, params?: IQueryParams) {
        params = { ...params, expand: "leasee", leasees__uuid__in: [userUuid] };

        const url = this.client.url(["api/v1", "community-leases"], params);

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<ICommunityLeaseRead>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public allForLeasees = (leasees: UUID[]) =>
        this.createListTraverser((params) => this.list<ICommunityLeaseDetailsRead>(params))({
            leasees__uuid__in: leasees,
            expand: ["leasee", "community"],
        });

    public delete(communityLeaseID: string) {
        const url = this.client.url(["api/v1", "community-leases", communityLeaseID]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.NoContent]: never;
            }>
        >(url);
    }

    public retrieve(communityLeaseID: string) {
        const params = { expand: ["leasee", "community"] };
        const url = this.client.url(["api/v1", "community-leases", communityLeaseID], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: ICommunityLeaseDetailsRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public update(id: string, data: ICommunityLeaseUpdate) {
        const params = { expand: ["leasee", "community"] };
        const url = this.client.url(["api/v1", "community-leases", id], params);
        const body = {
            ...data,
            activates: data.activates != null ? toIsoDateString(data.activates) : null,
            expires: data.expires != null ? toIsoDateString(data.expires) : null,
        };
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ICommunityLeaseDetailsRead;
                [HttpStatus.BadRequest]: ValidationError<ICommunityLeaseUpdate>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, body);
    }
}
