import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";

interface IAddressReadable {
    id: number;
    zipCode: string;
    city: string;
    // street: string;
    // number: number;
    // littera: string;
    streetAddress: string;
    longitude: number;
    latidute: number;
}

export type IAddressesReadable = IAddressReadable[];

export class AddressService extends Service {
    public get(buildingId?: number) {
        let query = {};
        if (buildingId) {
            query = Object.assign({}, query, { buildings: buildingId });
        }
        const url = this.client.url(["manager", "addresses"], query, {
            listFormat: "comma",
        });
        return this.client.get<IApiResponse<IAddressesReadable, number>>(url, {
            throwOnNon2xx: true,
        });
    }
}
