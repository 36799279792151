import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export enum GroupRoleAdmin {
    WhitelabelAdministrator = "whitelabel_administrator",
    CommunityAdministrator = "community_administrator",
    PropertyStaff = "property_staff",
}

export enum GroupRoleUser {
    Coworker = "coworker",
    ContactPerson = "contact_person",
    Tenant = "tenant",
    Resident = "resident",
    BoardMember = "board_member",
    CoworkingContactPerson = "coworking_contact_person",
    CoworkingMember = "coworking_member",
    ExternalNotification = "external_notification",
}

export type GroupRole = GroupRoleAdmin | GroupRoleUser;

export interface IGroupRoleRead {
    uuid: string;
    client: string;
    name: string;
    role: GroupRole;
    permissions: {
        role: string;
        permission: string;
    }[];
}

interface IListGroupRoleQueryParams extends IQueryParams {
    role?: GroupRole;
}

export class GroupRoleService extends Service {
    public list = (params?: IListGroupRoleQueryParams) => {
        // This isn't used in a list view so the tag filter is itentionally not applied here
        const url = this.client.url(["api", "v1", "group-roles"], params);

        return this.client.get<IApiResponse<IPaginatedBody<IGroupRoleRead>, number>>(url);
    };

    public all = this.createListTraverser(this.list);
}
