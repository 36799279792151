import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";

export interface IOverviewSuccessResponse {
    realEstateCount?: number;
    premisesCount?: number;
    buildingCount?: number;
    residentCount?: number;
    cardCount?: number;
    commentCount?: number;
    resourceCount?: number;
    fileCount?: number;
    administratorCount?: number;
    reportCount?: number;
    contactsCount?: number;
    organizationCount?: number;
    communityCount?: number;
    userCount?: number;
    serviceCount?: number;
    issueCount?: number;
    membershipCount?: number;
    pendingCommunityLeaseInvitationsCount?: number;
    pendingMembershipLeaseInvitationsCount?: number;
    pendingOfficeLeaseInvitationsCount?: number;
    pendingAdminInvitationsCount?: number;
    pendingPremisesLeaseInvitationsCount?: number;
    activeUsersCount?: number;
}

export class TenantOverviewService extends Service {
    public retrieve() {
        const url = this.client.url(["api", "v1", "tenant-overview"], undefined, {
            applyTagFilter: true,
        });
        return this.client.get<IApiResponse<IOverviewSuccessResponse, number>>(url, {
            throwOnNon2xx: true,
        });
    }
}
