import { objectToFormData } from "src/lib/object-to-formdata";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";

import { ILibraryEntryBase } from "./types/library-entry-base";

export interface ILibraryFileRead extends ILibraryEntryBase {
    file: string;
    /** File-size in bytes */
    fileSize: number;
    description: string;
}
export interface ILibraryFileCreate
    extends Pick<ILibraryFileRead, "title" | "category" | "isPublic" | "description"> {
    file: File;
    tags?: string[];
}
export type ILibraryFileUpdate = Partial<ILibraryFileCreate>;

export class LibraryFileService extends Service {
    public create(data: ILibraryFileCreate) {
        const url = this.client.url(["api", "v1", "library-files"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: ILibraryFileRead;
                [HttpStatus.BadRequest]: ValidationError<ILibraryFileCreate>;
                [HttpStatus.Forbidden]: IGenericApiError;
            }>
        >(url, objectToFormData(data));
    }

    public update(uuid: string, data: ILibraryFileUpdate) {
        const url = this.client.url(["api", "v1", "library-files", uuid]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ILibraryFileRead;
                [HttpStatus.BadRequest]: ValidationError<ILibraryFileUpdate>;
                [HttpStatus.Forbidden]: IGenericApiError;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, objectToFormData(data));
    }

    public delete(uuid: string) {
        const url = this.client.url(["api", "v1", "library-files", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
                [HttpStatus.Forbidden]: IGenericApiError;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }
}
