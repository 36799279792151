import { APPLICATION, DeployEnvironment, DEPLOY_ENVIRONMENT } from "src/config/environment";
import { TargetApplication } from "src/lib/target-application";

export const isInternalEnvironment = () => {
    return [DeployEnvironment.Local, DeployEnvironment.Development].includes(DEPLOY_ENVIRONMENT);
};

/**
 * @returns true if NODE_ENV is `"test"`
 */
export function isTestEnv() {
    return process.env.NODE_ENV === "test";
}

export const isProperty = () => APPLICATION === TargetApplication.Property;
export const isManager = () => APPLICATION === TargetApplication.Manager;
export const isIdHub = () => APPLICATION === TargetApplication.IdHub;
