import { Apartments } from "src/models/apartments";
import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export interface IHousingCoopReadable {
    organizationNumber: string;
    name: string;
    street: string;
    city: string;
    zipCode: string;
}

interface IHousingCoopSummaryReadable {
    spaces: {
        number: string;
        rooms: number;
        size: number;
        address: {
            zipCode: string;
            city: string;
            streetAddress: string;
        };
    }[];
}

export class HousingCoopService extends Service {
    public async get(query: string) {
        const hydrate = (res: IPaginatedBody<IHousingCoopReadable>) => {
            return res.results;
        };
        const response = await this.client.get<
            IApiResponse<IPaginatedBody<IHousingCoopReadable>, number>
        >(
            this.client.url(["housing-coops"], {
                search: query.trim().replace("-", " "),
            }),
            {
                throwOnNon2xx: true,
            }
        );
        return this.client.hydrateBody(response, hydrate);
    }

    public async summary(organizationNumber: string) {
        const url = this.client.url(["housing-coops", organizationNumber]);
        const response = await this.client.get<IApiResponse<IHousingCoopSummaryReadable, number>>(
            url,
            {
                throwOnNon2xx: true,
            }
        );
        return this.client.hydrateBody(response, (body: IHousingCoopSummaryReadable) => {
            return new Apartments(body.spaces).addressSummary();
        });
    }

    public async getInvitationPdf() {
        const url = this.client.url(["manager", "building-pdf-invitation"]);
        return await this.client.get<IApiResponse<Blob, number>>(url, {
            throwOnNon2xx: true,
        });
    }
}
