import addMonths from "date-fns/addMonths";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import subMonths from "date-fns/subMonths";

import { HttpStatus, IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginationStoreParams } from "src/store/_generic/pagination-store";
import { IPaginatedBody } from "src/types/paginated-response";

export interface IBookingCalendarDay {
    day: number;
    month: number;
    year: number;
    noBookings: boolean;
    fullyBooked: boolean;
    currentUserHasBooking: boolean;
}

interface IListBookingCalendarDayQueryParams extends IPaginationStoreParams {
    month?: number;
    year?: number;
}

export class BookingCalendarDayService extends Service {
    public list(resourceId: string, params?: IListBookingCalendarDayQueryParams) {
        const url = this.client.url(
            ["api", "v1", "resources", resourceId, "calendar-days"],
            params
        );
        return this.client.get<
            | IApiResponse<IPaginatedBody<IBookingCalendarDay>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public all = (resourceId: string, params?: IListBookingCalendarDayQueryParams) =>
        this.createListTraverser((p) => this.list(resourceId, p))(params);

    public async allForAdjacentMonths(resourceId: string, date: Date) {
        const month = getMonth(date) + 1;
        const year = getYear(date);

        const previousDate = subMonths(date, 1);
        const previousMonth = getMonth(previousDate) + 1;
        const previousYear = getYear(previousDate);

        const nextDate = addMonths(date, 1);
        const nextMonth = getMonth(nextDate) + 1;
        const nextYear = getYear(nextDate);

        return [
            await this.all(resourceId, { month: previousMonth, year: previousYear }),
            await this.all(resourceId, { month, year }),
            await this.all(resourceId, { month: nextMonth, year: nextYear }),
        ].reduce((acc, v) => acc.concat(v), []);
    }
}
