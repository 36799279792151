import { HttpStatus, IGenericApiError, IApiResponseMap } from "src/services/client";
import { Service } from "src/services/service";

interface IResourceFilterFieldOptions<T extends string | number = string | number> {
    label: string;
    value: T;
}

export interface IResourceFilterFieldKeywordsRead {
    uuid: string;
    filterLabel: string;
    inputLabel: string;
    options: IResourceFilterFieldOptions[] | null;
    type: "keywords";
}

export interface IResourceFilterFieldNumberGteRead {
    uuid: string;
    filterLabel: string;
    inputLabel: string;
    options: IResourceFilterFieldOptions<number>[] | null;
    type: "number_gte";
}

export type IResourceFilterFieldRead =
    | IResourceFilterFieldKeywordsRead
    | IResourceFilterFieldNumberGteRead;

export interface IResourceFilterValueRead {
    uuid: UUID;
    field: UUID;
    value: string | number;
}

export interface IResourceFilterValueWrite {
    field: UUID;
    value: string | number;
}

export class ResourceFilterService extends Service {
    public delete(resourceUuid: UUID, filterValueUuid: UUID) {
        const url = this.client.url([
            "api",
            "v1",
            "resources",
            resourceUuid,
            "resource-filter-values",
            filterValueUuid,
        ]);

        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: undefined;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public create(resourceUuid: UUID, data: IResourceFilterValueWrite) {
        const url = this.client.url([
            "api",
            "v1",
            "resources",
            resourceUuid,
            "resource-filter-values",
        ]);

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IResourceFilterValueRead;
                [HttpStatus.BadRequest]: ValidationError<IResourceFilterValueWrite>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, data);
    }

    public update(resourceUuid: UUID, filterValueUuid: UUID, data: IResourceFilterValueWrite) {
        const url = this.client.url([
            "api",
            "v1",
            "resources",
            resourceUuid,
            "resource-filter-values",
            filterValueUuid,
        ]);

        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IResourceFilterValueRead;
                [HttpStatus.BadRequest]: ValidationError<IResourceFilterValueWrite>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, data);
    }
}
