import { objectToFormData } from "src/lib/object-to-formdata";
import { FormValue } from "src/models/form-value";
import { IContactCategoryRead } from "src/services";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { ITag } from "src/services/services/types/tag";
import { IPaginatedBody } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

export enum ContactType {
    Person = "person",
    Organization = "organization",
}

interface IContactFileRead {
    file: string;
    fileSize: number;
    iconUrl: string;
    title: string;
    created: string;
    updated: string;
}

export interface IContactRead {
    uuid: string;
    type: ContactType;
    category: IContactCategoryRead | null;
    title: string;
    description: string;
    firstName?: string;
    lastName?: string;
    organizationName?: string;
    phone: string;
    phoneSecondary: string;
    email: string;
    emailSecondary: string;
    profileImage: string | null;
    file: IContactFileRead | null;
    url: string;
    urlTitle: string;
    position: number;
    tags: ITag[];
    parent?: string;
    children: IContactRead[];
}

export interface IContactCreate {
    category: FormValue<IContactCategoryRead> | null;
    type: ContactType;
    title: string;
    description: string;
    firstName?: string;
    lastName?: string;
    organizationName?: string;
    phone: string;
    phoneSecondary: string;
    email: string;
    emailSecondary: string;
    profileImage: File | string | null;
    file: IContactFileRead | File | string | null;
    url: string;
    urlTitle: string;
    tags: ITag[];
    parent?: string;
}

export interface IContactUpdate extends IContactCreate {
    uuid: string;
}

export type IContactUpsert = IContactCreate | IContactUpdate;

export type IContactChildUpdate = Omit<IContactUpdate, "category">;
export type IContactChildCreate = Omit<IContactCreate, "category">;
export type IContactChildUpsert = IContactChildCreate | IContactChildUpdate;

export class ContactService extends Service {
    public list(params?: IQueryParams) {
        params = { ...params, expand: ["category", "tags", "children"] };
        const url = this.client.url(["api", "v1", "contacts"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<IContactRead>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public allForContact = (id: string, params?: IQueryParams) =>
        this.createListTraverser((p) => this.list({ ...p, parent: id }))(params);

    public retrieve(id: string) {
        const params = { expand: ["category", "tags", "children"] };
        const url = this.client.url(["api", "v1", "contacts", id], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IContactRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public create(data: IContactCreate | IContactChildCreate) {
        const params = { expand: ["category", "tags", "children"] };
        const url = this.client.url(["api", "v1", "contacts"], params);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IContactRead;
                [HttpStatus.BadRequest]: ValidationError<IContactCreate>;
            }>
        >(url, this.prepareContactFormData(data));
    }

    public update(id: string, data: IContactUpdate | IContactChildUpdate) {
        const params = { expand: ["category", "tags", "children"] };
        const url = this.client.url(["api", "v1", "contacts", id], params);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IContactRead;
                [HttpStatus.BadRequest]: ValidationError<IContactUpdate>;
            }>
        >(url, this.prepareContactFormData(data));
    }

    public delete(id: string) {
        const url = this.client.url(["api", "v1", "contacts", id]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: never;
                [HttpStatus.NotFound]: never;
            }>
        >(url);
    }

    public export(params?: Omit<IPaginationStoreParams, "page_size" | "page">) {
        const url = this.client.url(["api", "v1", "contacts", "export"], params, {
            applyTagFilter: true,
        });
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Accepted]: void;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, {});
    }

    private prepareContactFormData(data: IContactUpsert | IContactChildUpsert) {
        const { tags, ...body } = data;

        if (body.file == null) {
            body.file = "";
        } else if (typeof body.file === "string" || "file" in body.file) {
            // @ts-ignore
            delete body.file;
        }

        if (body.profileImage == null) {
            body.profileImage = "";
        } else if (typeof body.profileImage === "string") {
            // @ts-ignore
            delete body.profileImage;
        }

        if (body.parent == null) {
            body.parent = "";
        }

        const formData = objectToFormData(body);

        if (formData.get("category") === "null") {
            formData.delete("category");
        }

        tags.forEach((tag) => {
            formData.append("tags", tag.id);
        });

        return formData;
    }
}
