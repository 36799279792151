import { objectToFormData } from "src/lib/object-to-formdata";
import { IPublicCardTypeV1Read, IAgentV1Read } from "src/services";
import { IApiResponse, HttpStatus, IGenericApiError, IApiResponseMap } from "src/services/client";
import { Service } from "src/services/service";
import { ITag } from "src/services/services/types/tag";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

import { PublishStatusEnum } from "src/types/published-status";

import { IUserBase } from "./user-service";

export interface IPublicCardAuthorProfileV1Read {
    profileImage: string | null;
}

export interface IPublicCardPersonAuthorV1Read {
    uuid: string;
    firstName?: string;
    lastName?: string;
    profile?: IPublicCardAuthorProfileV1Read;
    type: "person";
}

export interface IPublicCardOrganizationAuthorV1Read {
    uuid: string;
    name?: string;
    profileImage?: string;
    type: "organization";
}

export interface IPublicCardFieldsV1Read {
    title?: string;
    address?: string;
    price?: string;
    priceCurrency?: string;
    startTime?: string;
    endTime?: string;
    latitude?: string;
    longitude?: string;
}

export interface IPublicCardV1Base {
    uuid?: string;
    title: string;
    description: string;
    pinned: string | null;
    isPinned: boolean;
    author: string | IAgentV1Read | null;
    creator: IAgentV1Read["uuid"] | null;
    picture?: string;
    cardType: string | IPublicCardTypeV1Read;
    cardTheme: string | null;
    transactionStatus: string | null;
    reportedCount: number;
    isReportedByMe: boolean;
    likedByCount: number;
    isLikedByMe: boolean;
    bookmarkedByCount: number;
    isBookmarkedByMe: boolean;
    followersCount: number;
    isFollowedByMe: boolean;
    interactionsCounts: {
        unanswered: number;
        yes: number;
        no: number;
    };
    interactionByMe: ["yes" | "no"] | null;
    commentCount: number;
    allowComments: boolean;
    fields: IPublicCardFieldsV1Read;
    tags: UUID[] | ITag[];
    created: string;
    updated: string;
    deleted: string | null;
    /** startDate is when the card is scheduled to be published */
    startDate: string | null;
    /** endDate is when the card is scheduled to be unpublished */
    endDate: string | null;
    isDraft: boolean;
    status: PublishStatusEnum;
    contentObject: UUID | null;
    userPermissions: { name: "change" }[];
}

export type IPublicCardV1Read = Expand<IPublicCardV1Base, "tags" | "cardType" | "author">;
export type IPublicCardV1DetailsRead = Expand<IPublicCardV1Base, "tags" | "cardType" | "author">;

export interface IPublicCardFieldsV1Upsert {
    title?: string;
    address?: string;
    price?: string;
    priceCurrency?: string;
    startTime: Date | null;
    endTime: Date | null;
    longitude: string;
    latitude: string;
}

export interface IPublicCardV1Upsert {
    uuid?: string;
    title: string;
    description?: string;
    pinned?: string;
    picture?: File | string;
    author?: string;
    cardType?: string;
    allowComments: boolean;
    tags: UUID[] | ITag[];
    deleted?: string;
    fields?: Partial<IPublicCardFieldsV1Upsert>;
    /** startDate is when the card is scheduled to be published */
    startDate?: string;
    /** endDate is when the card is scheduled to be unpublished */
    endDate?: string;
}

export interface IPublicCardV1InteractsRead {
    card: string;
    user: Expand<IUserBase, "communities">;
    answer: "yes" | "no";
}

export interface IPublicCardV1ReportsRead {
    uuid: string;
    firstName: string;
    lastLame: string;
    profileImage: string;
}

export type IPublicCardV1UpsertError = Omit<ValidationError<IPublicCardV1Upsert>, "fields"> & {
    fields: string[];
};

export class PublicCardService extends Service {
    public retrieve(id: string) {
        const params = { expand: ["author", "card_type", "tags"] };
        const url = this.client.url(["api", "v1", "public-cards", id], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPublicCardV1DetailsRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public list(params: IQueryParams) {
        params = { ...params, ...{ expand: ["author", "card_type", "tags"] } };
        const url = this.client.url(["api", "v1", "public-cards"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            IApiResponseMap<{ [HttpStatus.Ok]: IPaginatedResponseV1<IPublicCardV1Read> }>
        >(url);
    }

    public create(data: IPublicCardV1Upsert) {
        // `fields` must be sent as json, not formdata
        const formData = objectToFormData({ ...data, fields: JSON.stringify(data.fields) });

        const url = this.client.url(["api", "v1", "public-cards"], {
            expand: ["author", "card_type", "tags"],
        });
        return this.client.post<
            | IApiResponse<IPublicCardV1DetailsRead, HttpStatus.Created>
            | IApiResponse<IPublicCardV1UpsertError, HttpStatus.BadRequest>
        >(url, formData);
    }

    public update(id: string, data: IPublicCardV1Upsert) {
        // `fields` must be sent as json, not formdata
        const formData = objectToFormData({ ...data, fields: JSON.stringify(data.fields) });

        const url = this.client.url(["api", "v1", "public-cards", id], {
            expand: ["author", "card_type", "tags"],
        });
        return this.client.patch<
            | IApiResponse<IPublicCardV1DetailsRead, HttpStatus.Ok>
            | IApiResponse<IPublicCardV1UpsertError, HttpStatus.BadRequest>
        >(url, formData);
    }

    public delete(id: string) {
        const url = this.client.url(["api", "v1", "public-cards", id], {
            expand: ["author", "card_type", "tags"],
        });
        return this.client.delete<IApiResponse<unknown, HttpStatus.NoContent>>(url);
    }

    public reportCard(id: string) {
        const url = this.client.url(["api", "v1", "public-cards", id, "reports"]);
        return this.client.post<IApiResponse<unknown, HttpStatus.Created>>(url, {});
    }

    public getReports(uuid: UUID) {
        const url = this.client.url(["api", "v1", "public-cards", uuid, "reports"]);
        return this.client.get<
            | IApiResponse<IPaginatedResponseV1<IPublicCardV1ReportsRead>, HttpStatus.Ok>
            | IApiResponse<IGenericApiError, HttpStatus.NotFound>
        >(url);
    }

    public deleteReport(cardId: UUID, uuid: UUID) {
        const url = this.client.url(["api", "v1", "public-cards", cardId, "reports", uuid]);
        return this.client.delete<IApiResponse<unknown, HttpStatus.NoContent>>(url);
    }

    public getAttendees(uuid: string) {
        const params = { expand: ["user", "communities"], answer: "yes" };
        const url = this.client.url(["api", "v1", "public-cards", uuid, "interacts"], params);
        return this.client.get<
            | IApiResponse<IPaginatedResponseV1<IPublicCardV1InteractsRead>, HttpStatus.Ok>
            | IApiResponse<IGenericApiError, HttpStatus.NotFound>
        >(url);
    }
}
