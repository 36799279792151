import { RouterStore } from "mobx-react-router";

import { LoadingStore } from "./loading-store";
import { ParamStore } from "./param-store";

export interface IStoreUtility {
    $loading: LoadingStore;
    $param: ParamStore;
    $router: RouterStore;
}

export function initUtilityStore(): IStoreUtility {
    const $router = new RouterStore();
    return {
        $loading: new LoadingStore(),
        $param: new ParamStore($router),
        $router,
    };
}
