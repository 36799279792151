import Debug from "debug";
import { action, observable, runInAction } from "mobx";

import { BaseStore } from "src/store/base-store";
import { store } from "src/store/lib/store";

export enum MessageSeverity {
    FatalError,
    Error,
}

export interface IErrorMessage {
    type: MessageSeverity.Error | MessageSeverity.FatalError;
    message: string;
    reference?: string;
}

export type IMessage = IErrorMessage;

type MessageListener = (message: IMessage) => boolean;

const warn = Debug("tmpl:warn:store/global/message");

@store
export class MessageStore extends BaseStore {
    @observable.shallow
    private messageListeners: MessageListener[] = [];

    public createMessage(type: MessageSeverity, message: string, reference?: string) {
        this.passMessageToConsumers({
            type,
            message,
            reference,
        });
    }

    @action
    public onMessage(callback: MessageListener) {
        this.messageListeners = [...this.messageListeners, callback];

        return () =>
            runInAction(
                () =>
                    (this.messageListeners = this.messageListeners.filter(
                        (currentCallback) => currentCallback !== callback
                    ))
            );
    }

    private passMessageToConsumers(message: IMessage) {
        const consumed = this.messageListeners.reverse().some((callback) => callback(message));

        if (!consumed) {
            warn("Warning: No listener consumed message " + JSON.stringify(message));
        }
    }
}
