import { t } from "@lingui/macro";

import { ClientConfigurationModuleSettings, IClientConfigurationModule } from "src/services";
import { HttpStatus } from "src/services/client";
import { BaseForm } from "src/store/base-form";
import { IUserSelection } from "src/types/user-selection";

export type IClientSettingsFormData = ClientConfigurationModuleSettings["leases"] & {
    autoDeleteIssues: boolean;
    deletionDaysDuration: number;
    defaultIssueHandler?: IUserSelection;
    notifyUserOfPendingDeletion: boolean;
};

function createEmptyData(): IClientSettingsFormData {
    return {
        autoDeleteIssues: false,
        deletionDaysDuration: 180,
        defaultIssueHandler: undefined,
        deleteUsersWithoutActiveLease: false,
        notifyUserOfPendingDeletion: false,
        nrDaysBeforeLeaseExpiresToNotify: undefined,
    };
}

type OriginalSettings = {
    issues: IClientConfigurationModule<"issues"> | null;
    leases: IClientConfigurationModule<"leases"> | null;
    defaultIssueHandler?: IUserSelection;
};

export class AlterClientSettingsForm extends BaseForm<IClientSettingsFormData> {
    public static readonly SAVE_CLIENT_MODULES = Symbol("SAVE_CLIENT_MODULES");

    public data: IClientSettingsFormData = createEmptyData();

    private originalSettings?: OriginalSettings;

    public reset() {
        super.reset(createEmptyData());
    }

    private get hasChangedIssueSettings() {
        return this.hasAnyChanged([
            "autoDeleteIssues",
            "defaultIssueHandler",
            "deletionDaysDuration",
        ]);
    }

    private get hasChangedLeasesSettings() {
        return this.hasAnyChanged([
            "deleteUsersWithoutActiveLease",
            "nrDaysBeforeLeaseExpiresToNotify",
            "notifyUserOfPendingDeletion",
        ]);
    }

    private async updateIssueSettings() {
        if (this.hasChangedIssueSettings && this.originalSettings) {
            const response = await this.services.clientModules.update("issues", {
                settings: {
                    // Note: when we patch we *overwrite* the module settings object.
                    // Any setting keys we don't handle here needs to be sent
                    // back as is so we don't accidentally delete them.
                    ...(this.originalSettings.issues?.settings ?? { version: "v2" }),
                    defaultIssueHandler: this.data.defaultIssueHandler?.uuid,
                    deletionDaysDuration: this.data.autoDeleteIssues
                        ? this.data.deletionDaysDuration
                        : undefined,
                },
            });
            return response.status === HttpStatus.Ok ? response.body : null;
        }
        return null;
    }

    private async updateLeasesSettings() {
        if (this.hasChangedLeasesSettings && this.originalSettings) {
            const response = await this.services.clientModules.update("leases", {
                settings: {
                    ...this.originalSettings.leases?.settings,
                    deleteUsersWithoutActiveLease: this.data.deleteUsersWithoutActiveLease,
                    nrDaysBeforeLeaseExpiresToNotify: this.data.notifyUserOfPendingDeletion
                        ? this.data.nrDaysBeforeLeaseExpiresToNotify
                        : undefined,
                },
            });
            return response.status === HttpStatus.Ok ? response.body : null;
        }
        return null;
    }

    public async submit() {
        this.validate();

        if (!this.valid()) {
            return;
        }

        if (!this.originalSettings) {
            throw new Error("Can't patch settings, original settings are not loaded");
        }

        const issues = await this.updateIssueSettings();
        const leases = await this.updateLeasesSettings();

        if (issues || leases) {
            this.init({
                issues,
                leases,
            });
            this.freeze();
        }
    }

    public init(data: OriginalSettings) {
        // Store the original settings object
        // @see #submit()
        this.originalSettings = data;

        const formData: Partial<IClientSettingsFormData> = {};

        if (!data.issues?.settings.deletionDaysDuration) {
            formData.autoDeleteIssues = false;
        } else {
            formData.autoDeleteIssues = true;
            formData.deletionDaysDuration = data.issues?.settings.deletionDaysDuration;
        }

        const notifyExpiration = data.leases?.settings.nrDaysBeforeLeaseExpiresToNotify;
        formData.defaultIssueHandler = data.defaultIssueHandler;
        formData.deleteUsersWithoutActiveLease =
            data.leases?.settings.deleteUsersWithoutActiveLease ?? false;
        formData.nrDaysBeforeLeaseExpiresToNotify =
            data.leases?.settings.nrDaysBeforeLeaseExpiresToNotify ?? 30;
        formData.notifyUserOfPendingDeletion = Boolean(notifyExpiration);

        this.setAll(formData);
    }

    protected validator() {
        if (this.data.autoDeleteIssues && !this.data.deletionDaysDuration) {
            this.addError("deletionDaysDuration", t`global.validation.obligatory-field`);
        }
        if (this.data.notifyUserOfPendingDeletion && !this.data.nrDaysBeforeLeaseExpiresToNotify) {
            this.addError(
                "nrDaysBeforeLeaseExpiresToNotify",
                t`global.validation.obligatory-field`
            );
        }
    }
}
