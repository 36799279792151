import { IAuthToken } from "src/services/auth-token";
import { Client } from "src/services/client";

import { WordMonitoringAlertsService } from "src/services/services/word-monitoring-alerts-service";

import * as deprecated from "./deprecated";
import * as external from "./external";
import {
    AdminInvitationService,
    AdminService,
    AgentService,
    AgreementService,
    AuthService,
    AuthenticationService,
    BookingCalendarDayService,
    BookingService,
    BuildingService,
    ClientAuthMethodService,
    ClientConfigurationService,
    CommunityLeasesService,
    CommunityService,
    ContactCategoryService,
    ContactService,
    EcomUsersService,
    FileCategoryService,
    FileService,
    FolioLinesService,
    FolioService,
    GalleryService,
    GroupRoleService,
    GuestsService,
    InvoiceService,
    InvoicingMethod,
    IssueCategoryConfigurationService,
    IssueCategoryService,
    IssueHistoryService,
    IssueMessagesService,
    IssueService,
    LibraryCategoryService,
    LibraryEntryService,
    LibraryFileService,
    LibraryHtmlPageService,
    LibraryHyperlinkService,
    MainEntranceService,
    MeService,
    MembershipLeasesService,
    MembershipsService,
    OfficeLeasesService,
    OrderLinesService,
    OrganizationsService,
    PasswordResetService,
    PremisesLeaseInvitationService,
    PremiseLeaseUserService,
    PremiseLeaseService,
    PremiseService,
    PublicCardCommentService,
    PublicCardService,
    PublicCardLikeService,
    PublicCardTypeService,
    RealEstateService,
    ResetPassworConfirmationService,
    ResourceService,
    SignableDocumentService,
    SubscriptionsService,
    SurveyAnswerService,
    SurveyQuestionOptionService,
    SurveyQuestionService,
    SurveyService,
    TagFilterService,
    TagService,
    TagSubTypeService,
    TenantOverviewService,
    UserAddresses,
    UserRoleService,
    UserService,
    WhiteLabelService,
    WordMonitoringConfigsService,
    ClientModulesService,
    ResourceFilterService,
} from "./services";

export class Services {
    public readonly address: deprecated.AddressService;
    public readonly admin: AdminService;
    public readonly adminInvitation: AdminInvitationService;
    public readonly agent: AgentService;
    public readonly agreement: AgreementService;
    public readonly auth: AuthService;
    public readonly authentication: AuthenticationService;
    public readonly bookingCalendarDay: BookingCalendarDayService;
    public readonly bookingV1: BookingService;
    public readonly building: deprecated.BuildingService;
    public readonly buildingV1: BuildingService;
    public readonly clientAuthMethod: ClientAuthMethodService;
    public readonly clientConfiguration: ClientConfigurationService;
    public readonly clientModules: ClientModulesService;
    public readonly community: CommunityService;
    public readonly communityLeases: CommunityLeasesService;
    public readonly contact: deprecated.ContactService;
    public readonly contactCategoryV1: ContactCategoryService;
    public readonly contactV1: ContactService;
    public readonly ecomUsers: EcomUsersService;
    public readonly feed: deprecated.FeedService;
    public readonly file: FileService;
    public readonly fileCategory: FileCategoryService;
    public readonly folio: FolioService;
    public readonly folioLines: FolioLinesService;
    public readonly gallery: GalleryService;
    public readonly groupRole: GroupRoleService;
    public readonly guests: GuestsService;
    public readonly housingCoop: deprecated.HousingCoopService;
    public readonly invoices: InvoiceService;
    public readonly invoicingMethod: InvoicingMethod;
    public readonly issue: IssueService;
    public readonly issueCategory: IssueCategoryService;
    public readonly issueCategoryConfiguration: IssueCategoryConfigurationService;
    public readonly issueHistory: IssueHistoryService;
    public readonly issueMessages: IssueMessagesService;
    public readonly library: deprecated.LibraryService;
    public readonly libraryCategoryV1: LibraryCategoryService;
    public readonly libraryEntryV1: LibraryEntryService;
    public readonly libraryFileV1: LibraryFileService;
    public readonly libraryHtmlPageV1: LibraryHtmlPageService;
    public readonly libraryHyperlinkV1: LibraryHyperlinkService;
    public readonly mainEntrance: MainEntranceService;
    public readonly me: MeService;
    public readonly membershipLeases: MembershipLeasesService;
    public readonly memberships: MembershipsService;
    public readonly oEmbed: external.OEmbedService;
    public readonly officeLeases: OfficeLeasesService;
    public readonly onboarding: deprecated.OnboardingService;
    public readonly orderLines: OrderLinesService;
    public readonly organizations: OrganizationsService;
    public readonly passwordReset: PasswordResetService;
    public readonly premise: PremiseService;
    public readonly premiseLease: PremiseLeaseService;
    public readonly premisesLeaseInvitation: PremisesLeaseInvitationService;
    public readonly premiseLeaseUser: PremiseLeaseUserService;
    public readonly publicCardCommentV1: PublicCardCommentService;
    public readonly publicCardLike: PublicCardLikeService;
    public readonly publicCardTypeV1: PublicCardTypeService;
    public readonly publicCardV1: PublicCardService;
    public readonly realEstate: RealEstateService;
    public readonly report: deprecated.ReportService;
    public readonly resetPasswordConfirmation: ResetPassworConfirmationService;
    public readonly resourceFilter: ResourceFilterService;
    public readonly resourceV1: ResourceService;
    public readonly session: deprecated.SessionService;
    public readonly sharing: deprecated.SharingService;
    public readonly signableDocument: SignableDocumentService;
    public readonly space: deprecated.SpaceService;
    public readonly subscriptions: SubscriptionsService;
    public readonly survey: SurveyService;
    public readonly surveyAnswer: SurveyAnswerService;
    public readonly surveyQuestion: SurveyQuestionService;
    public readonly surveyQuestionOption: SurveyQuestionOptionService;
    public readonly tag: TagService;
    public readonly tagFilter: TagFilterService;
    public readonly tagSubType: TagSubTypeService;
    public readonly tenantOverview: TenantOverviewService;
    public readonly user: deprecated.UserService;
    public readonly userAddresses: UserAddresses;
    public readonly userRole: UserRoleService;
    public readonly userV1: UserService;
    public readonly whiteLabel: WhiteLabelService;
    public readonly wordMonitoringAlerts: WordMonitoringAlertsService;
    public readonly wordMonitoringConfigs: WordMonitoringConfigsService;

    constructor(private client: Client) {
        this.address = new deprecated.AddressService(client);
        this.admin = new AdminService(client);
        this.adminInvitation = new AdminInvitationService(client);
        this.agent = new AgentService(client);
        this.agreement = new AgreementService(client);
        this.auth = new AuthService(client);
        this.authentication = new AuthenticationService(client);
        this.bookingCalendarDay = new BookingCalendarDayService(client);
        this.bookingV1 = new BookingService(client);
        this.building = new deprecated.BuildingService(client);
        this.buildingV1 = new BuildingService(client);
        this.clientAuthMethod = new ClientAuthMethodService(client);
        this.clientConfiguration = new ClientConfigurationService(client);
        this.clientModules = new ClientModulesService(client);
        this.community = new CommunityService(client);
        this.communityLeases = new CommunityLeasesService(client);
        this.contact = new deprecated.ContactService(client);
        this.contactCategoryV1 = new ContactCategoryService(client);
        this.contactV1 = new ContactService(client);
        this.ecomUsers = new EcomUsersService(client);
        this.feed = new deprecated.FeedService(client);
        this.file = new FileService(client);
        this.fileCategory = new FileCategoryService(client);
        this.folio = new FolioService(client);
        this.folioLines = new FolioLinesService(client);
        this.gallery = new GalleryService(client);
        this.groupRole = new GroupRoleService(client);
        this.guests = new GuestsService(client);
        this.housingCoop = new deprecated.HousingCoopService(client);
        this.invoices = new InvoiceService(client);
        this.invoicingMethod = new InvoicingMethod(client);
        this.issue = new IssueService(client);
        this.issueCategory = new IssueCategoryService(client);
        this.issueCategoryConfiguration = new IssueCategoryConfigurationService(client);
        this.issueHistory = new IssueHistoryService(client);
        this.issueMessages = new IssueMessagesService(client);
        this.library = new deprecated.LibraryService(client);
        this.libraryCategoryV1 = new LibraryCategoryService(client);
        this.libraryEntryV1 = new LibraryEntryService(client);
        this.libraryFileV1 = new LibraryFileService(client);
        this.libraryHtmlPageV1 = new LibraryHtmlPageService(client);
        this.libraryHyperlinkV1 = new LibraryHyperlinkService(client);
        this.mainEntrance = new MainEntranceService(client);
        this.me = new MeService(client);
        this.membershipLeases = new MembershipLeasesService(client);
        this.memberships = new MembershipsService(client);
        this.oEmbed = new external.OEmbedService(client);
        this.officeLeases = new OfficeLeasesService(client);
        this.onboarding = new deprecated.OnboardingService(client);
        this.orderLines = new OrderLinesService(client);
        this.organizations = new OrganizationsService(client);
        this.passwordReset = new PasswordResetService(client);
        this.premise = new PremiseService(client);
        this.premiseLease = new PremiseLeaseService(client);
        this.premisesLeaseInvitation = new PremisesLeaseInvitationService(client);
        this.premiseLeaseUser = new PremiseLeaseUserService(client);
        this.publicCardCommentV1 = new PublicCardCommentService(client);
        this.publicCardLike = new PublicCardLikeService(client);
        this.publicCardTypeV1 = new PublicCardTypeService(client);
        this.publicCardV1 = new PublicCardService(client);
        this.realEstate = new RealEstateService(client);
        this.report = new deprecated.ReportService(client);
        this.resetPasswordConfirmation = new ResetPassworConfirmationService(client);
        this.resourceFilter = new ResourceFilterService(client);
        this.resourceV1 = new ResourceService(client);
        this.session = new deprecated.SessionService(client);
        this.sharing = new deprecated.SharingService(client);
        this.signableDocument = new SignableDocumentService(client);
        this.space = new deprecated.SpaceService(client);
        this.subscriptions = new SubscriptionsService(client);
        this.survey = new SurveyService(client);
        this.surveyAnswer = new SurveyAnswerService(client);
        this.surveyQuestion = new SurveyQuestionService(client);
        this.surveyQuestionOption = new SurveyQuestionOptionService(client);
        this.tag = new TagService(client);
        this.tagFilter = new TagFilterService(client);
        this.tagSubType = new TagSubTypeService(client);
        this.tenantOverview = new TenantOverviewService(client);
        this.user = new deprecated.UserService(client);
        this.userAddresses = new UserAddresses(client);
        this.userRole = new UserRoleService(client);
        this.userV1 = new UserService(client);
        this.whiteLabel = new WhiteLabelService(client);
        this.wordMonitoringAlerts = new WordMonitoringAlertsService(client);
        this.wordMonitoringConfigs = new WordMonitoringConfigsService(client);
    }

    public setWhitelabelFilterGetter(fn: () => string | undefined) {
        this.client.setWhitelabelfilterGetter(fn);
    }

    public setBaseUrl(baseUrl: string) {
        this.client.setBaseUrl(baseUrl);
    }

    public getBaseUrl() {
        return this.client.getBaseUrl();
    }

    public setAuthToken(token: IAuthToken) {
        this.client.setAuthToken(token);
    }

    public destroyAuthToken() {
        this.client.destroyAuthToken();
    }

    public getAuthToken() {
        return this.client.getAuthToken();
    }
}
export * from "./services";
