import { objectToFormData } from "src/lib/object-to-formdata";

import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

export interface IContactPerson {
    id: number;
    firstName: string;
    lastName: string;
    title: string;
    email: string;
    phone: string;
    contact: number;
}

export interface IContact {
    id: number;
    firstName: string;
    lastName: string;
    description: string;
    title: string;
    email: string;
    emailSecondary: string;
    phone: string;
    phoneSecondary: string;
    created: string;
    profileImage: string | null;
    profileImageName?: string | null;
    url: string;
    contactPersons?: IContactPerson[];
}

export interface IContactWritable {
    firstName: string;
    lastName: string;
    description: string;
    title: string;
    email: string;
    emailSecondary: string;
    phone: string;
    phoneSecondary: string;
    url: string;
    profileImage: File | string | null;
    file: File | string | null;
}

export type IContactCreateError = ValidationError<IContactWritable>;
export type IContactUpdateError = ValidationError<IContactWritable>;

export class ContactService extends Service {
    public async getPaginatedContacts(
        url?: string,
        params?: IPaginationStoreParams,
        category?: string
    ) {
        if (!url) {
            const queryParams = {
                category__code__iexact: category ? category : "",
            };
            if (params && params.page) {
                Object.assign(queryParams, { page: params.page });
            }
            url = this.client.url(["manager", "contacts"], queryParams, {
                applyWlFilter: true,
            });
        }
        return this.client.get<IApiResponse<IPaginatedBody<IContact>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async getContact(id: number) {
        const url = this.client.url(["manager", "contacts", id.toString()]);
        return this.client.get<IApiResponse<Required<IContact>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async patchContact(id: number, contact: Partial<IContactWritable>) {
        const url = this.client.url(["manager", "contacts", id.toString()]);

        const body = objectToFormData(contact);

        if (typeof body.get("file") === "string") {
            if (body.get("file") === "null") {
                body.set("file", "");
            } else {
                body.delete("file");
            }
        }

        if (typeof body.get("profileImage") === "string") {
            if (body.get("profileImage") === "null") {
                body.set("profileImage", "");
            } else {
                body.delete("profileImage");
            }
        }

        return this.client.patch<IApiResponse<Required<IContact>, number>>(url, body, {
            throwOnNon2xx: true,
        });
    }

    public async createContact(contact: IContactWritable) {
        const url = this.client.url(["manager", "contacts"]);
        const body = objectToFormData(contact);
        if (typeof body.get("file") === "string") {
            body.delete("file");
        }

        if (typeof body.get("profileImage") === "string") {
            body.delete("profileImage");
        }

        return this.client.post<IApiResponse<Required<IContact>, number>>(url, body, {
            throwOnNon2xx: true,
        });
    }

    public async patchContactPerson(id: number, contactPerson: Partial<IContactPerson>) {
        const url = this.client.url(["manager", "contact-people", id.toString()]);
        return this.client.patch<IApiResponse<IContactPerson, number>>(url, contactPerson, {
            throwOnNon2xx: true,
        });
    }

    public async createContactPerson(contactPerson: Partial<IContactPerson>) {
        const url = this.client.url(["manager", "contact-people"]);
        return this.client.post<IApiResponse<IContactPerson, number>>(url, contactPerson, {
            throwOnNon2xx: true,
        });
    }

    public async deleteContactPerson(id: number) {
        const url = this.client.url(["manager", "contact-people", id.toString()]);
        return this.client.delete(url, {
            throwOnNon2xx: true,
        });
    }

    public async delete(id: number) {
        const url = this.client.url(["manager", "contacts", id.toString()]);
        return this.client.delete(url, {
            throwOnNon2xx: true,
        });
    }
}
