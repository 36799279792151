import { objectToFormData } from "src/lib/object-to-formdata";
import { FormValue } from "src/models/form-value";
import { IResourceBase, IUserRead } from "src/services";
import { IApiResponse, HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

export enum BookingStatus {
    Pending = "pending",
    Confirmed = "confirmed",
    Declined = "declined",
    Exception = "exception",
    Reserved = "reserved",
}

type IBookingResourceRead = Collapse<IResourceBase, "tags" | "owner" | "category">;

export interface IBookingBase {
    uuid: UUID;
    startDatetime: string;
    endDatetime: string;
    status: BookingStatus;
    user: string | IUserRead | null;
    resource: string | IBookingResourceRead;
    image: string;
    price: string;
    priceExclUpsells: string;
    ecommerceBasketId: number;
    ecommerceOrderId: number;
    currency: string;
    priceTaxAmount: string;
    priceInclTax: string;
    priceExclTax: string;
    priceExclUpsellsTaxAmount: string;
    priceExclUpsellsInclTax: string;
    priceExclUpsellsExclTax: string;
    taxLevel: string;
    accessStartDatetime: string;
    accessEndDatetime: string;

    /**  @deprecated */
    startDate: string;
    /**  @deprecated */
    endDate: string;
}

export type IBookingRead = Expand<IBookingBase, "user" | "resource">;

export interface IBookingUpdate {
    startDatetime?: string;
    endDatetime?: string;
    status?: BookingStatus;
    resource?: string;
    image?: File | string | null;
    user?: string;
    price?: string;
}

export interface IBookingCreate {
    startDatetime?: Date;
    endDatetime?: Date;
    resource?: string;
    user?: FormValue<IUserRead>;
}

interface IListBookingQueryParams extends IQueryParams {
    start_date?: string;
    end_date?: string;
}

export class BookingService extends Service {
    public list(params: IQueryParams) {
        params = { ...params, expand: ["user", "resource"] };
        const url = this.client.url(["api", "v1", "bookings"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IBookingRead>;
            }>
        >(url);
    }

    public listForResource(resourceId: string, params?: IListBookingQueryParams) {
        params = { ...params, expand: "user" };
        const url = this.client.url(
            ["api", "v1", "resources", resourceId, "resources-bookings"],
            params,
            {
                applyTagFilter: true,
            }
        );
        return this.client.get<
            | IApiResponse<IPaginatedResponseV1<IBookingRead>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public allForResource = (resourceId: string, params?: IListBookingQueryParams) =>
        this.createListTraverser((p) => this.listForResource(resourceId, p))(params);

    public create(data: IBookingCreate) {
        const url = this.client.url(["api", "v1", "bookings"], {
            expand: "user",
        });
        const payload = {
            ...data,
            startDatetime: data.startDatetime?.toISOString(),
            endDatetime: data.endDatetime?.toISOString(),
            status: BookingStatus.Confirmed,
        };
        return this.client.post<
            | IApiResponse<IBookingRead, HttpStatus.Created>
            | IApiResponse<
                  ValidationError<IBookingCreate> | IGenericApiError,
                  HttpStatus.BadRequest
              >
        >(url, payload);
    }

    public delete(bookingId: string) {
        const url = this.client.url(["api", "v1", "bookings", bookingId]);
        return this.client.delete<
            IApiResponse<unknown, HttpStatus.NoContent> | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public update(id: string, data: IBookingUpdate) {
        const url = this.client.url(["api", "v1", "bookings", id], {
            expand: "user",
        });
        const payload = objectToFormData(data);
        return this.client.patch<
            | IApiResponse<IBookingRead, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
            | IApiResponse<ValidationError<IBookingRead>, HttpStatus.BadRequest>
        >(url, payload);
    }

    public deleteGuest(guestId: string, bookingId: string) {
        const url = this.client.url(["api", "v1", "bookings", bookingId, "guests", guestId]);
        return this.client.delete<IApiResponse<unknown, HttpStatus.Ok>>(url);
    }

    public export(params?: Omit<IPaginationStoreParams, "page_size" | "page">) {
        const url = this.client.url(["api", "v1", "bookings", "export"], params, {
            applyTagFilter: true,
        });
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Accepted]: void;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, {});
    }
}
