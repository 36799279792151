import { t } from "@lingui/macro";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import env from "penv.macro";
import "react-app-polyfill/stable";
import { render } from "react-dom";
import { Router } from "react-router";

import { autoSaveStore } from "src/lib/auto-save-store";
import { exposeFreezeStore } from "src/lib/expose-freeze-store";
import { setupSentry } from "src/lib/setup-sentry";
import { TargetApplication } from "src/lib/target-application";

import { Services } from "src/services";
import { Client } from "src/services/client";
import { RequestQueue } from "src/services/request-queue";
import { Store } from "src/store";
import { MessageSeverity } from "src/store/global";

import { DEPLOY_ENVIRONMENT, DeployEnvironment } from "./config/environment";
import { exposePrintStore } from "./lib/expose-print-store";

const loadApp = env(
    {
        manager: async (client: Client, services: Services, root: HTMLElement) => {
            // TODO switch to TmplStore when brf can be dropped
            const { BrfStore } = await import("src/app/brf/store");
            const store = new BrfStore(services);
            const history = syncHistoryWithStore(createBrowserHistory(), store.utility.$router);
            Promise.all([import("src/app/tmpl"), bootstrap({ client, store })]).then(
                ([{ createApp: createManager }]) => {
                    const Manager = createManager(Router, { history }, store);
                    render(<Manager />, root);
                }
            );
        },
        idhub: async (client: Client, services: Services, root: HTMLElement) => {
            const { IdHubStore } = await import("src/app/idhub/store");
            const store = new IdHubStore(services);
            const history = syncHistoryWithStore(createBrowserHistory(), store.utility.$router);
            Promise.all([
                import("src/app/idhub"),
                bootstrap({ client, store, skipInitOfReactDatepickerLocale: true }),
            ]).then(([{ createApp: createIdHub }]) => {
                const IdHub = createIdHub(Router, { history }, store);
                render(<IdHub />, root);
            });
        },
        property: async (client: Client, services: Services, root: HTMLElement) => {
            const { PropertyStore } = await import("src/app/property/store");
            const store = new PropertyStore(services);
            const history = syncHistoryWithStore(createBrowserHistory(), store.utility.$router);
            Promise.all([import("src/app/property"), bootstrap({ client, store })]).then(
                ([{ createApp: createProperty }]) => {
                    const Property = createProperty(Router, { history }, store);
                    render(<Property />, root);
                }
            );
        },
    },
    () => {
        throw new Error(
            `Unexpected REACT_APP_TARGET_APPLICATION, got "${process.env.REACT_APP_TARGET_APPLICATION}". ` +
                `Start the application by running \`yarn <target-application> start\`, ` +
                `where <target-application> is one of ${Object.values(TargetApplication)
                    .map((x) => `"${x}"`)
                    .join(", ")}.`
        );
    }
);

setupSentry();

type BootstrapConfig = {
    client: Client;
    store: Store;
    skipInitOfReactDatepickerLocale?: boolean;
};

declare let global: any;
async function bootstrap({ client, store, skipInitOfReactDatepickerLocale }: BootstrapConfig) {
    client.onCatastrophicFailure(() => {
        store.global.$message.createMessage(MessageSeverity.Error, t`global.unexpected-error`);
        store.utility.$loading.stopAll();
    });

    client.onRequestFailure(() => {
        store.global.$message.createMessage(MessageSeverity.Error, t`global.unexpected-error`);
    });

    client.onUnauthorizedFailure(() => {
        store.global.$session.destroy();
    });

    autoSaveStore(store, window.localStorage);
    exposePrintStore(store);
    exposeFreezeStore(store);

    if (process.env.NODE_ENV === "development") {
        global.store = store;
    }

    if (
        window.localStorage &&
        !window.localStorage.debug &&
        DEPLOY_ENVIRONMENT !== DeployEnvironment.Production
    ) {
        window.localStorage.debug = "tmpl:warn*";
    }

    if (!skipInitOfReactDatepickerLocale) {
        store.global.$i18n.onActivateLocale(async (locale, dateLocale) => {
            // Dynamically import react-datepicker locale register
            // As we do not use datepicker in IdHub we want to skip this
            // init for IdHub but allow for every other app.
            await import("react-datepicker").then(({ registerLocale, setDefaultLocale }) => {
                registerLocale(locale, dateLocale);
                setDefaultLocale(locale);
            });
        });
    }

    await store.global.$i18n.activateLocale(store.global.$i18n.locale);
}

const client = new Client({ requestQueue: new RequestQueue(5) });
const services = new Services(client);

const root = document.getElementById("root")!;

loadApp(client, services, root);
