import { observable, action, makeObservable } from "mobx";
import * as qs from "querystringify";

import { getMatchingItemIndex, updateMatchingItem } from "src/lib/array";
import { checkNumberBoundaries } from "src/lib/number";
import { NoPagePaginationError } from "src/models/no-page-pagination-error";
import { IApiResponseMap, HttpStatus } from "src/services/client";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

type IServiceEndpointResponse<T> = Promise<
    IApiResponseMap<{
        [HttpStatus.Ok]: IPaginatedResponseV1<T>;
    }>
>;

export type IPaginationStoreParams = IQueryParams & {
    sort?: string;
    page?: number;
    page_size?: number;
};

const pageSizes = [10, 25, 50, 75, 100];

export type IPaginationServiceEndpoint<T> = (
    params: IPaginationStoreParams
) => IServiceEndpointResponse<T>;

export class PaginationStore<T> implements Partial<IPaginatedResponseV1<T>> {
    static readonly DEFAULT_PAGE_SIZE = 25;

    /**
     * All valid page size the user can select from
     */
    static readonly PAGE_SIZES = pageSizes;

    @observable
    public count?: number;

    @observable
    public next?: string | null;

    @observable
    public previous?: string | null;

    @observable.shallow
    public results?: T[];

    /**
     * The page that is currently being loaded or the most recently loaded
     * page.
     */
    @observable
    public page?: number;

    /**
     * The raw value of the manual page input. This value is not
     * validated at this stage and can be any string.
     */
    @observable
    private rawInput?: string;

    @observable
    public params: IPaginationStoreParams = {};

    public constructor(
        private serviceEndpoint: IPaginationServiceEndpoint<T>,
        private defaultParams: IPaginationStoreParams = {
            page_size: PaginationStore.DEFAULT_PAGE_SIZE,
        }
    ) {
        makeObservable(this);
        this.params = { ...this.defaultParams };
        if (window) {
            this.params = {
                ...this.params,
                ...(qs.parse(window.location.search) as IPaginationStoreParams),
            };
        }
    }

    public get input() {
        return this.rawInput ?? this.page;
    }

    public get pageSize() {
        return this.params.page_size || PaginationStore.DEFAULT_PAGE_SIZE;
    }

    @action.bound
    public updateResultItem(match: Partial<T>, update: (item: T) => T) {
        if (this.results == null) {
            return;
        }
        this.results = updateMatchingItem(this.results, match, update);
    }

    public getResultItem(match: Partial<T>) {
        if (this.results == null) {
            return;
        }

        const index = getMatchingItemIndex(this.results, match);

        if (index === -1) {
            return null;
        } else {
            return this.results[index];
        }
    }

    /**
     * Sets the page_size param to the value if that value
     * is valid. Then reloads page with the new page size param
     */
    @action.bound
    public setPageSize(value: number): void {
        if (PaginationStore.PAGE_SIZES.includes(value)) {
            this.params.page_size = value;
        }
    }

    /**
     * Sets page size to initial default value then reloads page
     */
    @action.bound
    public async resetPageSize(): Promise<void> {
        this.params.page_size = PaginationStore.DEFAULT_PAGE_SIZE;
        return this.reload();
    }

    /**
     * Returns all internal valid page sizes the user can choose from
     */
    public get validPageSizes() {
        return PaginationStore.PAGE_SIZES;
    }

    /**
     * Returns the page count for the current query. All lists have at least
     * one page, even if it's only an empty one.
     */
    public get pageCount() {
        return Math.ceil((this.count || 1) / this.pageSize);
    }

    public isFirstPage() {
        return this.page === 1;
    }

    public isLastPage() {
        return this.page === this.pageCount;
    }

    @action.bound
    public async getInitial() {
        await this.fetchPage(1);
    }

    @action.bound
    public async getNext() {
        if (this.isLastPage()) {
            throw new NoPagePaginationError("next");
        }

        const nextPage = this.page ? this.page + 1 : 1;
        await this.fetchPage(nextPage);
    }

    @action.bound
    public async getPrevious() {
        if (this.isFirstPage()) {
            throw new NoPagePaginationError("previous");
        }

        const prevPage = this.page ? this.page - 1 : 1;
        await this.fetchPage(prevPage);
    }

    @action.bound
    public async getPage(page: number | string) {
        await this.fetchPage(this.parsePage(page));
    }

    @action.bound
    public async getPageFromInput(skipIfUnchanged?: boolean) {
        if (skipIfUnchanged && this.rawInput == null) {
            return;
        }
        await this.fetchPage(this.parsePage(this.rawInput ?? this.page ?? 1));
    }

    @action.bound
    public async reload() {
        if (this.params.page) {
            return this.getPage(this.params.page);
        }
        return this.getInitial();
    }

    @action.bound
    public setInput(input: string) {
        this.rawInput = input;
    }

    @action.bound
    public clearInput() {
        this.rawInput = undefined;
    }

    @action.bound
    public setParam(key: string, value: IQueryParamValue) {
        this.params[key] = value;
    }

    @action.bound
    public setParams(params: { [key: string]: IQueryParamValue }) {
        this.params = { ...this.params, ...params };
    }

    public getParam<P>(key: string, defaultValue?: P) {
        if (typeof defaultValue !== "undefined") {
            return this.params[key] || defaultValue;
        }
        return this.params[key];
    }

    public getFirstParam<P>(key: string, defaultValue?: P) {
        const value = this.getParam(key, defaultValue);
        if (Array.isArray(value)) {
            return value[0];
        }
        return value;
    }

    /**
     *
     * @returns Params without pageSize and page
     */
    @action
    public getExportParams() {
        const { page_size: _pageSize, page: _page, ...params } = this.params;
        return { ...params };
    }

    @action.bound
    public reset() {
        this.count = undefined;
        this.next = undefined;
        this.previous = undefined;
        this.results = undefined;
        this.params = { ...this.defaultParams };
    }

    @action.bound
    private setPage(page: string | number) {
        this.page = this.parsePage(page);
    }

    @action
    private update(next: IPaginatedResponseV1<T>, page: number) {
        this.count = next.count;
        this.next = next.next;
        this.previous = next.previous;
        this.results = next.results;
        this.params.page = page;
        this.page = page;
    }

    private parsePage(page: string | number) {
        const parsedPage = checkNumberBoundaries(this.pageCount, 1, Number(page));
        return isNaN(parsedPage) ? 1 : parsedPage;
    }

    private async fetchPage(page: number) {
        this.setPage(page);
        this.clearInput();

        const params = { ...this.params, page };
        const response = await this.serviceEndpoint(params);

        // Ignore pages that don't match the current `page`. This a crude
        // and ineffiecent way to skip obsolete requests. The performance of
        // navigating with the paginator can be improved by aborting obsolete
        // requests instead.
        if (page === this.page) {
            if (response.status === HttpStatus.Ok) {
                this.update(response.body, page);
            }
        }
    }
}
export default PaginationStore;
