import * as Sentry from "@sentry/browser";
import Debug from "debug";
import { action, observable } from "mobx";

import { API_CORE_CLIENT_ID, API_CORE_PUBLIC_URL } from "src/config/environment";
import { toSnakeCase, toCamelCase, formDataToSnakeCase } from "src/lib/transform-keys";
import { hasProtocol } from "src/lib/url";
import { RequestError } from "src/models/request-error";
import { observed } from "src/store/lib/observed";

import { AuthToken, IAuthToken } from "./auth-token";
import { RequestQueue } from "./request-queue";

const debug = Debug("tmpl:client");

export enum HttpStatus {
    Ok = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
}

export type IApiResponseMap<T extends Partial<{ [key in HttpStatus]: any }>> =
    | { [key in keyof T]: key extends HttpStatus ? IApiResponse<T[key], key> : never }[keyof T]
    | IApiResponse<unknown, Exclude<HttpStatus, keyof T>>;

export interface IGenericApiResponse {
    status: HttpStatus;
    body: unknown;
}

export interface IGenericApiError {
    detail: string;
}

export interface IApiResponse<T, S extends HttpStatus> extends IGenericApiResponse {
    status: S;
    body: T;
}

interface IURLOptions {
    applyTagFilter?: boolean;
    listFormat?: "multiple" | "comma";
    /**
     * @deprecated Use the tag filter instead
     */
    applyWlFilter?: boolean;
}

type FetchRequestInit = RequestInit & {
    expectedStatus?: HttpStatus[];
    throwOnNon2xx?: boolean;
    rawRequest?: boolean;
};

@observed
export class Client {
    @observable
    protected token: AuthToken;
    @observable
    private _baseUrl: string | null = null;
    private readonly requestQueue: RequestQueue;
    private readonly _fetch: (request: Request, init: FetchRequestInit) => Promise<Response>;
    private readonly refreshTokenCallback: (
        refreshToken: string
    ) => Promise<IAuthToken | undefined>;

    // Warn if the url is longer than URL_WARNING_LENGTH. For urls longer than
    // that the api may abort the request and return 502. The api's limit is on
    // a more general level than specifically url length, so the warning is set
    // a bit low to provide some margin.
    private readonly URL_WARNING_LENGTH = 3 * 1024;

    private readonly DEFAULT_EXPECTED_STATUS = [
        HttpStatus.Ok,
        HttpStatus.Created,
        HttpStatus.Accepted,
        HttpStatus.NoContent,
        HttpStatus.BadRequest,
    ];

    protected catastrophicFailureHandler?: () => void;
    protected requestFailureHandler?: () => void;
    protected unauthorizedFailureHandler?: () => void;
    protected whitelabelFilterIdGetter?: () => string | undefined;

    protected defaultOptions: FetchRequestInit = {
        expectedStatus: this.DEFAULT_EXPECTED_STATUS,
        throwOnNon2xx: false,
        rawRequest: false,
    };

    constructor({
        requestQueue,
        fetch: fetchFn,
        refreshTokenCallback,
    }: {
        requestQueue: RequestQueue;
        fetch?: (request: Request, init: FetchRequestInit) => Promise<Response>;
        refreshTokenCallback?: (refreshToken: string) => Promise<IAuthToken | undefined>;
    }) {
        this.token = new AuthToken();
        this.requestQueue = requestQueue;
        this._fetch = fetchFn ?? fetch.bind(window);
        this.refreshTokenCallback =
            refreshTokenCallback ??
            (async (token) => {
                const response = await this._fetch(
                    new Request(this.url([API_CORE_PUBLIC_URL, "api", "v1", "auth", "login"])),
                    {
                        method: "POST",
                        body: (() => {
                            const data = new FormData();
                            data.set("grant_type", "refresh_token");
                            data.set("refresh_token", token);
                            data.set("client_id", API_CORE_CLIENT_ID);
                            return data;
                        })(),
                    }
                );
                if (response.status !== HttpStatus.Ok) {
                    return undefined;
                }
                return (await this.parseBody(response)) as IAuthToken;
            });
    }

    public get baseUrl() {
        if (this._baseUrl == null) {
            throw new Error("_baseUrl is not set.");
        }
        return this._baseUrl;
    }

    @action
    public setBaseUrl(baseUrl: string) {
        if (hasProtocol(baseUrl)) {
            this._baseUrl = baseUrl;
        } else {
            this._baseUrl = `https://${baseUrl}`;
        }
    }

    public getBaseUrl() {
        return this._baseUrl;
    }

    public setAuthToken(token: IAuthToken) {
        this.token.set(token);
    }

    public destroyAuthToken() {
        this.token.destroy();
    }

    public getAuthToken() {
        return this.token;
    }

    public onRequestFailure(fn: () => void) {
        this.requestFailureHandler = fn;
    }

    public onCatastrophicFailure(fn: () => void) {
        this.catastrophicFailureHandler = fn;
    }

    public onUnauthorizedFailure(fn: () => void) {
        this.unauthorizedFailureHandler = fn;
    }

    public setWhitelabelfilterGetter(fn: () => string | undefined) {
        this.whitelabelFilterIdGetter = fn;
    }

    private getParamEncoded(param: string) {
        if (decodeURIComponent(param) === param) {
            return encodeURIComponent(param);
        }
        return param;
    }

    public url(
        parts: (string | number)[],
        queryParams: IQueryParams = {},
        options: IURLOptions = {}
    ) {
        if ("wl_filter" in queryParams) {
            throw new Error("wl_filter cannot be set manually.");
        }

        if (options.applyWlFilter && this.whitelabelFilterIdGetter) {
            const filter = this.whitelabelFilterIdGetter();
            if (filter) {
                queryParams = { ...queryParams, wl_filter: filter };
            }
        }

        if (options.applyTagFilter && this.whitelabelFilterIdGetter) {
            const filter = this.whitelabelFilterIdGetter();
            if (filter) {
                queryParams = { ...queryParams, tag_filter: filter };
            }
        }

        const queryParamsString = Object.keys(queryParams).reduce((acc, key, i) => {
            const prefix = i > 0 ? "&" : "?";
            const value = queryParams[key];
            const values = Array.isArray(value) ? value : [value];
            let pairs = [];
            if (options.listFormat === "comma") {
                const encodedValues = values.map((v) => this.getParamEncoded((v ?? "").toString()));
                pairs = [`${encodeURIComponent(key)}=${encodedValues.join(",")}`];
            } else {
                pairs = values.map((v) =>
                    key !== "cursor"
                        ? `${encodeURIComponent(key)}=${this.getParamEncoded((v ?? "").toString())}`
                        : `${key}=${value}`
                );
            }

            return `${acc}${prefix}${pairs.join("&")}`;
        }, "");

        const endpoint = this.getEndpoint(parts);
        const url = `${endpoint}/${queryParamsString}`;

        if (url.length > this.URL_WARNING_LENGTH) {
            Sentry.captureMessage(
                `Url created with length ${url.length} characters might be to long for the api to handle. Url was ${url}.`,
                Sentry.Severity.Warning
            );
        }

        return url;
    }

    public hydrateBody<T extends IGenericApiResponse, F>(
        res: T,
        hydrateFn: (body: T["body"]) => F
    ) {
        return Object.assign({}, res, { body: hydrateFn(res.body) });
    }

    public async post<T extends IGenericApiResponse>(
        path: string,
        body: ObjectOf<any> | ObjectOf<any>[] | FormData | null,
        options: FetchRequestInit = this.defaultOptions
    ) {
        const defaultOptions = {
            method: "POST",
            body,
        };
        const nextOptions = Object.assign({}, defaultOptions, options);
        return this.fetch<T>(path, nextOptions);
    }

    public async get<T extends IGenericApiResponse>(
        path: string,
        options: FetchRequestInit = this.defaultOptions
    ) {
        const nextOptions = Object.assign({}, options);
        return this.fetch<T>(path, nextOptions);
    }

    public async options<T extends IGenericApiResponse>(
        path: string,
        options: FetchRequestInit = this.defaultOptions
    ) {
        const defaultOptions = {
            method: "OPTIONS",
        };
        const nextOptions = Object.assign({}, defaultOptions, options);
        return this.fetch<T>(path, nextOptions);
    }

    public async patch<T extends IGenericApiResponse>(
        path: string,
        body: ObjectOf<any> | ObjectOf<any>[] | FormData,
        options: FetchRequestInit = this.defaultOptions
    ) {
        const defaultOptions = {
            method: "PATCH",
            body,
        };
        const nextOptions = Object.assign({}, defaultOptions, options);
        return this.fetch<T>(path, nextOptions);
    }

    public async delete<T extends IGenericApiResponse>(
        path: string,
        options: FetchRequestInit = this.defaultOptions
    ) {
        const defaultOptions = {
            method: "DELETE",
        };
        const nextOptions = Object.assign({}, defaultOptions, options);
        return this.fetch<T>(path, nextOptions);
    }

    private parseBody(response: Response): Promise<any> {
        const contentType = response.headers.get("content-type") || "no-content-type-provided";
        switch (contentType.split(";")[0]) {
            case "application/pdf":
                return this.responseToBlob(response);
            case "application/json":
                return this.responseToJson(response);
            default:
                return response.text();
        }
    }

    public fetch<T extends IGenericApiResponse>(input: string | Request, init: FetchRequestInit) {
        const request = new Request(input);
        return new Promise<T>((resolve, reject) => {
            const inner = async () => {
                try {
                    if (init.rawRequest) {
                        const response = await this._fetch(request, init);

                        if (response.status >= 400) {
                            reject(new RequestError(await this.parseBody(response), response));
                            return;
                        }

                        resolve({
                            status: response.status,
                            body: await this.parseBody(response),
                        } as T);
                    } else {
                        const response = await this._fetch(request, this.decorateRequest(init));

                        if (response.status >= 500) {
                            this.callCatastrophicFailureHandler();
                        } else if (
                            response.status === 401 &&
                            !this.responseHasExpectedStatus(response, init)
                        ) {
                            if (this.isRefreshingToken) {
                                enqueueFetch();
                                return;
                            }
                            if (this.canRefreshToken) {
                                this.refreshToken(); // do not await to release requestQueue request (should not matter though)
                                enqueueFetch();
                                return;
                            }
                            this.callUnauthorizedFailureHandler();
                        } else if (response.status >= 400 && init.throwOnNon2xx) {
                            this.callRequestFailureHandler();
                            reject(new RequestError(await this.parseBody(response), response));
                            return;
                        } else if (
                            response.status > 401 &&
                            !this.responseHasExpectedStatus(response, init)
                        ) {
                            this.callRequestFailureHandler();
                        }

                        resolve({
                            status: response.status,
                            body: await this.parseBody(response),
                        } as T);
                    }
                } catch (e) {
                    if (e instanceof TypeError) {
                        this.callCatastrophicFailureHandler();
                    }
                    reject(e);
                }
            };
            const enqueueFetch = () => this.requestQueue.enqueueRequest(inner);
            enqueueFetch();
        });
    }

    private async refreshToken() {
        const token = this.token.refreshToken;
        if (!token) {
            this.callUnauthorizedFailureHandler();
            return;
        }
        this.requestQueue.pause();
        const authToken = await this.refreshTokenCallback(token);
        if (authToken === undefined) {
            this.callUnauthorizedFailureHandler();
            return;
        }
        this.token.set(authToken);
        this.requestQueue.resume();
    }
    private get isRefreshingToken() {
        return this.requestQueue.paused;
    }
    private get canRefreshToken() {
        return this.token.refreshToken !== undefined;
    }

    private async responseToBlob(response: Response): Promise<Blob> {
        try {
            return await response.blob();
        } catch (e) {
            debug("Could not parse response as a blob.");
            throw e;
        }
    }

    private async responseToJson(response: Response): Promise<ObjectOf<any>> {
        try {
            const json = await response.json();
            return toCamelCase(json);
        } catch (e) {
            debug("Could not parse response as json.");
            throw e;
        }
    }

    private callRequestFailureHandler() {
        debug("fetch failure");
        if (this.requestFailureHandler) {
            debug("calling failure handler");
            this.requestFailureHandler();
        } else {
            debug("no failure handler defined");
        }
    }

    private callCatastrophicFailureHandler() {
        debug("🐈astrophic fetch failure");
        if (this.catastrophicFailureHandler) {
            debug("calling catastrophoic failure handler");
            this.catastrophicFailureHandler();
        } else {
            debug("no catastrophoic failure handler defined");
        }
    }

    private callUnauthorizedFailureHandler() {
        debug("unauthorized fetch failure");
        if (this.unauthorizedFailureHandler) {
            debug("calling unauthorized failure handler");
            this.unauthorizedFailureHandler();
        } else {
            debug("no unauthorized failure handler defined");
        }
    }

    private decorateRequest(init: RequestInit): RequestInit {
        const headers = new Headers(init.headers || {});

        if (this.token.accessToken) {
            headers.append("Authorization", `Bearer ${this.token.accessToken}`);
        }

        if (init.body) {
            if (init.body instanceof FormData) {
                init.body = formDataToSnakeCase(init.body);
            } else if (typeof init.body === "object") {
                headers.append("Content-Type", "application/json");
                init.body = JSON.stringify(toSnakeCase(init.body));
            } else {
                headers.append("Content-Type", "application/json");
            }
        }

        headers.append("tmplcontext", "MANAGER");
        headers.append("Accept-Language", window.__mngrLocale__.code ?? "");

        return { ...init, headers };
    }

    private responseHasExpectedStatus(response: Response, init: FetchRequestInit) {
        let expectedStatus = init.expectedStatus ?? this.DEFAULT_EXPECTED_STATUS;

        if (expectedStatus.length === 0) {
            const joinedStatuses = this.DEFAULT_EXPECTED_STATUS.join(", ");
            Sentry.captureMessage(
                `No expected status defined for ${init.method} ${response.url}. The expected status will be 
                assumed to be one of ${joinedStatuses} as defined by \`DEFAULT_EXPECTED_STATUS\`, 
                otherwise all responses would be treated as an error.`,
                Sentry.Severity.Warning
            );
            expectedStatus = this.DEFAULT_EXPECTED_STATUS;
        }

        return expectedStatus.includes(response.status);
    }

    private getEndpoint(parts: (string | number)[]) {
        parts = parts.filter((part) => part != null);

        let endpoint = parts.join("/");

        // If the url already has a protocol (i.e. is a complete url), we skip
        // prefixing it with `this.baseUrl`. This is necessary to support
        // accessing the public api which some services do.
        if (hasProtocol(endpoint)) {
            return endpoint;
        }

        if (endpoint) {
            return [this.baseUrl, endpoint].join("/");
        } else {
            return this.baseUrl;
        }
    }
}
