import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";

import { IRealEstateRead } from "src/services/services/real-estate-service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

export interface IBuildingBase {
    uuid: string;
    title: string;
    organizationNumber: string;
    mainEntranceCount: number;
    realEstate: string | null | IRealEstateRead;
    created: string;
    updated: string;
}

export type IBuildingRead = Collapse<IBuildingBase, "realEstate">;
export type IBuildingDetailsRead = Expand<IBuildingBase, "realEstate">;

export interface IBuildingCreate {
    title: string;
}

export interface IBuildingUpdate {
    uuid: string;
    title?: string;
    realEstate?: string | null;
}

export type IBuildingUpsert = IBuildingUpdate | IBuildingCreate;

export type IBuildingUpsertError = ValidationError<IBuildingUpdate>;

export interface IBuildingDeleteError {
    building: string[];
}

export class BuildingService extends Service {
    public retrieve(id: string) {
        const params: IQueryParams = { expand: "real_estate" };

        const url = this.client.url(["api", "v1", "buildings", id]);
        return this.client.get<
            | IApiResponse<IBuildingDetailsRead, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url, params);
    }

    public create(data: IBuildingCreate) {
        const url = this.client.url(["api", "v1", "buildings"]);
        return this.client.post<
            | IApiResponse<Collapse<IBuildingDetailsRead, "realEstate">, HttpStatus.Created>
            | IApiResponse<IBuildingUpsertError, HttpStatus.BadRequest>
        >(url, data);
    }

    public update(data: IBuildingUpdate) {
        const url = this.client.url(["api", "v1", "buildings", data.uuid]);
        return this.client.patch<
            | IApiResponse<Collapse<IBuildingDetailsRead, "realEstate">, HttpStatus.Ok>
            | IApiResponse<IBuildingUpsertError, HttpStatus.BadRequest>
        >(url, data);
    }

    public delete(id: string) {
        const url = this.client.url(["api", "v1", "buildings", id]);
        return this.client.delete<
            | IApiResponse<unknown, HttpStatus.Ok>
            | IApiResponse<IBuildingDeleteError, HttpStatus.BadRequest>
        >(url);
    }

    public upsert(data: IBuildingUpsert) {
        if ("uuid" in data) {
            return this.update(data);
        } else {
            return this.create(data);
        }
    }

    public list(params?: IPaginationStoreParams) {
        params = { ...params, expand: "real_estate" };

        const url = this.client.url(["api", "v1", "buildings"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponse<IPaginatedResponseV1<IBuildingDetailsRead>, HttpStatus.Ok>
        >(url);
    }

    public search(query: string, params: IPaginationStoreParams) {
        params = { ...params, search: query, expand: "real_estate" };

        const url = this.client.url(["api", "v1", "buildings"], params);
        return this.client.get<
            IApiResponse<IPaginatedResponseV1<IBuildingDetailsRead>, HttpStatus.Ok>
        >(url);
    }
}
