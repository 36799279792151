import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { ICommunityRead } from "src/services/services/community-service";
import { IGuestDetailsRead } from "src/services/services/guests-service";
import { IIssueMessageRead } from "src/services/services/issue-messages-service";
import { IIssueRead } from "src/services/services/issue-service";
import { IPublicCardCommentV1Read } from "src/services/services/public-card-comment-service";
import { IPublicCardV1Read } from "src/services/services/public-card-service";
import { IResourceRead } from "src/services/services/resource-service";
import { IUserProfileRead, IUserRead } from "src/services/services/user-service";
import { IWordMonitoringConfigRead } from "src/services/services/word-monitoring-configs-service";
import { ContentModel } from "src/types/content-model";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export enum WordMonitoringAlertStatus {
    Open = "open",
    Completed = "completed",
    Aborted = "aborted",
}
export interface IContentType<T = ContentModel> {
    appLabel: string;
    model: T;
    id: number;
}

export interface IWordMonitoringAlertBase {
    uuid: string;
    community: string | ICommunityRead;
    user: string | IUserRead;
    matchedConfig: string | IWordMonitoringConfigRead;
    matchedWords: string[];
    matchedObject:
        | string
        | IPublicCardV1Read
        | IPublicCardCommentV1Read
        | IIssueRead
        | IIssueMessageRead
        | IUserRead /* Actually SSOUser - Add specific interface if needed */
        | IUserProfileRead
        | IGuestDetailsRead
        | IResourceRead
        | null;
    matchedField:
        | "company"
        | "comment"
        | "company_role"
        | "competences"
        | "description"
        | "first_name"
        | "last_name"
        | "location"
        | "message"
        | "misc_description"
        | "phone"
        | "phone_swish"
        | "professional_info"
        | "profile_bio"
        | "title"
        | "description"
        | "name";
    contentType: number | IContentType;
    objectId: number;
    status: WordMonitoringAlertStatus;
    text: string;
    textExcerpt: string;
    created: string;
    updated: string;
    unread: boolean;
}

export type IWordMonitoringAlertRead = ExpandCollapse<
    IWordMonitoringAlertBase,
    "matchedObject",
    "user" | "contentType"
>;

export interface IWordMonitoringAlertPublicCardDetailsRead
    extends Expand<IWordMonitoringAlertBase, "user" | "community" | "matchedObject"> {
    contentType: IContentType<ContentModel.PublicCard>;
    matchedObject: null | IPublicCardV1Read;
    matchedField: "title" | "description";
}

export interface IWordMonitoringAlertPublicCardCommentDetailsRead
    extends Expand<IWordMonitoringAlertBase, "user" | "community" | "matchedObject"> {
    contentType: IContentType<ContentModel.CardComment>;
    matchedObject: null | IPublicCardCommentV1Read;
    matchedField: "comment";
}

export interface IWordMonitoringAlertIssueDetailsRead
    extends Expand<IWordMonitoringAlertBase, "user" | "community" | "matchedObject"> {
    contentType: IContentType<ContentModel.Issue>;
    matchedObject: null | IIssueRead;
    matchedField: "title" | "description" | "location" | "misc_description";
}

export interface IWordMonitoringAlertIssueMessageDetailsRead
    extends Expand<IWordMonitoringAlertBase, "user" | "community" | "matchedObject"> {
    contentType: IContentType<ContentModel.IssueMessage>;
    matchedObject: null | IIssueMessageRead;
    matchedField: "message";
}

export interface IWordMonitoringAlertSSOUserDetailsRead
    extends Expand<IWordMonitoringAlertBase, "user" | "community" | "matchedObject"> {
    contentType: IContentType<ContentModel.SSOUser>;
    matchedObject: null | IUserRead;
    matchedField: "first_name" | "last_name" | "phone";
}

export interface IWordMonitoringAlertPersonDetailsRead
    extends Expand<IWordMonitoringAlertBase, "user" | "community" | "matchedObject"> {
    contentType: IContentType<ContentModel.Person>;
    matchedObject: null | IUserProfileRead;
    matchedField:
        | "phone_swish"
        | "profile_bio"
        | "competences"
        | "company"
        | "company_role"
        | "professional_info";
}

export interface IWordMonitoringAlertGuestDetailsRead
    extends Expand<IWordMonitoringAlertBase, "user" | "community" | "matchedObject"> {
    contentType: IContentType<ContentModel.Guest>;
    matchedObject: null | IGuestDetailsRead;
    matchedField: "comment";
}

export interface IWordMonitoringAlertResourceDetailsRead
    extends Expand<IWordMonitoringAlertBase, "user" | "community" | "matchedObject"> {
    contentType: IContentType<ContentModel.Resource>;
    matchedObject: null | IResourceRead;
    matchedField: "name" | "description";
}

export type IWordMonitoringAlertDetailsRead =
    | IWordMonitoringAlertPublicCardDetailsRead
    | IWordMonitoringAlertPublicCardCommentDetailsRead
    | IWordMonitoringAlertIssueDetailsRead
    | IWordMonitoringAlertIssueMessageDetailsRead
    | IWordMonitoringAlertSSOUserDetailsRead
    | IWordMonitoringAlertPersonDetailsRead
    | IWordMonitoringAlertGuestDetailsRead
    | IWordMonitoringAlertResourceDetailsRead;

export interface IWordMonitoringAlertUpsert {
    status: WordMonitoringAlertStatus;
    unread: boolean;
}

export type IWordMonitoringAlertUpsertError = ValidationError<IWordMonitoringAlertUpsert>;

export class WordMonitoringAlertsService extends Service {
    public async list(params: IQueryParams) {
        params = {
            ...params,
            expand: ["user", "content_type"],
        };

        const url = this.client.url(["api", "v1", "word-monitoring-alerts"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IWordMonitoringAlertRead>;
            }>
        >(url);
    }

    public async retrieve(uuid: string, params?: IQueryParams) {
        params = {
            ...params,
            expand: ["user", "community", "matched_object", "content_type"],
        };

        const url = this.client.url(["api", "v1", "word-monitoring-alerts", uuid], params);

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IWordMonitoringAlertDetailsRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async update(uuid: string, body: Partial<IWordMonitoringAlertUpsert>) {
        const params = {
            expand: ["user", "community"],
        };

        const url = this.client.url(["api", "v1", "word-monitoring-alerts", uuid], params);

        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IWordMonitoringAlertDetailsRead;
                [HttpStatus.BadRequest]: IWordMonitoringAlertUpsertError;
            }>
        >(url, body);
    }
}
