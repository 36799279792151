import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface ILibraryCategoryRead {
    uuid: string;
    /**
     * The name of the category.
     * The category will be translated based on the client locale
     */
    name: string | null;
    parent: string | null;
    children: string[];
    position: number;
    permissions: string[];
}

export type ILibraryRootCategoryRead = Omit<ILibraryCategoryRead, "parent"> & { parent: null };

export type ILibraryCategoryCreate = Pick<ILibraryCategoryRead, "name" | "parent" | "position">;

export type ILibraryCategoryUpdate = Partial<ILibraryCategoryCreate>;

/**
 * service to fetch library categories (directories in the library view)
 */
export class LibraryCategoryService extends Service {
    public readonly list = (params?: IQueryParams) => {
        const url = this.client.url(["api", "v1", "library-categories"], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ILibraryCategoryRead>;
                [HttpStatus.Forbidden]: IGenericApiError;
            }>
        >(url);
    };

    public readonly all = this.createListTraverser(this.list);

    public retrieve(uuid: string) {
        const url = this.client.url(["api", "v1", "library-categories", uuid]);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: ILibraryCategoryRead;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.Forbidden]: IGenericApiError;
            }>
        >(url);
    }

    public create(data: ILibraryCategoryCreate) {
        const url = this.client.url(["api", "v1", "library-categories"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: ILibraryCategoryRead;
                [HttpStatus.BadRequest]: ValidationError<ILibraryCategoryCreate>;
                [HttpStatus.Forbidden]: IGenericApiError;
            }>
        >(url, data);
    }

    public update(uuid: string, data: ILibraryCategoryUpdate) {
        const url = this.client.url(["api", "v1", "library-categories", uuid]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: ILibraryCategoryRead;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.Forbidden]: IGenericApiError;
                [HttpStatus.BadRequest]: ValidationError<ILibraryCategoryUpdate>;
            }>
        >(url, data);
    }

    public delete(uuid: string) {
        const url = this.client.url(["api", "v1", "library-categories", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.Forbidden]: IGenericApiError;
            }>
        >(url);
    }
}
