import { makeObservable, action, observable, override, computed } from "mobx";

import {
    IPaginationServiceEndpoint,
    IPaginationStoreParams,
    PaginationStore,
} from "./pagination-store";

export enum PaginationSortOrder {
    Asc = "asc", // SortKey
    Desc = "desc", // -SortKey
}

export interface ISorting<SortingName> {
    sortingParam: SortingName;
    sortOrder: PaginationSortOrder;
}
const sortingToQueryParam = <SortingName>({
    sortingParam,
    sortOrder,
}: ISorting<SortingName>): string => {
    const prefix = sortOrder === PaginationSortOrder.Desc ? "-" : "";
    return prefix + sortingParam;
};

export class SortedPaginationStore<
    T,
    SortingName extends string = string
> extends PaginationStore<T> {
    private readonly PARAM_SORT = "sort";

    @observable
    private _sorting: ISorting<SortingName>[] = [];

    @computed
    public get sorting(): readonly Readonly<ISorting<SortingName>>[] {
        return this._sorting;
    }

    public constructor(
        serviceEndpoint: IPaginationServiceEndpoint<T>,
        public readonly sortableNames: readonly SortingName[],
        private defaultSort: undefined | ISorting<SortingName> = undefined,
        defaultParams: IPaginationStoreParams = {
            page_size: PaginationStore.DEFAULT_PAGE_SIZE,
        }
    ) {
        super(serviceEndpoint, defaultParams);
        makeObservable(this);
        this._sorting = defaultSort ? [defaultSort] : [];
    }

    public findActiveSorting(sortName: SortingName) {
        return this._sorting.find(({ sortingParam }) => sortingParam === sortName);
    }

    @override
    public async getInitial() {
        const sorting = this._sorting.map(sortingToQueryParam).join(",");
        this.setParam(this.PARAM_SORT, sorting);
        await super.getInitial();
    }

    @action
    public async toggleSingleSorting(sortingName: SortingName) {
        const sorting = this.findActiveSorting(sortingName);
        if (!sorting) {
            this._sorting = [
                {
                    sortingParam: sortingName,
                    sortOrder: PaginationSortOrder.Asc,
                },
            ];
        } else if (sorting.sortOrder === PaginationSortOrder.Asc) {
            this._sorting = [
                {
                    sortingParam: sortingName,
                    sortOrder: PaginationSortOrder.Desc,
                },
            ];
        } else {
            // Necessary to be able to change column order for default sort (if its displayed in table)
            if (sortingName === this.defaultSort?.sortingParam) {
                this._sorting = [
                    {
                        sortingParam: sortingName,
                        sortOrder: PaginationSortOrder.Asc,
                    },
                ];
            } else {
                this._sorting = this.defaultSort ? [{ ...this.defaultSort }] : [];
            }
        }

        await this.getInitial();
    }

    @action
    public async applySorting(sortingParam: SortingName) {
        this._sorting = [...this._sorting, { sortingParam, sortOrder: PaginationSortOrder.Desc }];
        await this.getInitial();
    }

    public async moveSortingToSiblingByValue(sorting: SortingName, sibling: SortingName) {
        const indexOf = (value: SortingName) =>
            this._sorting.findIndex(({ sortingParam }) => sortingParam === value);
        this.reorderSorting(indexOf(sorting), indexOf(sibling));
        await this.getInitial();
    }

    @action
    public async reorderSorting(from: number, to: number) {
        const isValid = (index: number) => 0 <= index && index < this._sorting.length;
        if (from === to) {
            return;
        }
        if (!isValid(from) || !isValid(to)) {
            return;
        }
        this._sorting.splice(to, 0, ...this._sorting.splice(from, 1));
        await this.getInitial();
    }

    @action
    public async removeSortingAt(index: number) {
        this._sorting.splice(index, 1);
        await this.getInitial();
    }

    @action
    public async clearSorting(noReload?: boolean) {
        this._sorting = [];
        if (noReload) {
            return;
        }
        await this.getInitial();
    }

    @action
    public async updateAt(index: number, sorting: Partial<ISorting<SortingName>>) {
        this._sorting[index] = {
            ...this._sorting[index],
            ...sorting,
        };
        await this.getInitial();
    }

    public get unsorted() {
        return this.sortableNames.filter(
            (sort) => !this._sorting.some(({ sortingParam }) => sortingParam === sort)
        );
    }
}
