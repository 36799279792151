import deepEqual from "fast-deep-equal/es6";
import { action, computed, observable, toJS } from "mobx";

import { deepCopy } from "src/lib/object";
import { storeItemToJSON } from "src/lib/store-item-to-json";
import { translate } from "src/lib/translations";
import { ITag } from "src/services/services/types/tag";

interface IFileInputChangeEvent {
    target: { files: FileList };
}

type Event<Detail> =
    | CustomEvent<Detail>
    | React.FormEvent<HTMLInputElement>
    | IFileInputChangeEvent
    | React.FormEvent<HTMLTextAreaElement>;

type EventCallBack<T> = (event: Event<T>) => void;

/**
 * Allowed type of keys in the form data tree
 */
export type DataTreeKey = number | string | symbol;

/**
 * A part of the form data errors tree
 */
type ErrorTreePart = string[] | { [key: string | symbol]: ErrorTreePart };

export function isFileEvent<T>(event: Event<T>): event is IFileInputChangeEvent {
    if (event instanceof CustomEvent) {
        return false;
    }
    return isFileListLike((event as IFileInputChangeEvent).target.files);
}

function isFileListLike(fileList: any) {
    return (
        fileList != null &&
        Symbol.iterator in fileList &&
        Array.from(fileList).every((file) => file instanceof File)
    );
}

export interface ISelectableTagsForm {
    data: { tags: ITag[] };
}

const zoomTo = (path: any[]) => (data: any) => {
    const get = (obj: any, segment: any) => {
        if (obj === undefined) {
            return obj;
        }
        if (!obj) {
            // Falsy objects cannot be indexed
            return;
        }
        if (obj instanceof Map) {
            return obj.get(segment);
        }
        return obj[segment];
    };
    return path.reduce(get, data);
};

const sortByDuckId = (value: any[]) =>
    value.slice(0).sort((a, b) => {
        if (Object(a) === a && Object(b) === b) {
            return a.id - b.id;
        }
        return a - b;
    });

const unpackEvent = <Detail>(event: Event<Detail>): any => {
    if (event instanceof CustomEvent) {
        return event.detail;
    } else if (isFileEvent(event)) {
        return event.target.files[0];
    }
    const currentTarget = event.currentTarget;
    if (currentTarget.type === "checkbox") {
        if ("checked" in currentTarget) {
            return currentTarget.checked;
        }
    } else if (currentTarget.type === "number") {
        const number = parseFloat(currentTarget.value);
        if (!Number.isNaN(number)) {
            return number;
        }
    }
    return currentTarget.value;
};

export abstract class AbstractForm<
    Data,
    Errors extends ValidationError<Data> = ValidationError<Data>
> {
    @observable
    private _errors?: Errors;

    @observable
    public abstract data: Data;

    @observable
    protected frozenData: Data | undefined;

    // Note: Only type-safe at step 1 if using a path
    public set<DataKey extends keyof Data>(key: DataKey): EventCallBack<Data[DataKey]>;
    public set<DataKey extends keyof Data>(key: DataKey, value: Data[DataKey]): void;
    public set(key: [keyof Data, ...DataTreeKey[]]): EventCallBack<any>;
    public set(key: [keyof Data, ...DataTreeKey[]], value: any): void;
    @action.bound
    public set<DataKey extends keyof Data>(
        key: DataKey | [keyof Data, ...DataTreeKey[]],
        value?: Data[DataKey]
    ): EventCallBack<Data[DataKey]> | void {
        const keys = Array.isArray(key) ? key : [key];
        if (typeof value === "undefined") {
            return (event) => this.setValue(keys, unpackEvent(event));
        } else {
            this.setValue(keys, value);
            return;
        }
    }

    @action
    private setValue(keys: DataTreeKey[], value: any) {
        let obj = this.data;
        for (const i in keys) {
            if (typeof obj === "undefined") {
                throw new Error(
                    `Tried to change value for key ${JSON.stringify(
                        keys
                    )} but obj was undefined when trying to get ${keys[i].toString()}.`
                );
            } else if (keys.length === +i + 1) {
                obj[keys[i]] = value;
            } else {
                obj = obj[keys[i]];
            }
        }
    }

    @action.bound
    public setAll(obj: Partial<Data>): void {
        for (const key of Object.keys(obj)) {
            this.data[key] = obj[key];
        }
    }

    public get<K extends keyof Data>(key: K) {
        return this.data[key];
    }

    @action
    protected reset(emptyData: Data) {
        this.data = emptyData;
        this.clearErrors();
        this.frozenData = undefined;
    }

    @action.bound
    public resetField(field: keyof Data) {
        if (!this.frozenData) {
            throw new Error("Can't reset field without frozen data. Call to freeze() missing.");
        }

        this.removeError(field);
        this.set(field, this.frozenData[field]);
    }

    @action.bound
    public resetAllFields(fields: Array<keyof Data>) {
        for (const field of fields) {
            this.resetField(field);
        }
    }

    @action.bound
    public clear<K extends OptionalPropertyNames<Data>>(
        key: K,
        curried?: boolean
    ): EventCallBack<any> | undefined {
        if (typeof curried !== "undefined" && curried) {
            return action(() => {
                // @ts-ignore
                this.data[key] = undefined;
            });
        } else {
            // @ts-ignore
            this.data[key] = undefined;
            return;
        }
    }

    @computed
    public get errors() {
        return this._errors || ({} as Errors);
    }

    // Note: Only type-safe at step 1 if using a path
    public error(path: keyof Errors | [keyof Errors, ...DataTreeKey[]]) {
        if (!this._errors) {
            return;
        }

        const segments: DataTreeKey[] = Array.isArray(path) ? path : [path];

        let currentError: ErrorTreePart = this._errors;
        let found: ErrorTreePart | undefined;

        for (const step of segments) {
            if (currentError && typeof currentError[step] !== "undefined") {
                currentError = currentError[step];
                found = currentError;
            } else {
                found = undefined;
                break;
            }
        }

        return found && this.collectErrors(found)?.map((error) => translate(error));
    }

    public valid() {
        if (!this._errors) {
            return true;
        }

        return Object.keys(this._errors).length === 0;
    }

    public invalid() {
        return !this.valid();
    }

    @action.bound
    public clearErrors() {
        this._errors = undefined;
    }

    @action.bound
    protected addError(key: keyof Errors, value: string) {
        if (!this._errors) {
            this._errors = { [key]: undefined } as Errors;
        }

        const errors = this._errors[key];
        if (typeof errors === "undefined") {
            this._errors[key] = [value] as any;
        } else {
            if (Array.isArray(errors)) {
                errors.push(value as any);
            }
        }
    }

    @action.bound
    protected removeError(key: keyof Errors) {
        if (!this._errors) {
            return;
        }
        this._errors[key] = undefined as Errors[keyof Errors];
    }

    @action.bound
    protected addErrors(key: keyof Errors, values?: string[]) {
        (values || []).forEach((value) => {
            this.addError(key, value);
        });
    }

    @action.bound
    // Note: Only type-safe at step 1 if using a path
    protected setErrorsOnPath(path: [keyof Errors, ...DataTreeKey[]], errorObject: ObjectOf<any>) {
        if (!this._errors) {
            this._errors = {} as Errors;
        }
        let errors: ErrorTreePart = this._errors;
        path.forEach((step: DataTreeKey, i) => {
            if (typeof errors[step] === "undefined") {
                const isLast = path.length === i + 1;
                if (!isLast) {
                    errors[step] = {};
                } else {
                    errors[step] = errorObject;
                }
            }
            errors = errors[step];
        });
    }

    @action.bound
    protected setErrors(errors: Errors) {
        this._errors = errors;
    }

    private collectErrors(errors: ErrorTreePart | undefined): undefined | string[] {
        if (!errors) {
            return undefined;
        }

        if (Array.isArray(errors) && typeof errors[0] === "string") {
            return errors;
        }

        return Object.values(errors)
            .map((nestedErrors) => this.collectErrors(nestedErrors))
            .flat()
            .filter(Boolean) as string[];
    }

    @action.bound
    protected assert(condition: any, key: keyof Errors, value: string) {
        if (!condition) {
            this.addError(key, value);
        }
    }

    protected assertIsSet(key: keyof Data, value: string) {
        this.assert(this.data[key], key, value);
    }

    @action.bound
    public validate() {
        this.clearErrors();
        this.validator();
        return this.valid();
    }

    @action.bound
    public freeze(fields?: (keyof Data)[]) {
        if (fields) {
            const tempData = this.frozenData ? deepCopy(this.frozenData) : ({} as Data);

            fields.forEach(
                (property) => (tempData[property] = deepCopy(toJS(this.data[property])))
            );

            this.frozenData = tempData;
        } else {
            this.frozenData = deepCopy(toJS(this.data));
        }
    }

    protected calculateHasChanged(frozenData: Data, data: Data) {
        return !deepEqual(frozenData, data);
    }

    /**
     * Returns if any of the given fields have changed
     *
     * @param fields
     * @returns
     */
    public hasAnyChanged(fields: (keyof Data)[]) {
        // Note: this doesn't accept a path currently, please add if needed.
        return fields.some((field) => this.hasChanged(field));
    }

    /**
     * Returns if any fields have changed not counting those given as argument.
     *
     * @param exceptFields Fields to exclude from checking
     * @returns if any fields not in exceptFields has changed
     */
    public hasOtherChanged(exceptFields: (keyof Data)[]) {
        // Note: this doesn't accept a path currently, please add if needed.
        if (!this.frozenData) {
            return false;
        }

        const allFields = Object.keys(this.frozenData) as (keyof Data)[];

        return this.hasAnyChanged(allFields.filter((field) => !exceptFields.includes(field)));
    }

    public hasChanged(): boolean;
    public hasChanged(selector: (data: Data) => any): boolean;
    public hasChanged(...path: [keyof Data, ...DataTreeKey[]]): boolean;
    public hasChanged(headOrSelector?: ((data: Data) => any) | keyof Data, ...path: DataTreeKey[]) {
        if (this.frozenData === undefined) {
            return false;
        }

        if (headOrSelector === undefined) {
            return this.calculateHasChanged(toJS(this.frozenData), toJS(this.data));
        } else if (typeof headOrSelector === "function") {
            return !deepEqual(
                headOrSelector(toJS(this.frozenData)),
                headOrSelector(toJS(this.data))
            );
        } else {
            const get = zoomTo([headOrSelector, ...path]);
            const frozenData = get(toJS(this.frozenData));
            const data = get(toJS(this.data));

            if (frozenData === data) {
                return false;
            } else if (Array.isArray(frozenData) && Array.isArray(data)) {
                return !deepEqual(sortByDuckId(frozenData), sortByDuckId(data));
            } else if (Object(frozenData) === frozenData) {
                return !deepEqual(frozenData, data);
            } else {
                return true;
            }
        }
    }

    protected validator(): void {
        // no-op
    }

    public toJSON() {
        return storeItemToJSON(this);
    }
}
