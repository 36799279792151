import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { ITag } from "src/services/services/types/tag";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

import { ILibraryFileRead } from "./library-file-service";
import { ILibraryHtmlPageRead } from "./library-html-page-service";
import { ILibraryHyperlinkRead } from "./library-hyperlink-service";
import { ILibraryEntryBase } from "./types/library-entry-base";

export enum LibraryContentType {
    File = "file",
    HtmlPage = "htmlpage",
    Hyperlink = "hyperlink",
}

export interface ILibraryEntryReadBase<T extends LibraryContentType> extends ILibraryEntryBase {
    contentType: T;
    objectUuid: string;
    /**
     * Url to the content of a library entry.
     * If it is a file/html-page then this will be the URL to the file/page
     * If it is a hyperlink then this will be the hyperlink provided by the user
     */
    url: string;
    tags: null | string[] | ITag[];
}

export type ILibraryEntryReadFile = ILibraryEntryReadBase<LibraryContentType.File> &
    Omit<ILibraryFileRead, "file">;
export type ILibraryEntryReadHyperLink = ILibraryEntryReadBase<LibraryContentType.Hyperlink> &
    ILibraryHyperlinkRead;
export type ILibraryEntryReadHtmlPage = ILibraryEntryReadBase<LibraryContentType.HtmlPage> &
    ILibraryHtmlPageRead;

export type ILibraryEntryRead =
    | ILibraryEntryReadFile
    | ILibraryEntryReadHyperLink
    | ILibraryEntryReadHtmlPage;

type EntryExpandables = "tags";

/**
 * service for getting library entries.
 * To create a library entry use one of:
 * - library-file-service
 * - library-hyperlink-service
 * - library-html-page-service
 */
export class LibraryEntryService extends Service {
    public list(params?: IQueryParams) {
        params = { display_in_gallery: "false", ...params };
        const url = this.client.url(["api", "v1", "library-entries"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<ILibraryEntryRead>;
            }>
        >(url);
    }

    public retrieve<Expanded extends EntryExpandables = never>(
        uuid: string,
        { expand }: { expand: Expanded[] }
    ) {
        const url = this.client.url(["api", "v1", "library-entries", uuid], {
            expand,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: ExpandCollapse<ILibraryEntryRead, EntryExpandables, Expanded>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }
}
