import { objectToFormData } from "src/lib/object-to-formdata";
import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export interface IUserAddress {
    id?: number;
    title: string;
    firstName: string;
    lastName: string;
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    state: string;
    postcode: string;
}

export class UserAddresses extends Service {
    public list(params: IQueryParams) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "user-addresses"], params);
        return this.client.get<
            | IApiResponse<IPaginatedBody<IUserAddress>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public update(resource: Partial<IUserAddress>, userAddressID: number) {
        const url = this.client.url([
            "api",
            "v1",
            "ecommerce",
            "v2",
            "user-addresses",
            userAddressID,
        ]);
        return this.client.patch<
            | IApiResponse<IUserAddress, HttpStatus.Ok>
            | IApiResponse<ValidationError<IUserAddress>, HttpStatus.BadRequest>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url, objectToFormData(resource));
    }
}
