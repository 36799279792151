import { API_CORE_PUBLIC_URL } from "src/config/environment";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";

export interface IResetPasswordConfirmationCreate {
    email: string;
    token: string;
    newPassword: string;
    reNewPassword: string;
}

/**
 * Error messages are under the `error` key instead of at the root for this
 * particular endpoint.
 */
type IResetPasswordConfirmationCreateError = {
    error: ValidationError<IResetPasswordConfirmationCreate>;
};

export class ResetPassworConfirmationService extends Service {
    public async create(data: IResetPasswordConfirmationCreate) {
        const url = this.client.url([
            API_CORE_PUBLIC_URL,
            "api",
            "v1",
            "auth",
            "reset-password-confirm",
            data.email,
            data.token,
        ]);

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.NoContent]: never;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.Unauthorized]: IGenericApiError;
                [HttpStatus.BadRequest]: IResetPasswordConfirmationCreateError;
            }>
        >(url, data, {
            expectedStatus: [
                HttpStatus.NoContent,
                HttpStatus.NotFound,
                HttpStatus.Unauthorized,
                HttpStatus.BadRequest,
            ],
        });
    }
}
