import { IApiResponseMap, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface IIssueCategoryConfiguration {
    uuid: string;
    category: string;
    community: string;
    mailTo: string;
    url: string | null;
}

export interface IIssueCategoryConfigurationCreate {
    mailTo: string;
    url: string | null;
}
export type IIssueCategoryConfigurationPatch = IIssueCategoryConfigurationCreate;

export type IIssueCategoryConfigurationCreateErrors = Partial<Record<"url" | "mailTo", string[]>>;
export type IIssueCategoryConfigurationPatchErrors = IIssueCategoryConfigurationCreateErrors;

interface IIssueCategoryConfigurationListParams extends IQueryParams {
    community?: string;
}

export class IssueCategoryConfigurationService extends Service {
    public readonly list = (params?: IIssueCategoryConfigurationListParams) => {
        const url = this.client.url(["api", "v2", "issue-category-configurations"], params);
        return this.client.get<
            IApiResponseMap<{ [HttpStatus.Ok]: IPaginatedResponseV1<IIssueCategoryConfiguration> }>
        >(url);
    };
    public readonly all = this.createListTraverser(this.list);

    public create(community: string, category: string, data: IIssueCategoryConfigurationCreate) {
        const url = this.client.url(["api", "v2", "issue-category-configurations"]);
        const body = { community, category, ...data };
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IIssueCategoryConfiguration;
                [HttpStatus.BadRequest]: IIssueCategoryConfigurationCreateErrors;
            }>
        >(url, body);
    }

    public update(uuid: string, data: IIssueCategoryConfigurationPatch) {
        const url = this.client.url(["api", "v2", "issue-category-configurations", uuid]);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IIssueCategoryConfiguration;
                [HttpStatus.BadRequest]: IIssueCategoryConfigurationPatchErrors;
            }>
        >(url, data);
    }

    public delete(uuid: string) {
        const url = this.client.url(["api", "v2", "issue-category-configurations", uuid]);
        return this.client.delete<IApiResponseMap<{ [HttpStatus.NoContent]: void }>>(url);
    }
}
