export function checkNumberBoundaries(upperLimit: number, lowerLimit: number, value: number) {
    if (isNaN(upperLimit) || isNaN(lowerLimit) || isNaN(value)) {
        return NaN;
    }

    if (value > upperLimit) {
        return upperLimit;
    }
    if (value < lowerLimit) {
        return lowerLimit;
    }
    return value;
}
