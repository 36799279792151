import { IApiResponse, HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";

export interface IPublicCardLikeRead {
    uuid: string;
    firstName: string;
    lastName: string;
    profileImage: string;
}

export class PublicCardLikeService extends Service {
    public delete(cardId: string, id: string) {
        const url = this.client.url(["api", "v1", "public-cards", cardId, "likes", id]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: unknown;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, { expectedStatus: [HttpStatus.NoContent, HttpStatus.NotFound] });
    }

    public create(cardId: string) {
        const url = this.client.url(["api", "v1", "public-cards", cardId, "likes"]);
        return this.client.post<IApiResponse<IPublicCardLikeRead, HttpStatus.Created>>(url, null);
    }
}
