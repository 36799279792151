import * as Sentry from "@sentry/browser";
import Debug from "debug";
import { observable, runInAction, action } from "mobx";

import { dropClientTags } from "src/lib/tags";
import { ITagSubTypeRead } from "src/services";
import { ITag, ITagFixedSubType } from "src/services/services/types/tag";
import { observed } from "src/store/lib/observed";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

const debug = Debug("tmpl:filter-picker-store");

type ICategoryPaginators = {
    [key in string]?: {
        page: number;
        count: number;
    };
};

interface IGroupedTags {
    subType: ITagSubTypeRead;
    tags: ITag[];
}

type SearchCallback = (
    category: ITagSubTypeRead,
    query: string,
    page: number
) => Promise<IPaginatedResponseV1<ITag>>;

interface ITagPickerStoreArgs {
    initialTags?: ITag[];
    categories: Readonly<ITagSubTypeRead[]>;
    logicalRelationships?: Readonly<string[][]>;
    performSearch: SearchCallback;
}

/**
 * Controller for the TagPicker view
 */
@observed
export class TagPickerStore {
    constructor({
        initialTags,
        categories,
        logicalRelationships,
        performSearch,
    }: ITagPickerStoreArgs) {
        this.categories = categories;
        this._pickedTags = initialTags ?? [];
        this._pickedCategoriesIds = this.getPreselectedCategories();
        this.logicalRelationships = logicalRelationships ?? [];
        this.performSearch = performSearch;
    }

    @observable
    private _pickedTags: ITag[];
    public readonly categories: Readonly<ITagSubTypeRead[]>;
    public readonly logicalRelationships: Readonly<string[][]>;
    private readonly performSearch: (
        category: ITagSubTypeRead,
        query: string,
        page: number
    ) => Promise<IPaginatedResponseV1<ITag>>;

    @observable private _query = "";
    public get query() {
        return this._query;
    }

    /* istanbul ignore next */
    @action.bound
    public setQuery(query: string) {
        this._query = query;
    }

    @observable private _pickedCategoriesIds: Set<string> = new Set();

    /* istanbul ignore next */
    @action.bound
    public unpickCategory({ id }: ITagSubTypeRead) {
        this._pickedCategoriesIds.delete(id);
    }

    /* istanbul ignore next */
    @action.bound
    public pickCategory({ id }: ITagSubTypeRead) {
        this._pickedCategoriesIds.add(id);
    }

    public isCategoryPicked({ id }: ITagSubTypeRead) {
        return this._pickedCategoriesIds.has(id);
    }

    /**
     * Only preselect some categories when opening the TagPicker. Fetching all
     * categories on mount takes _a lot_ time, this a way to mitigate that.
     *
     * @returns A set of preselected categories
     */
    private getPreselectedCategories(): Set<UUID> {
        const filteredCategories = this.categories.filter(
            ({ name }) => name === ITagFixedSubType.Community
        );
        // Preselect the community tag sub type if it exists in
        // `this.categories`, otherwise preselect all categories.
        const categories = filteredCategories.length === 1 ? filteredCategories : this.categories;
        return new Set(categories.map(({ id }) => id));
    }

    @observable
    public _filter: string | undefined;
    /* istanbul ignore next */
    public get filter() {
        return this._filter;
    }
    /* istanbul ignore next */
    @action.bound
    public setFilter(filter: string) {
        this._filter = filter;
    }

    @observable
    private _tags: ITag[] = [];
    public get tags() {
        return this._tags;
    }
    public tagsOfType(type: ITagSubTypeRead) {
        return this.tags.filter((s) => s.subType === type.name);
    }

    public get pickedTags() {
        return this._pickedTags;
    }
    private tagIsPicked(tag: ITag) {
        return Boolean(this.pickedTags.find((v) => v.id === tag.id));
    }
    public pickedTagsOfType(type: ITagSubTypeRead) {
        return this.pickedTags.filter((tags) => tags.subType === type.name);
    }
    @action.bound
    public unpickTag(tag: ITag) {
        this._pickedTags = this._pickedTags.filter(({ id }) => id !== tag.id);
    }
    @action.bound
    public pickTag(tag: ITag) {
        if (!this.tagIsPicked(tag)) {
            this._pickedTags = [...this._pickedTags, tag];
        }

        this._pickedTags = dropClientTags(this._pickedTags);
    }

    @action.bound
    public setPickedTags(tags: ITag[]) {
        this._pickedTags = tags;
    }

    @observable private loadingNext: Set<string> = new Set();
    /* istanbul ignore next */
    @action
    private addLoadingNext({ id }: ITagSubTypeRead) {
        this.loadingNext.add(id);
    }
    /* istanbul ignore next */
    @action
    private removeLoadingNext({ id }: ITagSubTypeRead) {
        this.loadingNext.delete(id);
    }
    public isLoadingNext({ id }: ITagSubTypeRead) {
        return this.loadingNext.has(id);
    }

    @observable
    private categoryPaginators: ICategoryPaginators = {};

    public categoryHasMoreTagsThan(category: ITagSubTypeRead, count: number) {
        const categoryPaginator = this.categoryPaginators[category.id];
        if (categoryPaginator) {
            return categoryPaginator.count > count;
        }
        return false;
    }

    public totalCountForCategory(category: ITagSubTypeRead) {
        const categoryPaginator = this.categoryPaginators[category.id];
        if (categoryPaginator != null) {
            return categoryPaginator.count;
        }
        return null;
    }

    private queryKey() {
        return `${this.query} | ${Array.from(this._pickedCategoriesIds).join(",")}`;
    }

    @observable
    public loadingInitial = false;
    public async search() {
        runInAction(() => {
            this._tags = [];
            this.loadingInitial = true;
        });

        const queryKey = this.queryKey();
        const isLatestRequest = () => queryKey === this.queryKey();

        const pickedCategories = this.categories.filter(({ id }) =>
            this._pickedCategoriesIds.has(id)
        );

        try {
            const responses = await Promise.all(
                pickedCategories.map((category) => this.performSearch(category, this.query, 1))
            );

            if (!isLatestRequest()) {
                debug("query has changed. ignoring responses from previous query.");
                return;
            }

            responses.forEach((response, index) => {
                runInAction(() => {
                    this._tags = this._tags.concat(response.results);
                    this.categoryPaginators[pickedCategories[index].id] = {
                        page: 1,
                        count: response.count,
                    };
                });
            });
        } finally {
            if (isLatestRequest()) {
                runInAction(() => {
                    this.loadingInitial = false;
                });
            }
        }
    }

    public async next(category: ITagSubTypeRead) {
        this.addLoadingNext(category);
        try {
            const page = this.categoryPaginators[category.id]?.page ?? 1;
            const nextPage = page + 1;
            const response = await this.performSearch(category, this.query, nextPage);
            runInAction(() => {
                this._tags = this._tags.concat(response.results);
                const categoryPaginator = this.categoryPaginators[category.id];
                if (categoryPaginator != null) {
                    categoryPaginator.page = nextPage;
                } else {
                    throw new Error(`Category paginator is not set of category ${category}`);
                }
            });
        } finally {
            this.removeLoadingNext(category);
        }
    }

    public groupedPickedTags(): IGroupedTags[][] {
        return this.groupedCategories()
            .map((group) => {
                return group
                    .map((category) => ({
                        subType: category,
                        tags: this.pickedTags.filter((tag) => category.name === tag.subType),
                    }))
                    .filter((category) => category.tags.length);
            })
            .filter((group) => group.length);
    }

    private groupedCategories() {
        if (!this.logicalRelationships.length) {
            return [this.categories];
        }
        const orphanCategoriesSet = new Set(this.categories);
        const groups = this.logicalRelationships.map((rel) =>
            rel
                .map((tagSubType) => {
                    const category = this.categories.find(
                        (category) => tagSubType === category.name
                    );
                    if (category == null) {
                        Sentry.captureMessage(
                            `Got '${tagSubType}' as a tag sub type name from logical relationships \
                        but could not find the tag sub type. This is probably a misconfiguration. \
                        '${tagSubType}' will be ignored.`
                        );
                    } else {
                        orphanCategoriesSet.delete(category);
                    }
                    return category;
                })
                .filter((category): category is ITagSubTypeRead => category != null)
        );
        if (orphanCategoriesSet.size) {
            const orphanCategories = Array.from(orphanCategoriesSet);
            Sentry.captureMessage(
                `Got orphaned tagSubType(s) [${orphanCategories.join(
                    ", "
                )}]. Added as ad-hoc logical relationship`
            );
            return [...groups, orphanCategories];
        }
        return groups;
    }
}
