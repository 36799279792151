import { debounce } from "decko";
import { action, override } from "mobx";

import { localStorageToJSON, updateObjectOnKeyInLocalStorage } from "src/lib/local-storage";

import {
    IPaginationServiceEndpoint,
    IPaginationStoreParams,
    ISorting,
    PaginationStore,
    SortedPaginationStore,
} from "src/store/_generic";
import { ListFilterStore } from "src/store/list-filter-store";
import { ListOptionsStore } from "src/store/list-options-store";
import { IDataTableColumnDef } from "src/types/data-table";

export interface IPageStore {
    /**
     * Exposes number of active filters
     * Uses {@link ListFilterStore} internally
     */
    activeFilterCount: number;
    /**
     * Resets filters and reloads page
     * Uses {@link ListFilterStore} internally
     */
    resetFilter: () => Promise<void>;
    /**
     * Sets a filter and reloads page if apply is supplied (defaults to `true`)
     * Uses {@link ListFilterStore} internally
     */
    setFilter: (optionId: string, value: Partial<any>, apply?: boolean) => Promise<void>;
    /**
     * Returns applied filter from {@link ListFilterStore}
     */
    appliedFilters: Partial<any>;
    /**
     * Saves columns width to {@link ListOptionsStore} and saves
     * to localstorage
     */
    onResizeColumn: (name: string, width: number) => void;
    /**
     * Sets page size but does not reload/fetch initial
     */
    initPageSize: (value: number) => void;
}

type StoredObjectColumns<Type> = {
    [key: string]: Partial<IDataTableColumnDef<Type>> & { position: number };
};

type StoredData<Type> = {
    [key: string]: {
        columns: IDataTableColumnDef<Type>[] | StoredObjectColumns<Type>;
        pageSize: number;
    };
};

/**
 * Store to be used in combination with ListPage
 * Is set up when calling `BaseStore.sortPaginated`
 * Extends {@link PaginationStore} and exposes methods
 * from {@link ListFilterStore} and {@link ListOptionsStore}
 */
export class PageStore<Type, RowType = any, SortingName extends string = string>
    extends SortedPaginationStore<Type>
    implements IPageStore
{
    /**
     * Key used to store PageStore settings in local storage
     */
    public static readonly storageKey = "listpage-options";

    /**
     * Key used to store settings for this PageStore instance in local storage
     */
    public readonly settingsKey: string;

    private _optionStore: ListOptionsStore<RowType>;
    private _filterStore: ListFilterStore<any>;

    constructor(
        serviceEndpoint: IPaginationServiceEndpoint<Type>,
        sortableNames: readonly SortingName[],
        defaultSort: undefined | ISorting<SortingName>,
        settingsKey: string = window.location.pathname,
        defaultParams: IPaginationStoreParams = {
            page_size: PaginationStore.DEFAULT_PAGE_SIZE,
        }
    ) {
        super(serviceEndpoint, sortableNames, defaultSort, defaultParams);
        this._optionStore = new ListOptionsStore();
        this._filterStore = new ListFilterStore();
        this.settingsKey = settingsKey;
    }

    @action
    public initPageSize(value: number) {
        super.setPageSize(value);
    }

    @override
    public async setPageSize(value: number): Promise<void> {
        super.setPageSize(value);
        this.saveToLocalStorage();
        return super.getInitial();
    }

    //#region FilterStore
    public get activeFilterCount() {
        return this._filterStore.activeFilterCount;
    }

    public get appliedFilters() {
        return this._filterStore.appliedFilters;
    }

    private applyFilter() {
        this.reset();
        this.setParams(this._filterStore.params);
        return this.getInitial();
    }

    @action
    public async resetFilter() {
        const hasChanged = this._filterStore.resetFilterAndNotifyIfHasChanged();
        if (!hasChanged) return Promise.resolve();
        this.applyFilter();
    }

    @debounce
    public setFilter(optionId: string, value: any, apply?: boolean | undefined) {
        const hasChanged = this._filterStore.setFilterAndNotifyIfHasChanged(optionId, value, apply);
        if (!hasChanged) return Promise.resolve();
        return this.applyFilter();
    }

    //#endregion

    //#region Options Store

    private saveToLocalStorage() {
        updateObjectOnKeyInLocalStorage(PageStore.storageKey, this.settingsKey, {
            columns: this.columnsAsObjectByName,
            pageSize: this.pageSize,
        });
    }

    private get columnsAsObjectByName() {
        const columnsObject: StoredObjectColumns<RowType> = {};
        const columns = this.columns;
        for (let index = 0; index < columns.length; index++) {
            columnsObject[columns[index].name] = { ...columns[index], position: index };
        }
        return columnsObject;
    }

    public get columns() {
        return this._optionStore.columns;
    }

    private get storedColumns() {
        const data = localStorageToJSON<StoredData<RowType>>(PageStore.storageKey);
        if (!data) return null;
        return data[this.settingsKey]?.columns;
    }

    public setColumns(columns: IDataTableColumnDef<RowType>[]) {
        this._optionStore.setInitialColumns(columns);
        const stored = this.storedColumns;
        // If not data saved in local storage, set initial columns
        if (!stored) {
            this._optionStore.setColumns(columns);
        } else {
            // As the initial implementation of storing in localstorage saved values as array
            // we need to check what the user has stored from before.
            // As value can be both an array and an object.
            const storedValueIsArray = Array.isArray(stored);
            // Go trough each defined columns as we need to
            // use developer defined columns as truth.
            const columnsMergedWithStoredValues = columns.map((column) => {
                if (storedValueIsArray) {
                    // If we have an array, find which position in the array the
                    // stored value is. Identify column by name.
                    const storedColumnIndex = stored.findIndex(
                        (storedColumn) => column.name === storedColumn.name
                    );
                    // If no stored value we can assume it is a new column
                    // or similar, then we want the position to be the first
                    if (storedColumnIndex < 0) {
                        return { ...column, position: 0 };
                    }
                    // Otherwise return a merged column
                    // Where the index is the position and where we overwrite
                    // the header to use the translated name of the header
                    return {
                        ...column,
                        ...stored[storedColumnIndex],
                        position: storedColumnIndex,
                        header: column.header,
                    };
                } else {
                    // If the value is an object we want to directly grab
                    // the column by name as the name is the key.
                    const storedColumn = stored[column.name] ?? {};
                    return { ...column, ...storedColumn, header: column.header };
                }
            });
            // As index of array is not used we need to sort all values
            // the the position property which is essentially the index.
            columnsMergedWithStoredValues.sort((a, b) => a.position - b.position);
            this._optionStore.setColumns(columnsMergedWithStoredValues);
        }
    }

    public resetOptions() {
        this._optionStore.reset();
        if (this.pageSize !== SortedPaginationStore.DEFAULT_PAGE_SIZE) {
            this.setPageSize(SortedPaginationStore.DEFAULT_PAGE_SIZE);
        }
        this.saveToLocalStorage();
    }

    public toggleHideColumn(name: string) {
        this._optionStore.toggleHideColumn(name);
        this.saveToLocalStorage();
    }

    public reorderColumns(sorting: string, sibling: string) {
        this._optionStore.reorderColumn(sorting, sibling);
        this.saveToLocalStorage();
    }

    public onResizeColumn(name: string, width: number): void {
        this._optionStore.onResizeColumn(name, width);
        this.saveToLocalStorage();
    }

    //#endregion
}
