import { LOADER } from "src/lib/loading";
import { Services } from "src/services";
import { IApiResponse } from "src/services/client";
import { LoadingStore, SessionStore } from "src/store/global";

import { AbstractForm } from "./base-form/abstract-form";
export * from "./base-form/abstract-form";

export abstract class BaseForm<
    T,
    E extends ValidationError<T> = ValidationError<T>
> extends AbstractForm<T, E> {
    protected $loading: LoadingStore;

    protected $session: SessionStore;

    constructor(
        protected services: Services,
        {
            $loading,
            $session,
        }: {
            $loading: LoadingStore;
            $session: SessionStore;
        },
        protected reportResponseError: (response: IApiResponse<any, any>) => void,
        protected onSubmit?: (data?: any) => Promise<void> | void
    ) {
        super();

        this.$loading = $loading;
        this.$session = $session;
    }

    get [LOADER]() {
        return this.$loading;
    }
}
