import { range } from "src/lib/range";
import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";
import { IUserRole } from "src/types/user";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

export interface ISpaceReadable {
    id: number;
    address: {
        id: number;
        city: string;
        zipCode: string;
        streetAddress: string;
    };
    building: number;
    floor: number;
    hasParking: boolean;
    hasStorage: boolean;
    internalNumber: string;
    number: string;
    users: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        profileImage: string;
        spaceNumber: number;
        role: IUserRole.Owner;
    }[];
    rooms: number | null;
    size: number;
    token: string;
}

export interface ISpaceWritable {
    address: number;
    hasParking: boolean;
    hasStorage: boolean;
    internalNumber: string;
    number: string;
    rooms: number;
    size: number;
}

export interface ISpaceWriteError {
    address?: string[];
    nonFieldErrors?: string[];
}

export interface ISpaceInvitationReadable {
    id: number;
    email: string;
    startDate: string;
    endDate: string;
    groups: string;
}

interface IInvitationWritable {
    space?: number;
    email: string;
    role: string;
    startDate: string | null;
    endDate: string | null;
}

export interface IInvitationWriteError {
    groups?: string[];
    space?: string[];
    role?: string[];
    startDate?: string[];
    endDate?: string[];
    email?: string[];
}

export class SpaceService extends Service {
    public find(id: number) {
        const url = this.client.url(["manager", "space", id.toString()]);
        return this.client.get<IApiResponse<ISpaceReadable, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public getPaginated(url?: string, params?: IPaginationStoreParams, buildingId?: number) {
        if (!url) {
            let queryParams = {};
            if (params && params.page) {
                queryParams = Object.assign(queryParams, { page: params.page });
            }
            if (buildingId) {
                queryParams = Object.assign(queryParams, { buildings: buildingId });
            }

            queryParams = Object.assign(queryParams, params);

            url = this.client.url(["manager", "space"], queryParams, {
                applyWlFilter: true,
            });
        }
        return this.client.get<IApiResponse<IPaginatedBody<ISpaceReadable>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async get(buildingId?: number) {
        const first = await this.getPaginated(undefined, { page: 1 }, buildingId);
        const pagesLeft = first.body.numberOfPages! - 1;

        // Generate each page request instead of using the `next` param to be able
        // to concurrently fetch all consecutive pages at the same time.
        const consecutiveRequests = range(pagesLeft, 2).map((page) => {
            return this.getPaginated(undefined, { page }, buildingId);
        });

        const consecutiveResponses = await Promise.all(consecutiveRequests);
        return [first, ...consecutiveResponses].reduce((acc: ISpaceReadable[], response) => {
            return acc.concat(response.body.results);
        }, []);
    }

    public patch(id: number, space: Partial<ISpaceWritable>) {
        const url = this.client.url(["manager", "space", id.toString()]);
        return this.client.patch<IApiResponse<ISpaceReadable, number>>(url, space, {
            throwOnNon2xx: true,
        });
    }

    public post(space: Partial<ISpaceWritable>) {
        const url = this.client.url(["manager", "space"]);
        return this.client.post<IApiResponse<ISpaceReadable, number>>(url, space, {
            throwOnNon2xx: true,
        });
    }

    public async invite(body: IInvitationWritable) {
        const url = this.client.url(["manager", "invitations"]);
        const hydrate = (resBody: ISpaceInvitationReadable) => ({
            ...resBody,
            startDate: new Date(resBody.startDate),
            endDate: new Date(resBody.endDate),
            groups: resBody.groups,
        });

        const response = await this.client.post<IApiResponse<ISpaceInvitationReadable, number>>(
            url,
            body,
            {
                throwOnNon2xx: true,
            }
        );
        return this.client.hydrateBody(response, hydrate);
    }
}
