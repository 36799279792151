/**
 * Utility function to slice an object to only select the given keys.
 *
 * Usage:
 *  const slice = createObjectSlicer<SourceType>()("key1", 4, SomeSymbol);
 *  const sourceObject = ...;
 *  const destinationObject = slice(sourceObject);
 *  // destinationObject now contains keys "key1", 4 and SomeSymbol
 */
export function createObjectSlicer<T>() {
    return <TKey extends keyof T>(...keys: TKey[]) =>
        (obj: { [key in TKey]: T[key] }) =>
            keys.reduce(
                (o, k) =>
                    k in obj
                        ? {
                              ...o,
                              [k]: obj[k],
                          }
                        : o,
                {} as any
            ) as Required<{ [key in TKey]: T[key] }>;
}
