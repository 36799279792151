import { objectToFormData } from "src/lib/object-to-formdata";
import { range } from "src/lib/range";
import { RequestError } from "src/models/request-error";

import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

interface IAddress {
    city: string;
    streetAddress: string;
    // littera: string;
    // number: 7;
    numberOfFloors: number;
    numberOfSpaces: number;
    // street: string;
    zipCode: string;
}

export interface IBuilding {
    id: number;
    title: string;
    email: string;
    emailSecondary: string;
    icon: File | string | null;
    profileImage: File | string | null;
    phone: string;
    organizationNumber: string;
    buildingInfo: {
        addresses: IAddress[];
    };
}

export type IBuildingUpdateError = ValidationError<IBuilding>;
export interface IAdminRightUpdateError {
    userId: string[];
}

export class BuildingService extends Service {
    public getPaginated(url?: string, params?: IPaginationStoreParams) {
        if (!url) {
            let queryParams = {};
            if (params && params.page) {
                queryParams = Object.assign(queryParams, { page: params.page });
            }

            url = this.client.url(["manager", "buildings"], queryParams, {
                applyWlFilter: true,
            });
        }
        return this.client.get<IApiResponse<IPaginatedBody<IBuilding>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async get() {
        const first = await this.getPaginated(undefined, { page: 1 });
        const pagesLeft = first.body.numberOfPages! - 1;

        // Generate each page request instead of using the `next` param to be able
        // to concurrently fetch all consecutive pages at the same time.
        const consecutiveRequests = range(pagesLeft, 2).map(() => {
            return this.getPaginated(undefined, { page: 1 });
        });

        const consecutiveResponses = await Promise.all(consecutiveRequests);
        return [first, ...consecutiveResponses].reduce((acc: IBuilding[], response) => {
            return acc.concat(response.body.results);
        }, []);
    }

    public async patch(data: Partial<IBuilding>) {
        const url = this.client.url(["manager", "building"]);

        const body = objectToFormData(data);

        if (typeof body.get("icon") === "string") {
            body.delete("icon");
        }

        if (typeof body.get("profileImage") === "string") {
            body.delete("profileImage");
        }

        return this.client.patch(url, body, {
            throwOnNon2xx: true,
        });
    }

    public grantAdminRights(id: number, userId: number) {
        const url = this.client.url(["manager", "building", id, "admins"]);
        return this.client.post(
            url,
            { userId },
            {
                throwOnNon2xx: true,
            }
        );
    }

    public async revokeAdminRights(id: number, userId: number, idempotent = false) {
        try {
            const url = this.client.url(["manager", "building", id, "admins", userId]);
            return await this.client.delete(url, {
                throwOnNon2xx: true,
            });
        } catch (e) {
            if (idempotent && RequestError.isNotFound(e)) {
                return true;
            }
            throw e;
        }
    }
}
