import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IUserProfileRead } from "src/services/services/user-service";
import { IPaginatedBody } from "src/types/paginated-response";

export enum AgentType {
    Organization = "organization",
    Person = "person",
}

export interface IAgentPersonV1Read {
    uuid: string;
    firstName: string;
    lastName: string;
    profile?: IUserProfileRead;
    streetAddress: string | null;
    apartmentNumber: string | null;
    email: string;
    phone: string;
    communities: string[];
    created: string;
    updated: string;
    groups: string[];
    roles: string[];
    revokedPermissions: string[];
    absent: boolean;
    userAbsenceSet: string[];
    leasePublicName: string;
    premisesInternalNumber: string;
    premisesLfrApartmentNumber: string;
    type: AgentType.Person;
}

export interface IAgentOrganizationV1Read {
    uuid: string;
    name: string;
    companyName: string;
    organizationNumber: string;
    profileImage: string;
    address: string;
    zipCode: string;
    city: string;
    phone: string;
    email: string;
    isWhitelabelOrganization: boolean;
    created: string;
    type: AgentType.Organization;
}

export type IAgentV1Read = IAgentPersonV1Read | IAgentOrganizationV1Read;

export class AgentService extends Service {
    public list(params?: IQueryParams) {
        params = { ...params, expand: "user" };
        const url = this.client.url(["api", "v1", "agents"], params);
        return this.client.get<IApiResponse<IPaginatedBody<IAgentV1Read>, number>>(url, {
            throwOnNon2xx: true,
        });
    }
}
