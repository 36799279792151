import { objectToFormData } from "src/lib/object-to-formdata";

import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";

import { IFilesInputFile } from "src/types/file-input";
import { IPaginatedBody } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";
import { IAlterResource } from "src/app/brf/store/sharing-store/alter-resource-form";

export enum IResourceType {
    PUBLIC = "shared",
    PRIVATE = "private",
}

export enum IBookingType {
    ANSWERED,
    UNANSWERED,
}

export enum IBookingStatus {
    PENDING = "pending",
    CONFIRMED = "confirmed",
    DECLINED = "declined",
}

export interface IInterval {
    id?: number;
    startTime: string;
    endTime: string;
    weekday: number;
    weekdayDisplay?: string;
}

export interface IFile {
    id?: number;
    title: string;
    file: string;
    fileSize: number;
    iconUrl?: string;
    created: string;
}

export interface IResource {
    id?: number;
    name: string;
    image?: string;
    timeUnit?: string;
    created?: string;
    owner?: string;
}

export interface IResourceUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    spaceNumber: string;
}

export interface IBooking {
    id: number;
    startDatetime: string;
    endDatetime: string;
    status: string;
    user: IResourceUser;
    resource: IResource;
    deleted?: string;
}

export class SharingService extends Service {
    public async getPaginatedResources(url?: string, params?: IPaginationStoreParams) {
        if (!url) {
            url = this.client.url(["manager", "resource"], params, {
                applyWlFilter: true,
            });
        }

        return this.client.get<IApiResponse<IPaginatedBody<IResource>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async getPaginatedBookings(url?: string, params?: IPaginationStoreParams) {
        if (!url) {
            url = this.client.url(["manager", "booking"], params, {
                applyWlFilter: true,
            });
        }

        return this.client.get<IApiResponse<IPaginatedBody<IBooking>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async getResource(resourceID: number) {
        const url = this.client.url(["manager", "resource", resourceID.toString()]);
        return this.client.get<IApiResponse<IAlterResource, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async postResource(resource: IAlterResource) {
        const url = this.client.url(["manager", "resource"]);

        const data: Partial<IAlterResource> = { ...resource };
        const image: File | null =
            typeof data.image !== "string" && data.image ? Object.assign({}, data.image) : null;

        delete data.image;
        delete data.files;
        delete data.rangeInputIntervals;
        delete data.startIntervalLengthEnum;
        delete data.endIntervalLengthEnum;
        delete data.inputFiles;

        const result = await this.client.post<IApiResponse<IAlterResource, number>>(url, data, {
            throwOnNon2xx: true,
        });

        if (image && result.body.id) {
            return await this.patchResource({ image: resource.image }, result.body.id);
        }
        return result;
    }

    public async patchResource(resource: Partial<IAlterResource>, resourceID: number) {
        const url = this.client.url(["manager", "resource", resourceID.toString()]);
        return this.client.patch<IApiResponse<IAlterResource, number>>(
            url,
            objectToFormData(resource),
            { throwOnNon2xx: true }
        );
    }

    public async deleteResource(resourceID: number) {
        const url = this.client.url(["manager", "resource", resourceID.toString()]);
        return this.client.delete(url, {
            throwOnNon2xx: true,
        });
    }

    public async patchBooking(bookingID: number, params: { [key: string]: string }) {
        const url = this.client.url(["manager", "booking", bookingID.toString()]);
        return this.client.patch<IApiResponse<IBooking, number>>(url, objectToFormData(params), {
            throwOnNon2xx: true,
        });
    }

    public async postResourceFile(file: IFilesInputFile, resourceID: number) {
        const url = this.client.url(["manager", "files"]);
        return this.client.post(
            url,
            objectToFormData({
                file: file.localFile,
                title: file.fileName,
                resourceDocuments: resourceID.toString(),
            }),
            { throwOnNon2xx: true }
        );
    }

    public async deleteResourceFile(file: IFilesInputFile) {
        const url = this.client.url(["manager", "files", file.id!.toString()]);
        return this.client.delete(url, {
            throwOnNon2xx: true,
        });
    }
}
