import { IEcomUser } from "src/services";
import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export interface IFolio {
    id: number;
    title: string;
    balance: string;
    creditInclTax: string;
    maxCreditSet: number;
    maxCreditAllowed: number;
    currency: string;
    user: IEcomUser;
    invoices: string;
}

export class FolioService extends Service {
    public listForLeasee(params: IQueryParams) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "folios"], params);

        return this.client.get<
            | IApiResponse<IPaginatedBody<IFolio>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public list() {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "folios"]);

        return this.client.get<
            | IApiResponse<IPaginatedBody<IFolio>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }
}
