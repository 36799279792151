import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginationStoreCursorParams } from "src/store/_generic/pagination-store-cursor";
import { IPaginatedBody } from "src/types/paginated-response";

export enum IInvoiceStatus {
    Unpaid = "unpaid",
    Paid = "paid",
    OverdueUnpaid = "overdue_unpaid",
    OverduePaid = "overdue_paid",
}

export interface IInvoice {
    id: number;
    number: string;
    title: string;
    invoiceDate: string;
    dueDate: string;
    paidDate: string;
    pdfUrl: string;
    totalInclTax: string;
    currency: string;
    status: IInvoiceStatus;
}

export class InvoiceService extends Service {
    public list(params?: IPaginationStoreCursorParams) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "invoices"], params);

        return this.client.get<
            | IApiResponse<IPaginatedBody<IInvoice>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }
}
