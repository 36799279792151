import { HttpStatus, IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface IAgreementRead {
    uuid: string;
    active: boolean;
    superClient: number;
    client: number;
    content: string;
    integrityPolicyContent: string;
    created: string;
    updated: string;
}

interface IAgreementLinks {
    termsOfUse: string;
    integrityPolicy: string;
}

export class AgreementService extends Service {
    public list(params?: IQueryParams) {
        const url = this.client.url(["api", "v1", "agreements"], params);
        return this.client.get<IApiResponse<IPaginatedResponseV1<IAgreementRead>, HttpStatus.Ok>>(
            url
        );
    }

    public agreementToLinks(agreement: IAgreementRead): IAgreementLinks {
        return {
            termsOfUse: this.client.url(["api", "v1", "agreements", agreement.uuid]),
            integrityPolicy: this.client.url(["api", "v1", "agreements", agreement.uuid], {
                is_integrity_policy: "1",
            }),
        };
    }
}
