import { observable, action } from "mobx";

import { IApiResponse, HttpStatus } from "src/services/client";
import { observed } from "src/store/lib/observed";
import { IPaginatedCursorResponse } from "src/types/paginated-response";

type IServiceEndpointCursorResponse<T> = Promise<
    IApiResponse<IPaginatedCursorResponse<T>, number> | IApiResponse<unknown, HttpStatus.NotFound>
>;
export type IPaginationStoreCursorParams = IQueryParams & { cursor?: string };

export type IPaginationServiceEndpointCursor<T> = (
    params: IPaginationStoreCursorParams
) => IServiceEndpointCursorResponse<T>;

@observed
export class PaginationStoreCursor<T> implements Partial<IPaginatedCursorResponse<T>> {
    private static DEFAULT_PAGE_SIZE = 20;

    @observable
    public count?: number;

    @observable
    public next?: string | null;

    @observable
    public previous?: string | null;

    @observable
    public results?: T[];

    @observable
    public numberOfPages?: number;

    @observable
    public page: number;

    @observable
    public params: IPaginationStoreCursorParams = {};

    @observable
    public useLoadMoreStore = false;

    @observable
    private defaultParams: IQueryParams = {};

    public constructor(
        private serviceEndpoint: IPaginationServiceEndpointCursor<T>,
        useLoadMoreStore?: boolean
    ) {
        this.page = 1;
        if (useLoadMoreStore) {
            this.useLoadMoreStore = useLoadMoreStore;
        }
    }

    @action.bound
    public async getInitial() {
        const response = await this.serviceEndpoint(this.params);
        if (response.status === HttpStatus.Ok) {
            this.update(response.body, 1);
        }
    }

    @action.bound
    public async getNext() {
        if (this.next !== null) {
            const params = { cursor: this.next };
            const response = await this.serviceEndpoint(params);

            if (response.status === HttpStatus.Ok) {
                this.update(response.body, this.page + 1);
            }
        }
    }

    @action.bound
    public async getPrevious() {
        if (this.previous !== null) {
            const params = { cursor: this.previous };
            const response = await this.serviceEndpoint(params);

            if (response.status === HttpStatus.Ok) {
                this.update(response.body, this.page - 1);
            }
        }
    }

    @action.bound
    private async getCurrentPage(params: IQueryParams) {
        const response = await this.serviceEndpoint(params);
        if (response.status === HttpStatus.Ok) {
            this.update(response.body, this.page);
        }
    }

    @action.bound
    public async reload() {
        if (this.params.cursor) {
            return this.getCurrentPage(this.params);
        }
        return this.getInitial();
    }

    @action.bound
    public setParam(key: string, value: IQueryParamValue) {
        this.params[key] = value;
    }

    @action.bound
    public setDefaultParams(params: IQueryParams) {
        this.defaultParams = params;
        this.params = { ...params };
    }

    @action.bound
    public getPageSize() {
        return PaginationStoreCursor.DEFAULT_PAGE_SIZE;
    }

    @action.bound
    public reset() {
        this.count = undefined;
        this.next = undefined;
        this.previous = undefined;
        this.results = undefined;
        this.params = { ...this.defaultParams };
    }

    @action.bound
    private update(next: IPaginatedCursorResponse<T>, page: number) {
        this.page = page;
        this.count = next.count;
        this.next = next.next;
        this.previous = next.previous;
        this.results =
            !this.params.search && this.useLoadMoreStore
                ? (this.results = (this.results ?? []).concat(next.results))
                : next.results;
        this.numberOfPages = Math.ceil(next.count / PaginationStoreCursor.DEFAULT_PAGE_SIZE);
    }
}
