import * as Sentry from "@sentry/browser";

import Debug from "debug";

import {
    DEPLOY_ENVIRONMENT,
    SENTRY_DSN,
    BUILD_TS,
    GIT_REV,
    VERSION,
    APPLICATION,
} from "src/config/environment";

const debug = Debug("tmpl:sentry");

export const setupSentry = () => {
    if (!SENTRY_DSN) {
        debug("Sentry DSN is not set. Skipping sentry.");
        return;
    }
    const options: Sentry.BrowserOptions = {
        dsn: SENTRY_DSN,
        environment: DEPLOY_ENVIRONMENT,
    };

    if (VERSION) {
        options.release = `${APPLICATION}@${VERSION}`;
    }

    if (GIT_REV) {
        Sentry.setTag("git_rev", GIT_REV);
    }

    if (BUILD_TS) {
        Sentry.setTag("build_timestamp", BUILD_TS);
    }

    Sentry.init(options);
};
