import { Omit } from "utility-types";

import { camelCase } from "src/lib/transform-case";

import { toSnakeCase } from "src/lib/transform-keys";
import { RequestError } from "src/models/request-error";

import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { ITag } from "src/services/services/types/tag";
import { IAPIPublicCard } from "src/types/api";
import { IPaginatedBody } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";
import { IAlterCardFormData } from "src/app/brf/store/feed-store/alter-card-form";

export interface ICardType {
    id: number;
    title: string;
    icon: string;
}

export interface ICardAuthor {
    id: number;
    firstName: string;
    lastName: string;
    profileImage: string | null;
    phone: string;
    email: string;
}

export interface ICard {
    id: number;
    author: ICardAuthor;
    title: string;
    description: string;
    created: string;
    picture: string | null;
    cardType: ICardType;
    cardcommentSet?: ICardComment[];
}

export interface ICardComment {
    id: number;
    created: string;
    user: ICardAuthor;
    comment: string;
}

export type ICardTypeItemField =
    | "address"
    | "endTime"
    // | "latitude"
    // | "longitude"
    | "price"
    | "priceCurrency"
    | "radius"
    | "startTime"
    | "title";

export interface ICardTypeItemRaw {
    uuid: string;
    identifier: string;
    icon: string;
    name: string;
    fieldStructure: { [key in ICardTypeItemField]: "" };
}

export type ICardTypeItem = Omit<ICardTypeItemRaw, "fieldStructure"> & {
    fields: ICardTypeItemField[];
};

export type ICardCreateError = ValidationError<IAPIPublicCard>;

export class FeedService extends Service {
    public async getPaginatedCards(
        url?: string,
        params?: IPaginationStoreParams,
        reported?: boolean
    ) {
        if (!url) {
            let queryParams = {
                flags_gte: reported ? 2 : "",
                flags_lte: reported ? "" : 1,
            };
            if (params && params.page) {
                queryParams = Object.assign(queryParams, { page: params.page });
            }
            queryParams = Object.assign(queryParams, params);
            url = this.client.url(["manager", "feed"], queryParams, {
                applyWlFilter: true,
            });
        }
        return this.client.get<IApiResponse<IPaginatedBody<ICard>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async getCard(id: number) {
        const url = this.client.url(["manager", "feed", id.toString()]);
        return this.client.get<IApiResponse<Required<ICard>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async deleteComment(id: number) {
        const url = this.client.url(["manager", "feed-comments", id.toString()]);
        return this.client.delete(url, {
            throwOnNon2xx: true,
        });
    }

    public async postCard(
        card: Pick<
            IAPIPublicCard,
            "cardType" | "title" | "description" | "picture" | "allowComments" | "author" | "pinned"
        > & { tags: ITag[] },
        fields: { [key in ICardTypeItemField]?: string }
    ) {
        const url = this.client.url(["api", "v1", "public-cards"]);

        const data = new FormData();
        data.append("title", card.title);
        data.append("description", card.description);
        data.append("cardType", card.cardType);
        data.append("allowComments", String(card.allowComments));
        data.append("author", card.author);

        if (card.pinned instanceof Date) {
            data.append("pinned", card.pinned.toISOString());
        }

        data.append("fields", JSON.stringify(toSnakeCase(fields)));

        if (card.picture instanceof File) {
            data.append("picture", card.picture);
        }

        card.tags.forEach((_, i) => {
            data.append(`tags[${i}]`, card.tags[i].id);
        });

        try {
            return await this.client.post(url, data, {
                throwOnNon2xx: true,
            });
        } catch (e) {
            // tslint:disable-next-line:no-any
            if (RequestError.isBadRequest<any>(e)) {
                if ("fields" in e.body) {
                    const original = e.body.fields;
                    delete e.body.fields;
                    const next = original.reduce(
                        (acc: ValidationError<IAlterCardFormData>, v: string) => {
                            const parts = v.split(":").map((p) => p.trim());
                            acc[camelCase(parts[0])] = [parts[1]];
                            return acc;
                        },
                        {} as ValidationError<IAlterCardFormData>
                    );
                    throw new RequestError(next, e.response);
                }
            }
            throw e;
        }
    }

    public async patchFlaggedCard(id: number) {
        const url = this.client.url(["manager", "feed", id.toString()]);
        return this.client.patch<IApiResponse<Required<ICard>, number>>(
            url,
            {
                flagged_by: [],
                deleted: null,
            },
            {
                throwOnNon2xx: true,
            }
        );
    }

    public async deleteCard(id: number) {
        const url = this.client.url(["manager", "feed", id.toString()]);
        return this.client.delete(url, {
            throwOnNon2xx: true,
        });
    }

    public async getCardTypes() {
        function compressFieldsForCardType(cardType: ICardTypeItemRaw): ICardTypeItem {
            return {
                ...cardType,
                fields: Object.keys(cardType.fieldStructure) as ICardTypeItemField[],
            };
        }

        const url = this.client.url(["api", "v1", "public-card-types"]);
        const response = await this.client.get<
            IApiResponse<IPaginatedBody<ICardTypeItemRaw>, number>
        >(url, {
            throwOnNon2xx: true,
        });
        return this.client.hydrateBody(response, (body) => ({
            ...body,
            results: body.results.map(compressFieldsForCardType),
        }));
    }
}
