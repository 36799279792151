import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export enum IUserLeaseRole {
    ContactPerson = "contact_person",
    Coworker = "coworker",
    Resident = "resident",
    Tenant = "tenant",
}

export interface IUserRole {
    uuid: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    profileImage: string | null;
    roles: Array<string>;
}

export class UserRoleService extends Service {
    public list(params?: IQueryParams) {
        const url = this.client.url(["api/v1", "user-roles"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<IUserRole>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public listForLease(leaseID: string) {
        const queryParams = {
            app: "community",
            model: "membershiplease",
            lookup_field: "uuid",
            lookup_value: leaseID,
        };

        return this.list(queryParams);
    }
}
