import { HttpStatus, IGenericApiError, IApiResponseMap } from "src/services/client";
import { Service } from "src/services/service";

import { IBuildingRead } from "src/services/services/building-service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

interface IRealEstate {
    title: string;
}

export interface IRealEstateBase extends IRealEstate {
    uuid: string;
    buildingCount: number;
    buildings: string[] | IBuildingRead[];
}

export type IRealEstateRead = Collapse<IRealEstateBase, "buildings">;
export type IRealEstateDetailsRead = Expand<IRealEstateBase, "buildings">;

export type IRealEstateUpsert = IRealEstate;

export type IRealEstateUpsertError = ValidationError<IRealEstateUpsert>;

export class RealEstateService extends Service {
    public async list(params: IQueryParams) {
        const url = this.client.url(["api", "v1", "real-estates"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IRealEstateRead>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async retrieve(uuid: string, params?: IQueryParams) {
        params = { ...params, expand: "buildings" };

        const url = this.client.url(["api", "v1", "real-estates", uuid], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IRealEstateDetailsRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async create(body: IRealEstateUpsert) {
        const params = {
            expand: "buildings",
        };

        const url = this.client.url(["api", "v1", "real-estates"], params);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IRealEstateDetailsRead;
                [HttpStatus.BadRequest]: IRealEstateUpsertError;
            }>
        >(url, body);
    }

    public async delete(uuid: string) {
        const url = this.client.url(["api", "v1", "real-estates", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: undefined;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async update(uuid: string, body: IRealEstateUpsert) {
        const params = {
            expand: "buildings",
        };

        const url = this.client.url(["api", "v1", "real-estates", uuid], params);
        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IRealEstateDetailsRead;
                [HttpStatus.BadRequest]: IRealEstateUpsertError;
            }>
        >(url, body);
    }
}
