import { FormValuefy } from "src/models/form-value/formvaluefy-type";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";

import { IPaginatedBody } from "src/types/paginated-response";

import { IGroupRoleRead, GroupRoleAdmin } from "./group-role-service";
import { IUserRead, IUserBase, IUserAbsenceWrite } from "./user-service";

export interface IGroupRoleAdminRead extends IGroupRoleRead {
    role: GroupRoleAdmin;
}

export interface IAdministratorCreate {
    user: string;
    role: string;
    communities: string[];
}

export interface IAdministratorUpdate extends IUserRead {
    user: string;
    role: string;
    communities: string[];
}

export interface IAdministratorBase extends IUserBase {
    roles: IGroupRoleAdminRead[] | string[];
}

export type IAdministratorRead = ExpandCollapse<IAdministratorBase, "communities", "roles">;
export type IAdministratorDetailsRead = Expand<IAdministratorBase, "communities" | "roles">;

export type IAdministratorUpdateError = ValidationError<IAdministratorUpdate>;
export type IAdministratorAbsentUpdateError = ValidationError<{ interval: string }>;

export class AdminService extends Service {
    public list(params?: IQueryParams) {
        params = { ...params, expand: ["roles"] };

        const url = this.client.url(["api", "v1", "admins"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<IAdministratorRead>;
            }>
        >(url);
    }

    public retrieve(userId: string, params?: IQueryParams) {
        params = { ...params, expand: ["roles", "communities", "user"] };
        const url = this.client.url(["api", "v1", "admins", userId], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IAdministratorDetailsRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public listAdminRoles(params?: IQueryParams) {
        params = { ...params, admin_role: 1 };

        const url = this.client.url(["api", "v1", "group-roles"], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<IGroupRoleAdminRead>;
            }>
        >(url);
    }

    public delete(uuid: string) {
        const url = this.client.url(["api", "v1", "admins", uuid]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.Forbidden]: IGenericApiError;
            }>
        >(url);
    }

    public async update(uuid: string, data: Partial<FormValuefy<IAdministratorUpdate>>) {
        const url = this.client.url(["api", "v1", "admins", uuid]);
        const result = await this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IAdministratorRead;
                [HttpStatus.BadRequest]: IAdministratorUpdateError;
            }>
        >(url, data);

        return result;
    }

    public create(adminInvitation: FormValuefy<IAdministratorCreate>) {
        const url = this.client.url(["api", "v1", "admins"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: IAdministratorRead;
                [HttpStatus.BadRequest]: IAdministratorUpdateError;
            }>
        >(url, adminInvitation);
    }

    public setAbsent(uuid: string, absent: boolean | IUserAbsenceWrite) {
        const url = this.client.url([
            "api",
            "v1",
            "users",
            uuid,
            absent ? "set-absent" : "set-present",
        ]);

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: IAdministratorRead;
                [HttpStatus.BadRequest]: IAdministratorAbsentUpdateError;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, typeof absent === "object" ? absent : {});
    }

    public allAdminRoles = this.createListTraverser((params) => this.listAdminRoles(params));
}
