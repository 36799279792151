import { objectToFormData } from "src/lib/object-to-formdata";
import { HttpStatus, IGenericApiError, IApiResponseMap } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface IFileRead {
    uuid: string;
    category: string;
    title: string;
    description: string;
    file: string;
    tags: string[];
    created: string;
    updated: string;
    fileSize: number;
    iconUrl: string;
    public: boolean;
}

export interface IFileCreate {
    title: string;
    description: string;
    category: string;
    file: File;
    tags: string[];
    public: boolean;
}

export interface IFileUpdate extends Partial<Omit<IFileCreate, "file">> {
    file?: File | null;
}

export class FileService extends Service {
    public async list(params?: IQueryParams) {
        const url = this.client.url(["api", "v1", "files"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IFileRead>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async retrieve(uuid: string) {
        const url = this.client.url(["api", "v1", "files", uuid]);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IFileRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async update(uuid: string, file: Partial<IFileCreate>) {
        const url = this.client.url(["api", "v1", "files", uuid]);

        const nextFile = { ...file };
        const body = objectToFormData(nextFile);

        if (typeof body.get("file") === "string") {
            body.delete("file");
        }

        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IFileRead;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.BadRequest]: ValidationError<IFileUpdate>;
            }>
        >(url, body);
    }

    public async create(file: IFileCreate) {
        const url = this.client.url(["api", "v1", "files"]);

        const nextFile = { ...file };
        const body = objectToFormData(nextFile);

        if (typeof body.get("file") === "string") {
            body.delete("file");
        }

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IFileRead;
                [HttpStatus.NotFound]: IGenericApiError;
                [HttpStatus.BadRequest]: ValidationError<IFileCreate>;
            }>
        >(url, body);
    }

    public async delete(uuid: string) {
        const url = this.client.url(["api", "v1", "files", uuid]);
        return this.client.delete(url);
    }
}
