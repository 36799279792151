export function deDup<T>(subject: T[]): T[] {
    return [...new Set(subject)];
}

export const isStringArray = (arr: unknown[]): arr is string[] => {
    return arr.every((item) => typeof item === "string");
};

/**
 * Updates the first matching item in-place with a partial of the same type.
 * Accepts a function returning an object, as the `update` argument, to be able
 * to make modifications based on the matching item.
 *
 * @example
 * ```
 * const arr = [{ id: 1, name: "Nelson 'Big Head' Bighetti" }, { id: 2, name: "Erlich Bachman" }];
 * const updatedItem = updateMatchingItem(arr, { id: 1 }, (item) => (
 *      { ...item, name: item.name.replace("Big Head", "Bag Head") }
 * ))
 *
 * arr === [{ id: 1, name: "Nelson 'Bag Head' Bighetti" }, { id: 2, name: "Erlich Bachman" }];
 * updatedItem === { id: 1, name: "Nelson 'Bag Head' Bighetti" }
 * ```
 *
 * Returns the modified object if an object was found, otherwise null.
 */
export const updateMatchingItem = <TItem>(
    arr: TItem[],
    match: Partial<TItem>,
    update: (item: TItem) => TItem
) => {
    const index = getMatchingItemIndex(arr, match);

    if (index === -1) {
        return arr;
    } else {
        const nextArr = arr.slice(0);
        nextArr[index] = { ...arr[index], ...update(arr[index]) };
        return nextArr;
    }
};

/**
 * Return the index of the first item matching the provided match partial.
 * Returns -1 if no matching item was found.
 */
export const getMatchingItemIndex = <TItem>(arr: TItem[], match: Partial<TItem>): number => {
    const keys = Object.keys(match);

    // If no matcher is provided exit early with -1
    if (!keys.length) {
        return -1;
    }

    return arr.findIndex((item) => {
        return keys.map((key) => key in item && item[key] === match[key]).every(Boolean);
    });
};
