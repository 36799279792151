import { objectToFormData } from "src/lib/object-to-formdata";

import { IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

import { IPaginationStoreParams } from "src/app/brf/store/_generic/pagination-store";

export interface IIssueUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface IIssueCategory {
    id: number;
    title: string;
    building: number;
    icon: string | null;
    email: string;
}

export interface IIssueStatus {
    value: string;
    display: string;
}

export interface IIssue {
    id: number;
    category: IIssueCategory;
    title: string;
    issue: string;
    user: IIssueUser;
    status: IIssueStatus;
    timeEstimate: string | null;
    image: string | null;
    created: string;
    updated: string;
}

export interface IIssueWritable {
    id: number;
    category: number;
    title: string;
    issue: string;
    status: string;
    timeEstimate: string | null;
}

export interface IIssueStatusChoice {
    value: string;
    displayName: string;
}

export interface IIssueOptions {
    status: {
        choices: IIssueStatusChoice[];
    };
}

export type IOptionsActions = { [key in "put" | "post"]: IIssueOptions };

export interface IOptions {
    actions: IOptionsActions;
}

export type IIssueCategoryUpdateError = ValidationError<IIssueCategory>;
export type IIssueUpdateError = ValidationError<IIssueWritable>;

export class ReportService extends Service {
    public getPaginatedIssues(url?: string, params?: IPaginationStoreParams, statuses?: string) {
        if (!url) {
            let queryParams = {
                status__in: statuses ? statuses : "",
            };
            if (params && params.page) {
                queryParams = Object.assign(queryParams, { page: params.page });
            }
            url = this.client.url(["manager", "issues"], queryParams, {
                applyWlFilter: true,
            });
        }
        return this.client.get<IApiResponse<IPaginatedBody<IIssue>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async getPaginatedIssueCategories(url?: string) {
        if (!url) {
            url = this.client.url(["manager", "issue-categories"]);
        }
        return this.client.get<IApiResponse<IPaginatedBody<IIssueCategory>, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async getIssueStatuses(issueID: number) {
        const url = this.client.url(["manager", "issues", issueID.toString()]);
        return this.client.options<IApiResponse<IOptions, number>>(url, {
            throwOnNon2xx: true,
        });
    }

    public async patchIssue(issue: Partial<IIssueWritable>, issueID: number) {
        const url = this.client.url(["manager", "issues", issueID.toString()]);
        return this.client.patch(url, objectToFormData(issue), {
            throwOnNon2xx: true,
        });
    }

    public delete(id: number) {
        const url = this.client.url(["manager", "issues", id]);
        return this.client.delete(url, {
            throwOnNon2xx: true,
        });
    }

    public async patchIssueCategory(category: IIssueCategory) {
        const url = this.client.url(["manager", "issue-categories", category.id.toString()]);
        return this.client.patch(url, objectToFormData(category), {
            throwOnNon2xx: true,
        });
    }
}
