import { ClientConfigurationModuleType, IUserRead } from "src/services";

import { HttpStatus } from "src/services/client";
import { BaseStore } from "src/store/base-store";

import { IUserSelection } from "src/types/user-selection";

import { AlterClientSettingsForm } from "./client-store/alter-client-settings-form";

function userToUserSelection(user: IUserRead) {
    return {
        uuid: user.uuid,
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: user.profile?.profileImage || null,
        description: user.email,
    };
}
export class ClientStore extends BaseStore {
    public static readonly LOADING_CLIENT_MODULES = Symbol("LOADING_CLIENT_MODULES");

    public alterClientSettingsForm = this.form(AlterClientSettingsForm);

    public async getModule<T extends ClientConfigurationModuleType>(moduleName: T) {
        const response = await this.services.clientModules.retrieve(moduleName);

        if (response.status === HttpStatus.Ok) {
            return response.body;
        }

        this.reportResponseError(response);
        return null;
    }

    public async initClientSettingsForm() {
        const issueSettings = await this.getModule("issues");
        const leasesSettings = await this.getModule("leases");
        let defaultIssueHandler: IUserSelection | undefined;

        if (issueSettings) {
            if (issueSettings.settings.defaultIssueHandler) {
                const userResponse = await this.services.userV1.retrieve(
                    issueSettings.settings.defaultIssueHandler
                );

                if (userResponse.status === HttpStatus.Ok) {
                    defaultIssueHandler = userToUserSelection(userResponse.body);
                } else {
                    this.reportResponseError(userResponse);
                }
            }
        }

        this.alterClientSettingsForm.init({
            issues: issueSettings,
            defaultIssueHandler,
            leases: leasesSettings,
        });
    }

    public async searchIssueHandler(query: string) {
        const result = await this.services.userV1.list({
            search: query,
            page_size: 5,
            can_handle_issues: 1,
        });

        if (result.status !== HttpStatus.Ok) {
            this.reportResponseError(result);
            return [];
        }

        return result.body.results.map(userToUserSelection);
    }
}
