import { toIsoDateString } from "src/lib/date-formatters";
import { IUserRole } from "src/services";
import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export interface IMembershipLease {
    uuid: string;
    membership: string;
    batchUuid: string;
    leasor: string | null;
    leasee: string | null;
    leasees: string[];
    publicName: string;
    publicNameWithDefault: string;
    activates: string;
    expires: string | null;
    accessEnds: string | null;
    created: string;
    updated: string;
    users: IUserRole[];
    invitations: ILeaseInvitations[];
}

export interface ILeaseInvitations {
    uuid: string;
    email: string;
    registered: string;
    start_date: string;
    end_date: string;
    membership_lease: string;
    token: string;
    role: string;
}

interface IUpdateMembershipLeaseResponse {
    payload: string;
    membershipLease: string;
}

export class MembershipLeasesService extends Service {
    public allForUser = (userUuid: string, extraParams?: IQueryParams) =>
        this.createListTraverser<IMembershipLease>((params) =>
            this.listForUser(userUuid, { ...extraParams, ...params })
        )();

    public list(params?: IQueryParams) {
        const url = this.client.url(["api/v1", "membership-leases"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            | IApiResponse<IPaginatedBody<IMembershipLease>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public listForUser(userUuid: string, params?: IQueryParams) {
        const url = this.client.url(["api", "v1", "membership-leases"], {
            user: userUuid,
            ...params,
        });

        return this.client.get<
            | IApiResponse<IPaginatedBody<IMembershipLease>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public setEndDate(subscriptionsID: number, membershipLeaseID: string, expires: Date) {
        const resource = {
            end_date: toIsoDateString(expires),
            membership_lease: membershipLeaseID,
        };
        const url = this.client.url([
            "api",
            "v1",
            "ecommerce",
            "v2",
            "proxy/core/subscriptions",
            subscriptionsID,
            "end-lease",
        ]);
        return this.client.patch<
            | IApiResponse<IUpdateMembershipLeaseResponse, HttpStatus.Ok>
            | IApiResponse<ValidationError<IUpdateMembershipLeaseResponse>, HttpStatus.BadRequest>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url, resource);
    }

    public createInvitations(email: string, membershipLeaseID: string, subscriptionsID: number) {
        const resource = { payload: { email }, membership_lease: membershipLeaseID };
        const url = this.client.url([
            "api",
            "v1",
            "ecommerce",
            "v2",
            "proxy/core/subscriptions",
            subscriptionsID,
            "invitation",
        ]);

        return this.client.post<
            | IApiResponse<ILeaseInvitations, HttpStatus.Created>
            | IApiResponse<ValidationError<ILeaseInvitations>, HttpStatus.BadRequest>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url, resource);
    }

    public deleteInvitation(subscriptionID: number, leaseID: string, invitationID: string) {
        const params = { membership_lease: leaseID, membership_lease_invitation: invitationID };
        const url = this.client.url(
            [
                "api",
                "v1",
                "ecommerce",
                "v2",
                "proxy/core/subscriptions",
                subscriptionID,
                "invitation",
            ],
            params
        );

        return this.client.delete<
            IApiResponse<unknown, HttpStatus.NoContent> | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }
}
