import { toCamelCase } from "src/lib/transform-keys";
import { IApiResponseMap, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";

export enum OEmbedProvider {
    Vimeo = "Vimeo",
    YouTube = "YouTube",
}
export interface IOEmbedMetadata {
    title: string;
    authorName: string;
    authorUrl: string;
    type: "video";
    version: string;
    providerName: OEmbedProvider;
    providerUrl: string;
    height: number;
    width: number;
    thumbnailHeight: number;
    thumbnailWidth: number;
    thumbnailUrl: string;
    html: string;
}

export interface IOEmbedError {
    url: string;
    error: string;
}

export type IOEmbedResponse = IOEmbedMetadata | IOEmbedError;

export class OEmbedService extends Service {
    public async retrieve(videoUrl: string, params?: IQueryParams) {
        params = { ...params, format: "json", url: videoUrl };

        const url = this.client.url(["https://noembed.com", "embed"], params);
        const response = await this.client.get<
            IApiResponseMap<{
                // Note: The response from noembed is always 200 even if the
                // video provider responds with 40X.
                //
                // Also the response content-type is set to `text/javascript` so it's never
                // parsed by client so we do it ourselves.
                [HttpStatus.Ok]: string;
            }>
        >(url, { rawRequest: true });

        if (response.status === HttpStatus.Ok) {
            return this.client.hydrateBody(
                response,
                (body: string) => toCamelCase(JSON.parse(body)) as IOEmbedResponse
            );
        }

        return response;
    }
}
