import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

interface IMemberships {
    uuid: string;
    communityLease: string;
    created: string;
    updated: string;
}

export class MembershipsService extends Service {
    public list(communityLeaseID: string, params?: IQueryParams) {
        params = { ...params, community_lease: communityLeaseID };
        const url = this.client.url(["api/v1", "memberships"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            | IApiResponse<IPaginatedBody<IMemberships>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public allForMemberships = (communityLeaseID: string, params?: IQueryParams) =>
        this.createListTraverser((p) => this.list(communityLeaseID, p))(params);
}
