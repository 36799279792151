import { Store } from "src/store";

import { I18nStore } from "src/store/global/i18n-store";
import { MessageStore } from "src/store/global/message-store";

import { ModalStore } from "./modal-store";
import { NavigationStore } from "./navigation-store";
import { SessionStore } from "./session-store";
import { TenantStore } from "./tenant-store";

export interface IStoreGlobal {
    $message: MessageStore;
    $i18n: I18nStore;
    $modal: ModalStore;
    $navigation: NavigationStore;
    $session: SessionStore;
    $tenant: TenantStore;
}

export function initGlobalStore(rootStore: Store): IStoreGlobal {
    return {
        $message: new MessageStore(rootStore),
        $i18n: new I18nStore(rootStore),
        $modal: new ModalStore(),
        $navigation: new NavigationStore(rootStore),
        $session: new SessionStore(rootStore),
        $tenant: new TenantStore(rootStore),
    };
}
