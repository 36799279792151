import { objectToFormData } from "src/lib/object-to-formdata";
import { IAgentV1Read } from "src/services";
import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export interface IOffice {
    uuid: string | undefined;
    community?: string;
    title: string;
    created?: string;
    updated?: string;
}

export interface IOfficeLease {
    uuid: string;
    office: IOffice;
    communityLease: string;
    leasor?: IAgentV1Read;
    leasee?: string;
    publicName: string;
    activates: string;
    expires?: string;
    created: string;
    updated: string;
}

export class OfficeLeasesService extends Service {
    public list(params: IQueryParams) {
        params = Object.assign(params, { expand: ["leasor", "office"] });
        const url = this.client.url(["api", "v1", "office-leases"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            | IApiResponse<IPaginatedBody<IOfficeLease>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public retrieve(officeLeaseID: string) {
        const params = { expand: ["leasor", "office"] };
        const url = this.client.url(["api", "v1", "office-leases", officeLeaseID], params);

        return this.client.get<
            IApiResponse<IOfficeLease, HttpStatus.Ok> | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public update(officeLeaseID: string, resource: { activates: string; expires: string }) {
        const url = this.client.url(["api", "v1", "office-leases", officeLeaseID]);

        return this.client.patch<
            | IApiResponse<IOfficeLease, HttpStatus.Ok>
            | IApiResponse<ValidationError<IOfficeLease>, HttpStatus.BadRequest>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url, objectToFormData(resource));
    }
}
