import { i18n, Messages } from "@lingui/core";
import { detect, fromUrl, fromStorage, fromNavigator } from "@lingui/detect-locale";
import Debug from "debug";
import * as plurals from "make-plural/plurals";
import { action, observable } from "mobx";

import { isInternalEnvironment } from "src/lib/environment";
import { loads } from "src/lib/loading";
import { Locale } from "src/locales/locale";
import { Store } from "src/store";
import { BaseStore } from "src/store/base-store";
import { readState } from "src/store/lib/read-state";
import { store } from "src/store/lib/store";

import type { Locale as DateLocale } from "date-fns";

const debug = Debug("tmpl:store/i18n");

const localStorageKey = "__tmpl-manager-locale";

export const defaultLocale = Locale.English;

@store
export class I18nStore extends BaseStore {
    public static readonly LOADING_LOCALE = Symbol("Loading locale");

    @observable
    public locale: Locale;

    @observable
    public debug = false;

    private onActivateLocaleCb?: (locale: Locale, dateLocale: DateLocale) => Promise<void>;

    public get i18n() {
        return i18n;
    }

    constructor(root: Store) {
        super(root);

        // Preload all pluralization data, it's very small anyway
        for (const locale of Object.values(Locale)) {
            i18n.loadLocaleData({
                [locale]: { plurals: plurals[locale] ?? plurals[locale.split("-")[0]] },
            });
        }

        const locale = detect(
            fromUrl("lang"),
            fromStorage(localStorageKey),
            fromNavigator()
        ) as Locale | null;

        if (locale) {
            if (this.validateLocale(locale)) {
                this.locale = locale;
                debug(`Detected locale ${this.locale}.`);
            } else {
                debug(`Detected unknown locale ${locale}, falling back to ${defaultLocale}.`);
                this.locale = defaultLocale;
            }
        } else {
            debug(`Failed to detect locale, using default ${defaultLocale}.`);
            this.locale = defaultLocale;
        }
    }

    /**
     * Activate a locale. This will lazy load the locale files, activate the locale and optionally
     * store it.
     *
     * @param locale The locale to activate. Defaults to the locale detected in the constructor.
     * @param storeLocale Store the locale in local storage so it'll be the new default locale.
     */
    @action.bound
    @loads(() => I18nStore.LOADING_LOCALE)
    async activateLocale(locale: Locale = this.locale, storeLocale: boolean = false) {
        debug(`Activating locale ${locale} ...`);

        this.locale = locale;

        if (storeLocale) {
            window.localStorage.setItem(localStorageKey, locale);
        }

        // Note, this intentionally avoids using an import expression to reduce the
        // build time and number of generated files.
        let awaitingLocale: [Promise<{ messages: Messages }>, Promise<{ default: DateLocale }>];

        switch (locale) {
            case Locale.Swedish:
                awaitingLocale = [import(`src/locales/sv/messages`), import(`date-fns/locale/sv`)];
                break;
            case Locale.English:
                awaitingLocale = [
                    import(`src/locales/en/messages`),
                    import(`date-fns/locale/en-GB`), // Default to en-GB date formats for english
                ];
                break;
            case Locale.BrazilianPortuguese:
                awaitingLocale = [
                    import(`src/locales/pt-BR/messages`),
                    import(`date-fns/locale/pt-BR`),
                ];
                break;
        }

        const [{ messages }, { default: dateLocale }] = await Promise.all(awaitingLocale);

        // Linguijs
        i18n.load(locale, this.wrapMessages(messages));
        i18n.activate(locale);

        // Date formatters
        window.__mngrLocale__ = dateLocale;

        await this.onActivateLocaleCb?.(locale, dateLocale);

        debug(`Successfully activated locale ${this.locale}!`);
    }

    private wrapMessages(messages: Messages) {
        if (isInternalEnvironment()) {
            return new Proxy(messages, {
                get: (messages, name, receiver) => {
                    if (readState(() => this.debug)) {
                        return name.toString();
                    }

                    return Reflect.get(messages, name, receiver);
                },
            });
        }

        return messages;
    }

    private validateLocale(locale: string): locale is Locale {
        return Object.values(Locale).includes(locale as Locale);
    }

    /**
     * Callback is used to register anything depending on locale,
     * returns the loaded locale and locale from date-fns.
     * Used to init react-datepicker locale. But is injected
     * like this because we do not want the store to have react-datepicker
     * as dependency
     */
    public onActivateLocale(cb: (locale: Locale, dateLocale: DateLocale) => Promise<void>) {
        this.onActivateLocaleCb = cb;
    }

    @action
    public setDebug(debug: boolean) {
        this.debug = debug;
    }
}
