import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface ITagSubTypeRead {
    id: string;
    name: string;
    label: string;
}

export class TagSubTypeService extends Service {
    public list = async (params?: IQueryParams) => {
        const url = this.client.url(["api", "v1", "tag-sub-types"], params);
        return this.client.get<
            | IApiResponse<IPaginatedResponseV1<ITagSubTypeRead>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    };

    public all = this.createListTraverser(this.list);
}
