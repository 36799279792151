import { API_CORE_TENANT_NAME, API_CORE_PUBLIC_URL } from "src/config/environment";
import { IOrganizations } from "src/services";
import { IApiResponseMap, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";

export interface IWhiteLabelRead {
    uuid: string;
    domainUrl: string;
    name: string;
    organization: IOrganizations;
    type: "home" | "work";
    defaultIssueHandler: null | string;
}

export class WhiteLabelService extends Service {
    public async fetchCurrent() {
        const url = this.client.url(
            [API_CORE_PUBLIC_URL, "api", "v1", "white-labels", API_CORE_TENANT_NAME],
            { expand: "organization" }
        );
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IWhiteLabelRead;
            }>
        >(url);
    }
}
