import { API_CORE_CLIENT_ID, API_CORE_PUBLIC_URL } from "src/config/environment";
import { IAuthToken } from "src/services/auth-token";
import { IApiResponseMap, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";

export interface IAuthenticateResponse extends IAuthToken {
    tokenType: "Bearer";
    scope: string;
    baseUrl: string;
    ecommerceUrl: string;
    hasClient: boolean;
}

interface IAuthenticateError {
    error: string;
}

const createFormData = (obj: { [key: string]: string | Blob }) =>
    Object.keys(obj).reduce((form, key) => {
        form.set(key, obj[key]);
        return form;
    }, new FormData());

export class AuthService extends Service {
    public async authenticate(code: string, redirectUri: string) {
        const url = this.client.url([API_CORE_PUBLIC_URL, "api", "v1", "auth", "login"]);

        const body = createFormData({
            grantType: "authorization_code",
            clientId: API_CORE_CLIENT_ID,
            code,
            // Not sure why this would be needed - the redirect is already done when redeeming
            // the code - but the backend gets much happier if it's included in the request.
            redirectUri,
        });

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Ok]: IAuthenticateResponse;
                [HttpStatus.BadRequest]: IAuthenticateError;
            }>
        >(url, body, {
            expectedStatus: [HttpStatus.Ok, HttpStatus.BadRequest, HttpStatus.Unauthorized],
        });
    }
}
