import { API_CORE_PUBLIC_URL } from "src/config/environment";
import { HttpStatus, IApiResponse } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginationStoreParams } from "src/store/_generic";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export enum AuthMethodIdentifier {
    Password = "password",
    SeBankId = "se_bank_id",
    Token = "token",
    MicrosoftAd = "microsoft_ad",
}

export interface IAuthMethodRead<T extends AuthMethodIdentifier> {
    uuid: string;
    label: string;
    identifier: T;
}

export enum ClientAuthMethodUsedFor {
    Login = "login",
    SignUp = "signup",
}

interface IAuthProviderSettings {
    [AuthMethodIdentifier.Password]: Record<string, never>;
    [AuthMethodIdentifier.SeBankId]: Record<string, never>;
    [AuthMethodIdentifier.Token]: Record<string, never>;
    [AuthMethodIdentifier.MicrosoftAd]: {
        msadApiSettings: {
            authority: string;
            clientId: string;
        };
    };
}

export interface IClientAuthMethodRead<T extends AuthMethodIdentifier = AuthMethodIdentifier> {
    uuid: string;
    authMethod: IAuthMethodRead<T>;
    isPrimary: boolean;
    usedFor: ClientAuthMethodUsedFor[];
    provider: string;
    providerSettings: IAuthProviderSettings[T];
}

type IListClientAuthMethodContextQueryParams = {
    wl: string;
} & ({ application: string } | { roles: string[] });

type IListClientAuthMethodQueryParams = IListClientAuthMethodContextQueryParams &
    IPaginationStoreParams;

export class ClientAuthMethodService extends Service {
    public list(params: IListClientAuthMethodQueryParams) {
        const url = this.client.url(
            [API_CORE_PUBLIC_URL, "api", "v1", "auth", "client-auth-methods"],
            { ...params, expand: "auth_method" }
        );
        return this.client.get<
            IApiResponse<
                IPaginatedResponseV1<IClientAuthMethodRead<AuthMethodIdentifier>>,
                HttpStatus.Ok
            >
        >(url);
    }

    public all = (contextParams: IListClientAuthMethodContextQueryParams) =>
        this.createListTraverser((paginationParams) =>
            this.list({ ...paginationParams, ...contextParams })
        )();
}
