import { observable, action, computed } from "mobx";
import * as qs from "querystringify";

import { IApiResponseMap, HttpStatus } from "src/services/client";
import {
    IPaginationServiceEndpoint,
    IPaginationStoreParams,
} from "src/store/_generic/pagination-store";
import { observed } from "src/store/lib/observed";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

type IServiceEndpointResponse<T> = Promise<
    IApiResponseMap<{
        [HttpStatus.Ok]: IPaginatedResponseV1<T>;
    }>
>;

export const MAX_PARALLELL_REQUESTS = 3;
export const FILE_PLACEHOLDER = Symbol("FILE_PLACEHOLDER");
export type FilePlaceholder = typeof FILE_PLACEHOLDER;

@observed
export class PaginationStoreScroll<T> {
    private static DEFAULT_PAGE_SIZE = 10;

    @observable
    public count?: number;

    @computed
    public get results() {
        return this.pages ? this.pages.flat() : undefined;
    }

    @computed
    public get pages(): (T[] | FilePlaceholder[])[] {
        if (this.count === undefined) {
            // Initial not loaded - assume we'll get the full page_size. We'll
            // return correct later.
            return [this.createPlaceholderPage(1)];
        }

        const pageEntries = [...this.loadedPages.entries()].sort(
            ([firstPageNr], [secondPageNr]) => firstPageNr - secondPageNr
        );

        if (pageEntries.length === 0) {
            return [];
        }

        const highestLoadingPageNr = [...this.pendingPages.keys()].sort().pop();
        const highestLoadedPageNr = [...this.loadedPages.keys()].sort().pop();
        const highestPageNr = Math.max(highestLoadingPageNr ?? 0, highestLoadedPageNr ?? 0);

        const pages = [];

        // Fill in the gaps with placeholders.
        // Note: we always create an additional placeholder page at the
        // end if we haven't reached the count.
        for (let i = 1; i <= highestPageNr + 1; i++) {
            const page = pageEntries.find(([pageNr]) => pageNr === i);

            if (page) {
                pages.push(page[1]);
            } else if (this.getPageSizeForPage(i) > 0) {
                pages.push(this.createPlaceholderPage(i));
            }
        }

        return pages;
    }

    @observable
    public params: IPaginationStoreParams = {};

    @observable
    private loadedPages: Map<number, (T | typeof FILE_PLACEHOLDER)[]> = new Map();

    private pendingPages: Map<number, IServiceEndpointResponse<T>> = new Map();

    public constructor(
        private serviceEndpoint: IPaginationServiceEndpoint<T>,
        private defaultParams: IPaginationStoreParams = {
            page_size: PaginationStoreScroll.DEFAULT_PAGE_SIZE,
        }
    ) {
        this.params = { ...this.defaultParams };

        if (window) {
            this.params = {
                ...this.params,
                ...(qs.parse(window.location.search) as IPaginationStoreParams),
            };
        }
    }

    public get pageSize() {
        return this.params.page_size || PaginationStoreScroll.DEFAULT_PAGE_SIZE;
    }

    @action.bound
    public async getInitial() {
        await this.clearPages();

        return this.getPage(1);
    }

    @action.bound
    public async getPage(page: number) {
        if (this.loadedPages.has(page) || this.pendingPages.has(page)) {
            return;
        }

        if (this.getPageSizeForPage(page) === 0) {
            throw new Error(`Page ${page} is out of range`);
        }

        const params = { ...this.params, page };
        const response = await this.requestPage(params);

        if (response.status === HttpStatus.Ok) {
            this.update(response.body, page);
        }
    }

    @action.bound
    public async reload() {
        return this.getInitial();
    }

    @action.bound
    public setParam(key: string, value: IQueryParamValue) {
        this.params[key] = value;
    }

    public getParam<P>(key: string, defaultValue?: P) {
        if (typeof defaultValue !== "undefined") {
            return this.params[key] || defaultValue;
        }
        return this.params[key];
    }

    public getFirstParam<P>(key: string, defaultValue?: P) {
        const value = this.getParam(key, defaultValue);
        if (Array.isArray(value)) {
            return value[0];
        }
        return value;
    }

    @action.bound
    public async reset() {
        await this.clearPages();
        this.params = { ...this.defaultParams };
    }

    @action
    private async clearPages() {
        if (this.pendingPages.size > 0) {
            // We wait for any pages currently loading so that a returning call don't
            // fill the pages Map after reset(). If needed implement a mechanism to
            // cancel the promises instead - then this method wouldn't need to be async.
            await Promise.all(this.pendingPages);
        }

        this.count = undefined;
        this.loadedPages.clear();
        this.pendingPages.clear();
    }

    @action
    private update(next: IPaginatedResponseV1<T>, page: number) {
        this.loadedPages.set(page, next.results);
        this.count = next.count;
    }

    private async requestPage(params: IPaginationStoreParams): IServiceEndpointResponse<T> {
        if (!params.page) {
            throw new Error("Attempted to make request without a page");
        }

        let promise;
        const page = params.page;

        if (this.pendingPages.size >= MAX_PARALLELL_REQUESTS) {
            promise = Promise.any(this.pendingPages).then(() => this.requestPage(params));
        } else {
            promise = this.serviceEndpoint(params).finally(() => this.pendingPages.delete(page));
        }

        this.pendingPages.set(page, promise);

        return promise;
    }

    private getPageSizeForPage(page: number) {
        const nrItems = page * this.pageSize;

        if (this.count === undefined || nrItems <= this.count) {
            return this.pageSize;
        }

        if (nrItems > this.count + this.pageSize) {
            return 0;
        }

        return this.count % this.pageSize;
    }

    private createPlaceholderPage(pageNr: number) {
        return Array(this.getPageSizeForPage(pageNr)).fill(FILE_PLACEHOLDER);
    }
}

export default PaginationStoreScroll;
