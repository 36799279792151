import { API_CORE_PUBLIC_URL } from "src/config/environment";
import { HttpStatus, IApiResponse } from "src/services/client";
import { Service } from "src/services/service";

export interface IPasswordResetCreate {
    email: string;
    application: string;
}

export class PasswordResetService extends Service {
    public create(data: IPasswordResetCreate) {
        const url = this.client.url([API_CORE_PUBLIC_URL, "api", "v1", "auth", "reset-password"]);
        return this.client.post<IApiResponse<unknown, HttpStatus.NoContent>>(url, data);
    }
}
