import { computed, action } from "mobx";
import { RouterStore } from "mobx-react-router";
import * as qs from "querystringify";

import { storeItemToJSON } from "src/lib/store-item-to-json";
import { store } from "src/store/lib/store";

interface IParams {
    [key: string]: string | undefined;
}

@store
export class ParamStore {
    constructor(private $router: RouterStore) {}

    @computed
    public get params(): IParams {
        return (qs.parse(this.$router.location.search) as IParams) || {};
    }

    @action
    public setParam(key: string, value: string | undefined) {
        const location = this.$router.location;
        const currentQuery = qs.parse(location.search);
        const params = { ...currentQuery, [key]: value };
        this.$router.push(location.pathname + qs.stringify(params, true));
    }

    public toJSON() {
        return storeItemToJSON(this);
    }
}
