import { action, observable } from "mobx";

import { observed } from "src/store/lib/observed";
import { IDataTableColumnDef } from "src/types/data-table";

@observed
export class ListOptionsStore<Type> {
    @observable
    private _columns: IDataTableColumnDef<Type>[];

    private _initialValues: IDataTableColumnDef<Type>[];

    constructor(columns: IDataTableColumnDef<Type>[] = []) {
        this._columns = columns;
        this._initialValues = columns;
    }

    public get columns() {
        return this._columns;
    }

    @action
    public setColumns(columns: IDataTableColumnDef<Type>[]) {
        this._columns = columns;
    }

    @action
    public setInitialColumns(columns: IDataTableColumnDef<Type>[]) {
        this._initialValues = columns;
    }

    @action
    public reset() {
        this._columns = this._initialValues;
    }

    @action
    public toggleHideColumn(name: string) {
        this._columns = this._columns.map((column) => {
            return column.name === name ? { ...column, hidden: !column.hidden } : column;
        });
    }

    @action
    public reorderColumn(sorting: string, sibling: string) {
        const indexOf = (value: string) => this.columns.findIndex(({ name }) => name === value);
        const isValid = (index: number) => 0 <= index && index < this.columns.length;
        const from = indexOf(sorting);
        const to = indexOf(sibling);
        if (from === to) {
            return;
        }
        if (!isValid(from) || !isValid(to)) {
            return;
        }
        this._columns.splice(to, 0, ...this._columns.splice(from, 1));
        this._columns = [...this._columns];
    }

    public onResizeColumn(name: string, width: number) {
        const column = this._columns.find((column) => column.name === name);
        if (!column) return;
        column.width = width;
        column.minWidth = width;
    }
}
