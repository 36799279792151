import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export enum IPublicCardTypeIndentifier {
    BoardInfo = "board info",
    Board = "board",
    Community = "community",
    Emergency = "emergency",
    Event = "event",
    Marketplace = "marketplace",
    MarketplaceForFree = "marketplace for free",
    MarketplaceBuy = "marketplace buy",
    Voting = "voting",
    TmplAdmin = "tmpl admin",
    Survey = "survey",
}

interface IPublicCardTypeRawV1Read {
    uuid: string;
    name: string;
    icon: string;
    identifier: IPublicCardTypeIndentifier;
    fieldStructure: { [key in IPublicCardFieldItemV1]?: unknown };
}

export interface IPublicCardTypeV1Read extends IPublicCardTypeRawV1Read {
    fields: IPublicCardFieldItemV1[];
}

export type IPublicCardFieldItemV1 =
    | "address"
    | "endTime"
    | "price"
    | "priceCurrency"
    | "startTime"
    | "title"
    | "longitude"
    | "latitude";

export class PublicCardTypeService extends Service {
    public async list(params?: IQueryParams) {
        params = { ...params, sort: "position" };
        const url = this.client.url(["api", "v1", "public-card-types"], params);

        const response = await this.client.get<
            IApiResponse<IPaginatedResponseV1<IPublicCardTypeRawV1Read>, HttpStatus.Ok>
        >(url);

        return this.client.hydrateBody(response, (body) => ({
            ...body,
            results: body.results.map((cardType) => ({
                ...cardType,
                fields: Object.keys(cardType.fieldStructure) as IPublicCardFieldItemV1[],
            })),
        })) as IApiResponse<IPaginatedResponseV1<IPublicCardTypeV1Read>, HttpStatus.Ok>;
    }
}
