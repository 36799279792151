/**
 * Remove all keys that have a value in the given array
 *
 * @example
 * filter({ foo: undefined, bar: 1, baz: null }, undefined, null)
 * // Becomes { bar: 1 }
 */
export function filter<T, U>(
    obj: T,
    ...values: U[] | U[][]
): { [key in keyof T]?: Exclude<T[key], U> } {
    const flatValues = values.flat();

    return Object.entries(obj)
        .filter(([_, value]) => !flatValues.includes(value))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {} as any);
}

/**
 * Remove all keys that have an undefined value
 *
 * @example
 * filterUndefinedObjectValues({ "foo": undefined, "bar": "baz" })
 * // Becomes { "bar": "baz" }
 */
export function filterUndefinedObjectValues<T>(obj: T): {
    [key in keyof T]?: Exclude<T[key], undefined>;
} {
    return filter(obj, undefined);
}

/**
 * Returns a subset of the given object containing only the keys given in the
 * array.
 *
 * @example
 * pick({ foo: 1, bar: 2, baz: 3 }, ["foo", "baz"])
 * // Becomes { foo: 1, baz: 3 }
 */
export function pick<T extends ObjectOf<T>, V extends keyof T>(object: T, keys: V[]) {
    return keys.reduce<Pick<T, V>>((subObject, key) => {
        if (object && object.hasOwnProperty(key)) {
            subObject[key] = object[key];
        }
        return subObject;
    }, {} as any);
}

export const insertAt = (path: (string | symbol)[], target: any, source: any) => {
    if (path.length === 0) {
        return target;
    }
    const heads = path.slice(0, path.length - 1);
    const lastSegment = path[path.length - 1];
    const root = { ...(target ?? {}) };
    let e = root;
    heads.forEach((segment) => {
        if (!(segment in e)) {
            e[segment] = {};
        }
        e = e[segment];
    });
    e[lastSegment] = source;
    return root;
};

const mapSnd =
    <T, U, V>(fn: (value: U) => V) =>
    ([a, b]: [T, U]) =>
        [a, fn(b)] as const;

export const deepCopyOverride = Symbol("copy");

export const deepCopy = <T>(value: T): T => {
    // All falsy values are immutable (except document.all, but we will ignore that)
    if (!value) return value;
    if (typeof value !== "object") {
        // Note; functions will not be cloned, but are mutable
        return value;
    }
    if (deepCopyOverride in value) {
        return value[deepCopyOverride]();
    } else if (value instanceof Array) {
        return value.map(deepCopy) as unknown as T;
    } else if (value instanceof Set) {
        return new Set([...value].map(deepCopy)) as unknown as T;
    } else if (value instanceof Map) {
        return new Map([...value].map(mapSnd(deepCopy))) as unknown as T;
    } else if (value instanceof Date) {
        return new Date(value.getTime()) as unknown as T;
    } else if (value instanceof File) {
        return new File([value], value.name) as unknown as T;
    } else if ((value as any).__proto__ === Object.prototype) {
        return Object.fromEntries(Object.entries(value).map(mapSnd(deepCopy))) as unknown as T;
    }
    const stringified = JSON.stringify(value);
    if (stringified === undefined) {
        // an object can override toJSON and return undefined
        // and when it does then JSON.parse gets sad. :(
        return undefined as unknown as T;
    }
    return JSON.parse(stringified);
};
