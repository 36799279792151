import { FormValue } from "src/models/form-value";
import { IGroupRoleRead, ILeaseRead } from "src/services";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export enum PremiseLeaseInvitationType {
    Personal = "personal",
    Open = "open",
}

export enum PremiseLeaseInvitationStatus {
    Pending = "pending",
    Accepted = "accepted",
    Expired = "expired",
}

export interface IPremiseLeaseInvitationBase {
    allAcceptedUsers: string[];
    uuid: string;
    status: PremiseLeaseInvitationStatus;
    host: string;
    role: string | IGroupRoleRead;
    lease: string | ILeaseRead;
    token: string;
    maxUsageCount: number | null;
    nationalIdentityNumberHashed: string | null;
    nationalIdentityNumberMasked: string | null;
    requireExactEmailOnSignup: boolean | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    created: string;
    updated: string;
    expires?: string;
    // Before the invitation is sent it has the same value as `created`.
    // `sent` is always set, even if the invitation was never sent as an email.
    sent: string;
}

export type IPremiseLeaseInvitationRead = Expand<IPremiseLeaseInvitationBase, "role" | "lease">;

export interface IPremiseLeaseInvitationPersonalCreate {
    email: string | null;
    role: FormValue<IGroupRoleRead> | null;
    lease: FormValue<ILeaseRead> | null;
    nationalIdentityNumber: string | null;
    requireExactEmailOnSignup: boolean;
}

export interface IPremiseLeaseInvitationOpenCreate {
    role: FormValue<IGroupRoleRead> | null;
    lease: FormValue<ILeaseRead> | null;
    maxUsageCount: number | null;
}

export type IPremiseLeaseInvitationCreate =
    | IPremiseLeaseInvitationPersonalCreate
    | IPremiseLeaseInvitationOpenCreate;

export class PremisesLeaseInvitationService extends Service {
    public async list(params?: IQueryParams) {
        params = { ...params, expand: ["role", "lease"] };

        const url = this.client.url(["api", "v1", "premises-lease-invitations"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<IPremiseLeaseInvitationRead>;
            }>
        >(url);
    }

    public async listForPremiseLease(leaseId: string, params?: IQueryParams) {
        params = { ...params, expand: ["role", "lease"] };

        const url = this.client.url(["api", "v1", "leases", leaseId, "invitations"], params);

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedBody<IPremiseLeaseInvitationRead>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async create(data: IPremiseLeaseInvitationCreate) {
        const url = this.client.url(["api", "v1", "premises-lease-invitations"]);

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: Collapse<IPremiseLeaseInvitationBase, "host">;
                [HttpStatus.BadRequest]: ValidationError<IPremiseLeaseInvitationCreate>;
            }>
        >(url, data);
    }

    public delete(id: string) {
        const url = this.client.url(["api", "v1", "premises-lease-invitations", id]);
        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: undefined;
                [HttpStatus.BadRequest]: ValidationError<IPremiseLeaseInvitationCreate>;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public resend(id: UUID) {
        const url = this.client.url(["api", "v1", "premises-lease-invitations", id, "resend"]);
        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.NoContent]: undefined;
                [HttpStatus.BadRequest]: IGenericApiError;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, {});
    }
}
