import { IApiResponseMap, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";

export enum IAdminRoles {
    BuildingAdministrator = "BA",
    WhitelabelAdministrator = "WLA",
    CommunityAdministrator = "CA",
    SuperUser = "SU",
    PropertyStaff = "PS",
}

export interface ICurrentUser {
    id: number;
    uuid: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    adminRoles: IAdminRoles[];
    onboardingCompleted: boolean;
    building: {
        id: number;
        title: string;
    };
}

export class SessionService extends Service {
    public check() {
        const path = this.client.url(["manager", "current-user"]);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: ICurrentUser;
                [HttpStatus.Unauthorized]: void;
            }>
        >(path, { expectedStatus: [HttpStatus.Ok, HttpStatus.Unauthorized] });
    }
}
