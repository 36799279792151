import { objectToFormData } from "src/lib/object-to-formdata";
import { IApiResponseMap, HttpStatus, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IUserRead } from "src/services/services/user-service";
import { IPaginationStoreParams } from "src/store/_generic";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export enum IssueAutoAllocationType {
    CommunityResponsible = "community_responsible",
    UserRealEstateResponsible = "user_real_estate_responsible",
    UserBuildingResponsible = "user_building",
    WlResponsible = "wl_responsible",
    Handler = "handler",
    Mail = "mail",
    Url = "url",
}

export interface IIssueCategoryBase {
    uuid: string;
    title: string;
    description: string;
    icon: string;
    customFieldLabel?: string;
    customFieldPlaceholder?: string;
    customFieldOptions?: string[] | null;
    notificationScope?: string;
    autoAllocationType: IssueAutoAllocationType | null;
    /**
     * The handler if autoAllocationType === IssueAutoAllocationType.Handler
     */
    autoAllocationHandler?: string | IUserRead | null;
    /**
     * The email to mail to when autoAllocationHandler === IssueAutoAllocationType.Mail
     */
    mailTo?: string | null;
    /**
     * The URL to visit when autoAllocationHandler === IssueAutoAllocationType.Url
     */
    url?: string | null;
}

export type IIssueCategoryRead = Expand<IIssueCategoryBase, "autoAllocationHandler">;

export type IIssueCategoryError = ValidationError<IIssueCategoryRead> & {
    name: string[];
};

export interface IIssueCategoryUpdate extends IIssueCategoryCreate {
    uuid: string;
    autoAllocationHandler?: string | IUserRead | null;
}
export interface IIssueCategoryCreate extends Omit<IIssueCategoryBase, "icon" | "uuid"> {
    icon?: File | string | null;
    includeOptions?: boolean;
    autoAllocationHandler?: string | IUserRead | null;
}

const defaultParams = { expand: ["auto_allocation_handler"] };

export class IssueCategoryService extends Service {
    public list(params: IPaginationStoreParams) {
        params = { ...params, ...defaultParams };

        const url = this.client.url(["api", "v2", "issue-categories"], params, {
            applyTagFilter: true,
        });

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IIssueCategoryRead>;
            }>
        >(url);
    }

    public listAll(params: IPaginationStoreParams) {
        params = { ...params, ...defaultParams };

        const url = this.client.url(["api", "v2", "issue-categories"], params);

        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IIssueCategoryRead>;
            }>
        >(url);
    }

    public readonly all = this.createListTraverser<IIssueCategoryRead>((params) =>
        this.listAll(params)
    );

    public retrieve = (categoryId: UUID, params?: IQueryParams) => {
        params = { ...params, ...defaultParams };

        const url = this.client.url(["api", "v2", "issue-categories", categoryId], params);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IIssueCategoryRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    };

    public update(
        categoryId: string,
        data: Partial<
            Omit<IIssueCategoryCreate, "icon"> & {
                icon?: File | string | null;
            }
        >,
        useFormatData = true
    ) {
        const url = this.client.url(["api", "v2", "issue-categories", categoryId], {
            ...defaultParams,
        });

        const resource = useFormatData ? objectToFormData(data) : data;

        return this.client.patch<
            IApiResponseMap<{
                [HttpStatus.Ok]: IIssueCategoryRead;
                [HttpStatus.BadRequest]: IIssueCategoryError;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, resource);
    }

    public create(resource: IIssueCategoryCreate) {
        const url = this.client.url(["api", "v2", "issue-categories"], {
            ...defaultParams,
        });

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IIssueCategoryRead;
                [HttpStatus.BadRequest]: IIssueCategoryError;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url, objectToFormData(resource));
    }

    public delete(id: string) {
        const url = this.client.url(["api", "v2", "issue-categories", id]);

        return this.client.delete<
            IApiResponseMap<{
                [HttpStatus.NoContent]: void;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }
}
