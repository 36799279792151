import { observable, action } from "mobx";

import { API_CORE_TENANT_NAME, FORCE_ONBOARDING_TENANTS } from "src/config/environment";
import { isInternalEnvironment } from "src/lib/environment";
import { store } from "src/store/lib/store";

function asTokens<T extends string>(tokens: T[]) {
    return tokens;
}

const availableFeatures = asTokens([
    "forceOnboarding",
    "newLibraryView",
    "alterClientIssueHandler",
    "showTenantOverviewCountsInNavigation",
    "showSurvey",
    "newResourcesView",
    "enhancedAgentInvitationsView",
]);
type Feature = typeof availableFeatures[0];

type IFeatures = Set<Feature>;

const sessionStorageKey = "__tmpl-manager-features";

@store
export class FeatureStore {
    public readonly availableFeatures: Readonly<typeof availableFeatures> = availableFeatures;

    @observable
    private _features: IFeatures = new Set();

    constructor() {
        this.setupStaticFeatures();
    }

    private setupStaticFeatures() {
        if (!this.restore()) {
            this.enableByCondition(
                "forceOnboarding",
                FORCE_ONBOARDING_TENANTS.includes(API_CORE_TENANT_NAME)
            );

            this.disable("showTenantOverviewCountsInNavigation");
            this.enable("showSurvey");
        }
    }

    @action
    public enable(key: Feature, store = true) {
        this._features.add(key);

        if (store) {
            this.serialize();
        }
    }

    @action
    public disable(key: Feature, store = true) {
        this._features.delete(key);

        if (store) {
            this.serialize();
        }
    }

    @action
    public enableByCondition(key: Feature, condition = true) {
        if (Boolean(condition)) {
            this.enable(key, false);
        }
    }

    private serialize() {
        if (isInternalEnvironment()) {
            window.sessionStorage.setItem(sessionStorageKey, JSON.stringify([...this._features]));
        }
    }

    @action
    private restore() {
        if (isInternalEnvironment()) {
            const storedFeatures = window.sessionStorage.getItem(sessionStorageKey);

            if (storedFeatures) {
                this._features = new Set(JSON.parse(storedFeatures));

                return true;
            }
        }

        return false;
    }

    public enabled(key: Feature) {
        return this._features.has(key);
    }

    public disabled(key: Feature) {
        return !this.enabled(key);
    }
}
