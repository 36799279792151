import { objectToFormData } from "src/lib/object-to-formdata";
import { IApiResponse, HttpStatus } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedBody } from "src/types/paginated-response";

export enum IInvoicingMethods {
    Pdf = "pdf",
    Peppol = "peppol",
}

export interface IInvoicingMethod {
    id?: number;
    user: number;
    activeMethod: IInvoicingMethods;
    pdfAddress: string;
    peppolAddress: string;
    isDefaultMethod: boolean;
}

export class InvoicingMethod extends Service {
    public list(params: IQueryParams) {
        const url = this.client.url(["api", "v1", "ecommerce", "v2", "invoicing-methods"], params);
        return this.client.get<
            | IApiResponse<IPaginatedBody<IInvoicingMethod>, HttpStatus.Ok>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url);
    }

    public update(resource: Partial<IInvoicingMethod>, invoicingMethodID: number) {
        const url = this.client.url([
            "api",
            "v1",
            "ecommerce",
            "v2",
            "invoicing-methods",
            invoicingMethodID,
        ]);
        return this.client.patch<
            | IApiResponse<IInvoicingMethod, HttpStatus.Ok>
            | IApiResponse<ValidationError<IInvoicingMethod>, HttpStatus.BadRequest>
            | IApiResponse<unknown, HttpStatus.NotFound>
        >(url, objectToFormData(resource));
    }
}
