import { t } from "@lingui/macro";
import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import isSameDay from "date-fns/isSameDay";
import isSameYear from "date-fns/isSameYear";
import parseISO from "date-fns/parseISO";

import { exhaustiveCheck } from "src/lib/exhaustive-check";

export enum DateFormat {
    /**
     * @example
     * "27 apr"
     */
    DateOnly = "DateOnly",

    /**
     * @example
     * "2019-04-27"
     */
    Short = "Short",

    /**
     * @example
     * "27 apr 2019"
     */
    Medium = "Medium",

    /**
     * @example
     * "Fre, 27 apr 2019"
     */
    Long = "Long",

    /**
     * Note: this is not a part of the standard date formats in manager
     * it's here since it's used in the design.
     *
     * @example
     * "Fredag 27 april"
     */
    Colloquial = "Colloquial",
}

export enum TimeFormat {
    /**
     * @example
     * "18.00"
     */
    TimeOnly = "TimeOnly",
}

/**
 * Formats and localizes a date according to the given format.
 *
 * @example
 * const date = new Date(2019, 3, 27, 10, 30)
 * formatDate(date, DateFormat.Short); // 2021-09-22
 * formatDate(date, DateFormat.Short, true); // 2021-09-22 kl. 10:30
 * formatDate(date, DateFormat.Short, TimeFormat.TimeOnly); // 2021-09-22 kl. 10:30
 * formatDate(date, TimeFormat.TimeOnly); // 10:30
 *
 * @param date Subject date, as a date object or ISO date string
 * @param format Date or time format to use
 * @param includeTime If a date format is given, append the time using this time format.
 * @returns formatted date
 */
export function formatDate(
    date: Date | string,
    dateFormat: DateFormat,
    includeTime?: TimeFormat | boolean
): string;
export function formatDate(
    date: Date | string,
    dateformat: DateFormat | TimeFormat,
    includeTime?: undefined
): string;
export function formatDate(
    date: Date | string,
    dateformat: DateFormat | TimeFormat = DateFormat.Medium,
    includeTime?: TimeFormat | boolean
) {
    date = parseDate(date);

    // Default includeTime to true if a date format is given
    includeTime = includeTime ?? dateformat in DateFormat;
    const locale = window.__mngrLocale__;
    try {
        if (includeTime) {
            const timeFormat = typeof includeTime === "boolean" ? TimeFormat.TimeOnly : includeTime;
            return format(
                date,
                t({
                    id: "global.date.date-with-time",
                    values: {
                        dateFormat: getDateTemplateFormat(dateformat),
                        timeFormat: getDateTemplateFormat(timeFormat),
                    },
                }),
                { locale }
            );
        }
        return format(date, getDateTemplateFormat(dateformat), { locale });
    } catch (error: unknown) {
        /**
         * As formatting a date depends on translations. If the translation
         * fails return default formatting instead of crashing as date formatter
         * will recieve faulty format string
         */
        return date.toISOString();
    }
}

/**
 * Creates a short readable date string
 *
 * @example
 * const date1 = new Date("2021-10-18 12:00:00+0000")
 * const date2 = new Date("2020-10-18 12:00:00+0000")
 *
 * shortenDate(date1) // '18 Oct'
 * shortenDate(date2) // '18 Oct 2020'
 *
 * @param date Source date
 * @returns readable date string
 */
export function shortenDate(date: string | Date) {
    date = parseDate(date);
    const now = new Date();

    return formatDate(date, isSameYear(date, now) ? DateFormat.DateOnly : DateFormat.Medium, false);
}

/**
 * Creates a formatted time range from two dates
 *
 * @example
 * const date1 = new Date("2020");
 * const date2 = new Date("2021");
 *
 * toFormattedDateTimeRange(date1, date2) // "2020-01-01 kl. 01:00 - 2021-01-01 kl. 01:00"
 * toFormattedDateTimeRange(date1, date2, true) // "1 jan. 2020 kl. 01:00 - 1 jan. 2021 kl. 01:00"
 * toFormattedDateTimeRange(date1, date2, true, true) // "ons 1 jan. 2020 kl. 01:00 - fre 1 jan. 2021 kl. 01:00"
 *
 * @param start start date
 * @param end start date
 * @param prettyDate Format the date in an easy to read format
 * @param includeDay Include the day name in the date along with the number
 * @returns formatted time range
 */
export function toFormattedDateTimeRange(
    start: Date | string,
    end: Date | string,
    dateFormat: DateFormat = DateFormat.Short
) {
    start = typeof start === "string" ? parseDate(start) : assertValidDate(start);
    end = typeof end === "string" ? parseDate(end) : assertValidDate(end);

    if (isSameDay(start, end)) {
        return t({
            id: "global.date.formatted-date-time-range-same-day",
            values: {
                date: formatDate(start, dateFormat, false),
                timeStart: formatDate(start, TimeFormat.TimeOnly),
                timeEnd: formatDate(end, TimeFormat.TimeOnly),
            },
        });
    }

    return `${formatDate(start, dateFormat, true)} - ${formatDate(end, dateFormat, true)}`;
}

/**
 * Returns the time since the given date in a human readable format
 *
 * @param date Subject date
 * @returns A strings with the time since in a human readable format
 */
export function timeSince(date: Date | string) {
    date = typeof date === "string" ? parseDate(date) : assertValidDate(date);

    const locale = window.__mngrLocale__;
    const daysDiff = differenceInDays(date, new Date());

    if (daysDiff >= 1 || daysDiff < 0) {
        return formatDate(date, DateFormat.Medium);
    }

    return formatDistanceToNow(date, { locale });
}

/* ISO (non localized) date formatters */

/**
 * Formats the time part of a Date object to a ISO compatible (HH:mm) time string.
 * Uses the local timezone and should never be used in services or submitted data.
 *
 * @param date Input date
 * @returns ISO compatible time string
 */
export function toIsoTimeString(date: Date) {
    assertValidDate(date);

    const hours = `0${date.getHours()}`.substr(-2);
    const minutes = `0${date.getMinutes()}`.substr(-2);
    return `${hours}:${minutes}`;
}

/**
 * Formats the date part of a Date object to a ISO compatible (yyyy-MM-dd) date string.
 * Uses the local timezone and should never be used in services or submitted data.
 *
 * @param date Input date
 * @returns ISO compatible date string
 */
export function toIsoDateString(date: Date): string {
    assertValidDate(date);

    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);

    return `${date.getFullYear()}-${month}-${day}`;
}

/**
 * Formats a Date object to a ISO compatible (yyyy-MM-ddTHH:mm) date string.
 * Uses the local timezone and should never be used in services or submitted data.
 *
 * @param date Input date
 * @returns ISO compatible date string
 */
export function toIsoDateTimeString(date: Date) {
    assertValidDate(date);

    return `${toIsoDateString(date)}T${toIsoTimeString(date)}`;
}

/**
 * Formats the date part of a Date object to a ISO compatible (yyyy-MM-dd) date string
 * in UTC time zone.
 *
 * @param date Input date
 * @returns ISO compatible date string
 */
export function toUtcIsoDateString(date: Date): string {
    assertValidDate(date);

    return date.toISOString().split("T")[0];
}

/* Time constants */
export const DAYS_IN_WEEK = 7;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;

/* Utils */

/**
 * Returns the template string to use for a given date or time format
 * @see https://date-fns.org/v2.25.0/docs/format
 *
 * @param format Time or date format
 * @returns Template string
 */
function getDateTemplateFormat(format: DateFormat | TimeFormat) {
    switch (format) {
        case DateFormat.DateOnly:
            return t`global.date.format-date-only`;
        case DateFormat.Short:
            return t`global.date.format-short`;
        case DateFormat.Medium:
            return t`global.date.format-medium`;
        case DateFormat.Long:
            return t`global.date.format-long`;
        case DateFormat.Colloquial:
            return t`global.date.format-colloquial`;
        case TimeFormat.TimeOnly:
            return t`global.date.format-timeonly`;
        default:
            exhaustiveCheck(format);
            return "";
    }
}

/**
 * Asserts that a given date is valid, throws otherwise
 *
 * @param date Source
 */
export function assertValidDate(date: Date) {
    if (date.toString() === "Invalid Date") {
        throw new Error(`Given date is invalid!`);
    }

    return date;
}

/**
 * Parses an ISO date and asserts that it's valid
 *
 * @param date source date
 * @returns Date object from the date
 */
export function parseDate(date: string | Date) {
    const parsedDate = typeof date === "string" ? parseISO(date) : date;

    assertValidDate(parsedDate);

    return parsedDate;
}

/**
 * Merges the date from the first date given, with the time from the second date given.
 * @param date Date part
 * @param time Time part
 * @returns Combined date
 */
export function mergeDateAndTime(date: Date | null, time: Date | null) {
    if (date) {
        assertValidDate(date);
    }

    if (time) {
        assertValidDate(time);
    }

    date = date || new Date();
    const nextDateParams: [number, number, number, number, number] = [
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
    ];

    if (time) {
        nextDateParams[3] = time.getHours();
        nextDateParams[4] = time.getMinutes();
    }
    return new Date(...nextDateParams);
}

/* Deprecated */

/**
 * Creates a readable date string
 *
 * @deprecated
 * Call formatDate directly instead
 *
 * @example
 * const date = new Date("2021-10-18 12:00:00+0000")
 *
 * prettifyDate(new Date()) // '18 okt. 2021 kl. 14:00'
 * prettifyDate(new Date(), false) // '18 okt. 2021'
 * prettifyDate(new Date(), false, true) //'mån 18 okt. 2021'
 * prettifyDate(new Date(), true, true) //'mån 18 okt. 2021 kl. 14:00'
 *
 * @param date source date
 * @param includeTime If the resulting string should include the time
 * @param includeDay  If the resulting string should include the day name (along with the number)
 * @returns readable date string
 */
export function prettifyDate(date: string | Date, includeTime = true, includeDay = false) {
    const dateFormat = includeDay ? DateFormat.Long : DateFormat.Medium;

    return formatDate(date, dateFormat, includeTime);
}
