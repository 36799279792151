import Debug from "debug";
import { observable, action, runInAction, autorun, makeObservable } from "mobx";

import { delay } from "src/lib/delay";
import { isTestEnv } from "src/lib/environment";
import { Key } from "src/lib/keyboard";
import { storeItemToJSON } from "src/lib/store-item-to-json";
import { store } from "src/store/lib/store";
import { IModalType } from "src/types/modal-type";

const debug = Debug("tmpl:modal");

export interface IPromiseConfirmation {
    body: React.ReactNode;
    confirmText: React.ReactNode;
    loading?: () => boolean;
}

type internalModalProps = "closeModal";

export interface IConfirmation extends IPromiseConfirmation {
    onConfirm: () => void;
    onAbort?: () => void;
}

type ConfirmCloseModalCallback = (confirmation: IPromiseConfirmation) => Promise<boolean>;
export type ICanCloseCondition = (
    confirmCallback: ConfirmCloseModalCallback
) => boolean | Promise<boolean>;

export type ModalComponentClass = React.ComponentType<any> & IModalType;

export interface IActiveModal {
    type: ModalComponentClass;
    data?: ObjectOf<any>;
    confirmation?: IConfirmation;
    canCloseCondition?: ICanCloseCondition;
    closeModal: () => void;
    closing: boolean;
}

@store
export class ModalStore {
    @observable.shallow
    public active: IActiveModal[] = [];

    constructor() {
        makeObservable(this);
        autorun(() => {
            if (this.active.length > 0) {
                document.body.classList.add("modal-open");
            } else {
                document.body.classList.remove("modal-open");
            }
        });
        window.addEventListener("keyup", this.handleEscape);
    }

    @action.bound
    public open<B extends ModalComponentClass>(
        type: B,
        data?: Omit<GetComponentProps<B>, internalModalProps>
    ) {
        debug(`Opening modal "${type.displayName}"`);
        this.active.push(this.getModalDef(type, data));
    }

    @action.bound
    public openUnique<B extends ModalComponentClass>(
        type: B,
        data?: Omit<GetComponentProps<B>, internalModalProps>
    ) {
        if (!this.closestModal(type)) {
            debug(`Opening modal "${type.displayName}"`);
            this.active.push(this.getModalDef(type, data));
        }
    }

    public visibleModal() {
        if (this.active.length > 0) {
            return this.active[this.active.length - 1];
        }
        return undefined;
    }

    private closestModal(key: ModalComponentClass) {
        for (let i = this.active.length - 1; i >= 0; i--) {
            if (this.active[i].type === key) {
                return this.active[i];
            }
        }
        return undefined;
    }

    public confirm(key: ModalComponentClass, confirmation: IConfirmation): void;
    public confirm(key: ModalComponentClass, confirmation: IPromiseConfirmation): Promise<boolean>;
    @action.bound
    public confirm(
        key: ModalComponentClass,
        confirmation: IConfirmation | IPromiseConfirmation
    ): void | Promise<boolean> {
        const modal = this.closestModal(key);
        if (!modal) {
            throw this.modalNotFoundError(key);
        }
        if ("onConfirm" in confirmation) {
            modal.confirmation = confirmation;
        } else {
            return new Promise((resolve) => {
                modal.confirmation = {
                    ...confirmation,
                    onConfirm: () => {
                        this.cancelConfirm(key);
                        return resolve(true);
                    },
                    onAbort: () => resolve(false),
                };
            });
        }
    }

    @action.bound
    public cancelConfirm(key: ModalComponentClass) {
        const modal = this.closestModal(key);
        if (!modal) {
            throw this.modalNotFoundError(key);
        }
        modal.confirmation = undefined;
    }

    @action.bound
    public canClose(key: ModalComponentClass, canCloseCondition: ICanCloseCondition) {
        const modal = this.closestModal(key);
        if (!modal) {
            throw this.modalNotFoundError(key);
        }
        modal.canCloseCondition = canCloseCondition;
    }

    @action
    private closeModal(key: ModalComponentClass) {
        for (let i = this.active.length - 1; i >= 0; i--) {
            if (this.active[i].type === key) {
                this.active.splice(i, 1);
                return;
            }
        }
    }

    @action
    public pop() {
        const modal = this.visibleModal();
        if (modal) {
            this.close(modal.type);
        }
    }

    @action.bound
    public async close(key: ModalComponentClass, force?: boolean, immediately?: boolean) {
        const modal = this.closestModal(key);
        if (!modal) {
            throw this.modalNotFoundError(key);
        }

        if (modal.closing) {
            return;
        }

        if (!force && modal.canCloseCondition) {
            const canClose = modal.canCloseCondition((confirmConfig) =>
                this.confirm(key, confirmConfig)
            );
            const result = typeof canClose === "boolean" ? canClose : await canClose;
            if (!result) {
                return;
            }
        }
        debug(`Closing modal "${modal.type.displayName}"`);
        if (immediately) {
            this.cancelConfirm(key);
            this.closeModal(key);
        } else {
            runInAction(() => (modal.closing = true));
            // Skip delay on modals to speed up tests
            if (!isTestEnv()) {
                await delay(500);
            }
            runInAction(() => {
                this.cancelConfirm(key);
                this.closeModal(key);
                modal.closing = false;
            });
        }
    }

    private modalNotFoundError(key: ModalComponentClass) {
        return new Error(
            `Could not find active modal with key ${
                key.displayName || key.name || key
            }. If you are using a component wrapped with "withStore" you must always use the wrapped component when managing modals.`
        );
    }

    private getModalDef<B extends ModalComponentClass>(
        type: B,
        data?: Omit<GetComponentProps<B>, internalModalProps>
    ) {
        return observable.object(
            { type, data, closeModal: this.close.bind(this, type), closing: false },
            { type: false },
            { deep: false }
        );
    }

    private handleEscape = (e: KeyboardEvent) => {
        if (e.key === Key.Escape) {
            this.pop();
        }
    };

    public toJSON() {
        return storeItemToJSON(this);
    }
}
