import { IPublicCardV1Read } from "src/services";
import { IApiResponse, HttpStatus, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface IPublicCardCommentUserProfileV1Read {
    profileImage: string | null;
}

export interface IPublicCardCommentPersonUserV1Read {
    uuid: string;
    firstName?: string;
    lastName?: string;
    profile?: IPublicCardCommentUserProfileV1Read;
    streetAddress: string | null;
    apartmentNumber: string | null;
    premisesInternalNumber: string | null;
}

export interface IPublicCardCommentOrganizationUserV1Read {
    uuid: string;
    name: string;
    profileImage?: string;
}

export interface IPublicCardCommentV1Base {
    uuid: string;
    card: string | IPublicCardV1Read;
    /**
     * Card creator. If the `organization` property is `null` this is also
     * the author.
     */
    user: string | IPublicCardCommentPersonUserV1Read;
    /**
     * Card author, if set. If it's `null` the creator (property `user`) is
     * considered the author.
     */
    organization: string | IPublicCardCommentOrganizationUserV1Read | null;
    comment: string;
    created: string;
    updated: string;
    userPermissions: Array<{ name: string }>;
}

export type IPublicCardCommentV1Read = ExpandCollapse<
    IPublicCardCommentV1Base,
    "card",
    "user" | "organization"
>;
export type IPublicCardCommentV1DetailsRead = Expand<
    IPublicCardCommentV1Base,
    "card" | "user" | "organization"
>;

export interface IPublicCardCommentV1Create {
    comment: string;
}

export class PublicCardCommentService extends Service {
    public list(params?: IQueryParams) {
        const url = this.client.url(
            ["api", "v1", "public-card-comments"],
            { ...params, expand: ["user", "card.tags", "organization"] },
            { applyTagFilter: true }
        );
        return this.client.get<
            | IApiResponse<IPaginatedResponseV1<IPublicCardCommentV1DetailsRead>, HttpStatus.Ok>
            | IApiResponse<IGenericApiError, HttpStatus.NotFound>
        >(url);
    }

    public listForCard(cardId: string, params?: IQueryParams) {
        const url = this.client.url(["api", "v1", "public-cards", cardId, "comments"], {
            ...params,
            expand: ["user", "organization"],
        });
        return this.client.get<
            | IApiResponse<IPaginatedResponseV1<IPublicCardCommentV1Read>, HttpStatus.Ok>
            | IApiResponse<IGenericApiError, HttpStatus.NotFound>
        >(url);
    }

    public create(cardId: string, comment: IPublicCardCommentV1Create) {
        const url = this.client.url(["api", "v1", "public-cards", cardId, "comments"]);
        return this.client.post<
            | IApiResponse<
                  Collapse<IPublicCardCommentV1Base, "organization" | "user" | "card">,
                  HttpStatus.Created
              >
            | IApiResponse<ValidationError<IPublicCardCommentV1Create>, HttpStatus.BadRequest>
            | IApiResponse<IGenericApiError, HttpStatus.NotFound>
        >(url, comment);
    }

    public update(cardId: UUID, commentUuid: UUID, comment: IPublicCardCommentV1Create) {
        const url = this.client.url(["api", "v1", "public-cards", cardId, "comments", commentUuid]);
        return this.client.patch<
            | IApiResponse<
                  Collapse<IPublicCardCommentV1Base, "organization" | "user" | "card">,
                  HttpStatus.Ok
              >
            | IApiResponse<ValidationError<IPublicCardCommentV1Create>, HttpStatus.BadRequest>
            | IApiResponse<IGenericApiError, HttpStatus.NotFound>
        >(url, comment);
    }

    public delete(cardId: string, id: string) {
        const url = this.client.url(["api", "v1", "public-cards", cardId, "comments", id]);
        return this.client.delete<IApiResponse<unknown, HttpStatus.NoContent>>(url);
    }
}
