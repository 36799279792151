import { objectToFormData } from "src/lib/object-to-formdata";
import { HttpStatus, IApiResponseMap, IGenericApiError } from "src/services/client";
import { Service } from "src/services/service";
import { IPaginatedResponseV1 } from "src/types/paginated-response";

export interface IGalleryFileRead {
    uuid: string;
    title: string;
    description: string;
    file: string;
    publicUrl: string;
    fileSize: number;
    iconUrl: string;
    created: string;
    updated: string;
}

export interface IGalleryFileCreate {
    file: File;
    title: string;
    description?: string;
}

export class GalleryService extends Service {
    public async list(params?: IQueryParams) {
        const url = this.client.url(["api", "v1", "gallery-files"], params, {
            applyTagFilter: true,
        });
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IPaginatedResponseV1<IGalleryFileRead>;
            }>
        >(url);
    }

    public async retrieve(uuid: string) {
        const url = this.client.url(["api", "v1", "gallery-files", uuid]);
        return this.client.get<
            IApiResponseMap<{
                [HttpStatus.Ok]: IGalleryFileRead;
                [HttpStatus.NotFound]: IGenericApiError;
            }>
        >(url);
    }

    public async create(file: IGalleryFileCreate) {
        const url = this.client.url(["api", "v1", "gallery-files"]);

        const nextFile = { ...file };
        const body = objectToFormData(nextFile);

        if (typeof body.get("file") === "string") {
            body.delete("file");
        }

        return this.client.post<
            IApiResponseMap<{
                [HttpStatus.Created]: IGalleryFileRead;
                [HttpStatus.BadRequest]: ValidationError<IGalleryFileCreate>;
            }>
        >(url, body);
    }

    public async delete(uuid: string) {
        const url = this.client.url(["api", "v1", "files", uuid]);
        return this.client.delete(url);
    }
}
