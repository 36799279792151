interface IAddress {
    streetAddress: string;
}

interface IApartment {
    address: IAddress;
    number: string;
}

export interface IApartmentsAddressSummary {
    apartments: number;
    streetAddress: string;
    floors: number;
}

export class Apartments {
    constructor(private apartments: IApartment[]) {}

    public addressSummary(): IApartmentsAddressSummary[] {
        const addresses = this.uniqueAddresses();
        addresses.sort();
        return addresses.map((address) => {
            const apartmentsOnAddress = this.apartmentsOnAddress(address);
            const floors = this.floorCount(apartmentsOnAddress);
            const apartments = apartmentsOnAddress.length;
            return {
                streetAddress: address,
                apartments,
                floors,
            };
        });
    }

    private floorCount(apartments: IApartment[]) {
        // Floor are counted starting from 10 (the entrance floor) so we need to subtract that.
        // That makes the first floor 0 hence we also need to add 1 as the entrance floor
        // should be counted.
        const modifier = 9;
        return (
            Math.max(...apartments.map((apartment) => Number(apartment.number.substr(0, 2)))) -
            modifier
        );
    }

    private apartmentsOnAddress(address: string) {
        return this.apartments.filter((apartment) => apartment.address.streetAddress === address);
    }

    private uniqueAddresses() {
        return Array.from(
            new Set(this.apartments.map((apartment) => apartment.address.streetAddress))
        );
    }
}
